import React from "react";
import { Spinner } from "react-bootstrap";

export default class LoadingSpinner extends React.Component {
  public render(): JSX.Element {
    return (
      <div className="loading-spinner__holder">
        <div className="loading-spinner__content">
          <span>
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          </span>
          <h2>Loading</h2>
        </div>
      </div>
    );
  }
}
