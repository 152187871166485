import { Constants, Actions } from "./types";

export const initialState = {
  loading: false,
};

export function globalReducer(
  state = initialState,
  action: Actions
): { loading: boolean } {
  switch (action.type) {
    case Constants.SET_LOADING:
      return { ...state, loading: action.payload.loading };
    default:
      return state;
  }
}
