import log from "loglevel";
import React from "react";
import { connect } from "react-redux";

import WindowAlertModal from "components/Shared/WindowAlertModal";
import { Event } from "models/Event";
import store from "store";
import { sendExcelFile } from "store/events/actions";
import { setEnableUpdateDetailsButton } from "store/views/actions";

interface IUploadFileProps {
  event: Event;
  enableUpdateDetails: boolean;
}

interface IUploadFileState {
  showModal: boolean;
  modalHeader: string;
  modalBody: JSX.Element;
}

function mapStateToProps(
  state
): {
  event: Event;
  enableUpdateDetails: boolean;
} {
  return {
    event: state.event.event,
    enableUpdateDetails: state.views.enableUpdateDetailsButton,
  };
}

export class UploadFile extends React.Component<
  IUploadFileProps,
  IUploadFileState
> {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalHeader: "",
      modalBody: <span />,
    };
  }
  public upload;

  /**
   * Sets the state to whether it shows the modal or not
   * @param {boolean} show Show the modal or not
   */
  setShowModal(show: boolean): void {
    this.setState({ showModal: show });
  }

  /**
   * Enable UpdateDetails Button if it is not enabled. Do nothing otherwise.
   * @private
   * @memberof UploadFile
   */
  private enableUpdateDetailsButton(): void {
    if (!this.props.enableUpdateDetails) {
      store.dispatch(setEnableUpdateDetailsButton(true));
    }
  }

  /**
   * Returns the modal body
   *  @param {string} string The string to return wrapped in <span>
   * @returns {Object} The JSX Element with the modal body
   */
  getModalBody(string: string): JSX.Element {
    return <span>{string}</span>;
  }

  /**
   * Upload csv file with riders
   * @param {*} ev
   * @memberof EventDetails
   */
  uploadFileBuilder(ev): void {
    const data = new FormData();
    this.uploadFile(ev, data);
  }

  /**
   * Dispach actions to upload the csv file with the riders in the event.
   * @private
   * @param {*} ev
   * @param {*} data
   * @memberof EventDetails
   */
  public uploadFile(ev, data): void {
    ev.preventDefault();
    data.append("file", this.upload.files[0]);
    store
      .dispatch(sendExcelFile(this.props.event, data))
      .then(res => {
        if (res.data.validation) {
          this.setState({
            modalHeader: "Success!",
            modalBody: this.getModalBody("File uploaded successfully."),
            showModal: true,
          });
          this.enableUpdateDetailsButton();
        } else {
          this.setState({
            modalHeader: "Error!",
            modalBody: this.getModalBody(
              `File could not be uploaded: ${res.data.data}.`
            ),
            showModal: true,
          });
        }
      })
      .catch(error => {
        log.error(error);
      });
  }

  public uploadFileBuilderFunctions(ev): void {
    if (this.props.event) {
      this.uploadFileBuilder(ev);
    }
  }

  render(): JSX.Element {
    return (
      <div className="upload-file__input-text">
        Upload Rider Registration List: &nbsp;
        <input
          id="uploadInput"
          type="file"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={(ref): any => (this.upload = ref)}
          onChange={(ev): void => this.uploadFileBuilderFunctions(ev)}
          className="upload-file__upload"
        />
        <p className="upload-file__input-text">
          File must be a .csv formated as: Last name, First name. &nbsp;
          <a
            className="upload-file__download-link"
            href="/upload-riders-sample-file.csv"
            download
          >
            Download example file
          </a>
          .
        </p>
        <WindowAlertModal
          modalHeader={this.state.modalHeader}
          modalBody={this.state.modalBody}
          showModal={this.state.showModal}
          onHide={(): void => this.setShowModal(false)}
        />
      </div>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any, any>(mapStateToProps)(UploadFile);
