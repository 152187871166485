import { isUndefined } from "util";

import { faEllipsisV, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { captureException } from "@sentry/browser";
import { cloneDeep, debounce } from "lodash";
import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import ComponentHider from "components/Shared/ComponentHider";
import LoadingSpinner from "components/Shared/LoadingSpinner";
import { Event } from "models/Event";
import ISeedUI from "models/ISeedUI";
import { Rider } from "models/Rider";
import store from "store";
import { saveRaceRiders } from "store/race/actions";
import { IRace } from "store/race/types";
import { updateRaceRiders, getEventRaceRiders } from "store/races/actions";
import {
  CATEGORY,
  SUBCATEGORY,
  RIDER,
  DIDNOTSTART,
  DIDNOTFINISH,
  SEEDING,
  SCRATCHRACE,
  KEIRIN,
  CHARIOT,
  CUSTOM,
  KEIRINMAXRIDERS,
  KEIRINCONSOLATIONMAXRIDERS,
} from "utils/constants";
import {
  sortRaceSubcategories,
  getNumberWithOrdinal,
  buildRaceTypesHash,
  convertRaceTypeToUrl,
  convertRaceNameForUrl,
} from "utils/utils";

import AddHeat from "../AddHeat";
import AddResultsButton from "../AddResultsButton";
import EditRaceDetailsButton from "../EditRaceDetailsButton";
import RandomizeLineup from "../RandomizeLineup";

interface IRacePageProps {
  raceTypes: { id: number; name: string }[];
  event: Event;
  races: IRace[];
  eventId: number;
  raceName: string;
  raceType: number;
  raceCategory: string;
  showEditRace: boolean;
}

export interface IRacePageState {
  loading: boolean;
  seed: ISeedUI[];
  raceTypes: { id: number; name: string }[];
  addResultsDisabled: boolean;
  resultsSubmittedFlag: boolean;
  currentRaces: IRace[] | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  infoPerCategory: any;
}

function mapStateToProps(
  state
): {
  event: Event;
  raceTypes: { id: number; name: string }[];
  races: IRace[];
  showEditRace: boolean;
} {
  return {
    event: state.event.event,
    raceTypes: state.raceTypes.raceTypes,
    races: (Object.values(state.races) as IRace[]).filter(race => {
      return race.raceType !== SEEDING;
    }),
    showEditRace: state.views.event.showEditRace,
  };
}

const RandomizeLineupWithHider = ComponentHider(RandomizeLineup);
const AddResultsButtonWithHider = ComponentHider(AddResultsButton);
const EditRaceDetailsWithHider = ComponentHider(EditRaceDetailsButton);
const AddHeatWithHider = ComponentHider(AddHeat);

export class RacePage extends React.Component<IRacePageProps, IRacePageState> {
  public saveSeedDebounced = debounce(this.saveSeed, 2000);
  private raceTypesHash: { [key: number]: string } = {};
  private raceHash: { [key: number]: IRace } = {};
  private hasSubcategory = false;
  private orderIndex = 0;
  private currentSubcategory;

  constructor(props: IRacePageProps) {
    super(props);
    this.state = {
      loading: true,
      seed: [],
      raceTypes: this.props.raceTypes,
      addResultsDisabled: true,
      resultsSubmittedFlag: false,
      currentRaces: this.getCurrentRaces(),
      infoPerCategory: new Map(),
    };
  }

  componentDidMount(): void {
    this.raceTypesHash = buildRaceTypesHash(this.props.raceTypes);
    this.loadRidersWrapper();
    this.setAddResultsButtonState();
  }

  async componentDidUpdate(prevProps: IRacePageProps): Promise<void> {
    // Reload if we switched to a different race type
    if (
      this.selectedRaceChanged(prevProps) ||
      this.props.races.length !== prevProps.races.length
    ) {
      await this.loadRidersWrapper();
      this.hasSubcategory = false;
      this.setState({
        currentRaces: this.getCurrentRaces(),
      });
      const infoPerCategory = this.getInfoPerCategory(
        this.state.currentRaces
      ).get(this.props.raceCategory);
      this.setState({
        infoPerCategory: infoPerCategory,
        loading: false,
      });
    }

    // Re-render if results have been submitted
    if (this.props.races !== prevProps.races) {
      this.setAddResultsButtonState();
      const newSeed = this.formatSeedToScreen();
      this.setState({
        seed: newSeed,
        currentRaces: this.getCurrentRaces(),
      });
      const infoPerCategory = this.getInfoPerCategory(
        this.state.currentRaces
      ).get(this.props.raceCategory);
      this.setState({
        infoPerCategory: infoPerCategory,
        loading: false,
      });
    }
  }
  /**
   * Adds the line/field to add a heat to a race if the race is a Keirin
   * @returns {?JSX.Element} The JSX.Element of the Add Heat
   */
  public addHeatLine(): JSX.Element | null {
    if (
      this.state.currentRaces === undefined ||
      this.props.raceType !== KEIRIN
    ) {
      return null;
    }
    return (
      <div>
        <AddHeatWithHider
          raceName={this.props.raceName}
          raceType={this.props.raceType}
          category={this.props.raceCategory}
        />
      </div>
    );
  }
  /**
   * Creates the header for the tiles. Right now, only applies to Scratch races
   * @param {?IRace} race  The race to add the header to
   * @returns {JSX.Element} The JSX.Element of the header
   */
  public createHeader(race: IRace | undefined): JSX.Element {
    if (race) {
      if (race.raceType === SCRATCHRACE) {
        // Adds new column headers if results have been entered
        if (race.riders && race.riders.length > 0 && race.riders[0].placing) {
          return (
            <Row className="ml-0 mr-0 riders__header-row">
              <Col
                xs="2"
                sm="2"
                lg="2"
                xl="1"
                className="riders__header-row-item riders__header-row-item-placing"
              >
                <p className="riders__placing-header">Placing</p>
              </Col>
              <Col
                xs="2"
                sm="1"
                lg="1"
                xl="1"
                className="riders__header-row-item"
              >
                <p>Bib #</p>
              </Col>
              <Col
                xs="4"
                sm="5"
                lg="5"
                xl="6"
                className="riders__header-row-item"
              >
                <p>Name</p>
              </Col>
              <Col
                xs="2"
                sm="2"
                lg="2"
                xl="2"
                className="riders__header-row-item"
              >
                <p className="riders__placing-header">Finish Order</p>
              </Col>
              <Col
                xs="2"
                sm="2"
                lg="2"
                xl="2"
                className="riders__header-row-item"
              >
                <p className="riders__placing-header">+ / - Laps</p>
              </Col>
            </Row>
          );
        } else {
          return (
            <Row className="ml-0 mr-0 riders__header-row">
              <Col xs="1"></Col>
              <Col xs="2" sm="1" className="riders__header-row-item">
                <p>Bib #</p>
              </Col>
              <Col xs="8" sm="9" className="riders__header-row-item">
                <p>Name</p>
              </Col>
            </Row>
          );
        }
      } else if (race.raceType === KEIRIN) {
        this.orderIndex = 1;
        // Adds new column headers when results have been entered
        if (race.riders && race.riders.length > 0 && race.riders[0].placing) {
          return (
            <Row className="ml-0 mr-0 riders__header-row">
              <Col
                xs="2"
                sm="2"
                md="1"
                lg="1"
                xl="1"
                className="riders__header-row-item-dragging riders__header-row-item-placing"
              >
                <p className="riders__placing-header">Placing</p>
              </Col>
              <Col
                xs="1"
                sm="1"
                lg="1"
                xl="1"
                className="riders__header-row-item-dragging"
              >
                <p>Bib #</p>
              </Col>
              <Col
                xs="3"
                sm="4"
                lg="4"
                xl="5"
                className="riders__header-row-item-dragging"
              >
                <p>Name</p>
              </Col>
              <Col
                xs="2"
                sm="2"
                lg="2"
                xl="2"
                className="riders__header-row-item-dragging riders__placing-header"
              >
                <p>Starting Order</p>
              </Col>
              <Col
                xs="2"
                sm="2"
                lg="2"
                xl="2"
                className="riders__header-row-item-dragging riders__placing-header"
              >
                <p>Finish Order</p>
              </Col>
              {/* Empty notes */}
              <Col xs="1" sm="1" lg="1" xl="1" />
            </Row>
          );
        } else {
          return (
            // KEIRIN where no results have been entered
            <Row className="ml-0 mr-0 riders__header-row">
              {/* Empty Placing */}
              <Col xs="2" sm="2" md="1" lg="1" xl="1" />
              <Col
                xs="1"
                sm="1"
                lg="1"
                xl="1"
                className="riders__header-row-item-dragging"
              >
                <p>Bib #</p>
              </Col>
              <Col
                xs="3"
                sm="4"
                lg="4"
                xl="5"
                className="riders__header-row-item-dragging"
              >
                <p>Name</p>
              </Col>
              <Col
                xs="2"
                sm="2"
                lg="2"
                xl="2"
                className="riders__header-row-item-dragging riders__placing-header"
              >
                <p>Starting Order</p>
              </Col>
              {/* Empty Finish Order */}
              <Col
                xs="2"
                sm="2"
                lg="2"
                xl="2"
                className="riders__header-row-item-dragging riders__placing-header"
              />
              {/* Empty Notes */}
              <Col xs="1" sm="1" lg="1" xl="1" />
            </Row>
          );
        }
      } else if (race.raceType === CUSTOM) {
        if (race.riders && race.riders.length > 0 && race.riders[0].placing) {
          return (
            <Row className="ml-0 mr-0 riders__header-row">
              <Col
                xs="2"
                sm="2"
                lg="2"
                xl="1"
                className="riders__header-row-item riders__header-row-item-placing"
              >
                <p className="riders__placing-header">Placing</p>
              </Col>
              <Col
                xs="2"
                sm="1"
                lg="1"
                xl="1"
                className="riders__header-row-item"
              >
                <p>Bib #</p>
              </Col>
              <Col
                xs="6"
                sm="7"
                lg="7"
                xl="9"
                className="riders__header-row-item"
              >
                <p>Name</p>
              </Col>
              <Col
                xs="2"
                sm="2"
                lg="2"
                xl="1"
                className="riders__header-row-item"
              >
                <p className="riders__laps-header">+ / - Laps</p>
              </Col>
            </Row>
          );
        } else {
          return (
            <Row className="ml-0 mr-0 riders__header-row">
              {/* Empty column for empty placing */}
              <Col xs="1"></Col>
              <Col
                xs="2"
                sm="1"
                lg="1"
                xl="1"
                className="riders__header-row-item"
              >
                <p>Bib #</p>
              </Col>
              <Col
                xs="8"
                sm="10"
                lg="10"
                xl="10"
                className="riders__header-row-item"
              >
                <p>Name</p>
              </Col>
            </Row>
          );
        }
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  }
  /**
   * Populates a rider tile depending on the race and if there are results entered
   * @param {ISeedUI} rider   The rider tile being populated
   * @param {?string} subcategory   The subcategory being read
   * @returns {JSX.Element}  The JSX.Element rider tile
   */
  public createTile(
    rider: ISeedUI,
    subcategory: string | undefined
  ): JSX.Element {
    const riderPlacing = (rider.data as Rider).placing;
    if (this.state.currentRaces) {
      if (this.props.raceType === SCRATCHRACE) {
        if (riderPlacing) {
          return (
            <Row className="ml-0 mr-0 riders__row">
              <Col className="riders__placing" xs="2" sm="2" lg="2" xl="1">
                {getNumberWithOrdinal(riderPlacing)}
              </Col>
              <Col xs="2" sm="1" lg="1" xl="1">
                {(rider.data as Rider).bib}
              </Col>
              <Col xs="4" sm="5" lg="5" xl="6" className="">
                <p>{(rider.data as Rider).getName()}</p>
              </Col>
              <Col xs="2" sm="2" lg="2" xl="2">
                <p className="riders__notes">
                  {getNumberWithOrdinal((rider.data as Rider).finishOrder)}
                </p>
              </Col>
              <Col xs="2" sm="2" lg="2" xl="2">
                <p className="riders__notes">
                  {this.notes(rider.data as Rider)}
                </p>
              </Col>
            </Row>
          );
        } else {
          // Scratch race, but no placing
          return (
            <Row className="ml-0 mr-0 riders__rider-no-dragging riders__row">
              {/* Empty column for empty placing */}
              <Col xs="1"></Col>
              <Col xs="2" sm="1">
                {(rider.data as Rider).bib}
              </Col>
              <Col xs="8" sm="9">
                <p>{(rider.data as Rider).getName()}</p>
              </Col>
            </Row>
          );
        }
      } else if (this.props.raceType === KEIRIN) {
        if (riderPlacing) {
          return (
            <Row className="ml-0 mr-0 riders__row">
              <Col
                xs="2"
                sm="2"
                md="1"
                lg="1"
                xl="1"
                className="riders__placing"
              >
                {getNumberWithOrdinal(riderPlacing)}
              </Col>
              <Col xs="1" sm="1" lg="1" xl="1">
                {(rider.data as Rider).bib}
              </Col>
              <Col xs="3" sm="4" lg="4" xl="5" className="">
                <p>{(rider.data as Rider).getName()}</p>
              </Col>
              <Col xs="2" sm="2" lg="2" xl="2" className="">
                <p className="riders__notes">
                  {this.getStartingOrder(rider.data as Rider)}
                </p>
              </Col>
              <Col xs="2" sm="2" lg="2" xl="2" className="">
                <p className="riders__notes">
                  {getNumberWithOrdinal(riderPlacing)}
                </p>
              </Col>
              <Col xs="1" sm="1" lg="1" xl="1">
                <span className="riders__notes">
                  {this.getQualifyingText(riderPlacing, subcategory)}
                </span>
              </Col>
            </Row>
          );
        } else {
          // For Keirin race but no placing
          return (
            <Row className="ml-0 mr-0 riders__row">
              <Col
                xs="2"
                sm="2"
                md="1"
                lg="1"
                xl="1"
                className="riders__drag-handle"
              >
                <span>
                  <FontAwesomeIcon icon={faEllipsisV} />
                </span>
              </Col>
              <Col xs="1" sm="1" lg="1" xl="1">
                {(rider.data as Rider).bib}
              </Col>
              <Col xs="3" sm="4" lg="4" xl="5" className="">
                <span>{(rider.data as Rider).getName()}</span>
              </Col>
              <Col xs="2" sm="2" lg="2" xl="2" className="">
                <span className="riders__notes">
                  {this.getStartingOrder(rider.data as Rider)}
                </span>
              </Col>
              {/* Empty Finish order */}
              <Col xs="2" sm="2" lg="2" xl="2" />
              {/* Empty Notes */}
              <Col xs="1" sm="1" lg="1" xl="1" />
            </Row>
          );
        }
      } else if (this.props.raceType === CUSTOM) {
        if (riderPlacing) {
          return (
            <Row className="ml-0 mr-0 riders__row">
              <Col className="riders__placing" xs="2" sm="2" lg="2" xl="1">
                {getNumberWithOrdinal(riderPlacing)}
              </Col>
              <Col xs="2" sm="1" lg="1" xl="1">
                {(rider.data as Rider).bib}
              </Col>
              <Col xs="6" sm="7" lg="7" xl="9">
                <p>{(rider.data as Rider).getName()}</p>
              </Col>
              <Col xs="2" sm="2" lg="2" xl="1">
                <p className="riders__notes">
                  {this.notes(rider.data as Rider)}
                </p>
              </Col>
            </Row>
          );
        } else {
          // for custom race, but no placing
          return (
            <Row className="mr-0 ml-0 riders__rider-no-dragging riders__row">
              {/* Drag handle column, which is empty here */}
              <Col xs="1"></Col>
              <Col xs="2" sm="1" lg="1" xl="1">
                {(rider.data as Rider).bib}
              </Col>
              <Col xs="8" sm="10" lg="10" xl="10">
                <p>{(rider.data as Rider).getName()}</p>
              </Col>
            </Row>
          );
        }
      }
    }
    return <></>;
  }
  /**
   * Finds if an event is locked
   * @returns {boolean} Whether the event is locked or not
   */
  public findIfEventIsLocked(): boolean {
    if (this.props.event.lock !== undefined && this.props.event.lock) {
      return true;
    }
    return false;
  }
  /**
   * Format a Seed object into a RLDD list
   * @returns {ISeedUI[]} The formatted ISeedUI[] object
   */
  public formatSeedToScreen(): ISeedUI[] {
    this.raceHash = {};
    const seedsUI: ISeedUI[] = [];
    let id = 0;
    let previousCategory = "";
    const races = store.getState().races;

    const addSeed = (data: ISeedUI): void => {
      seedsUI.push(data);
      id += 1;
    };
    // filter seeds by race type and category
    if (races) {
      Object.values(races as IRace[])
        .filter(
          (race: IRace) =>
            race.name === this.props.raceName &&
            race.category === this.props.raceCategory
        )
        .sort((a, b) => sortRaceSubcategories(a, b))
        .forEach((race: IRace) => {
          this.raceHash[race.id] = race;
          if (previousCategory !== race.category) {
            addSeed({
              id,
              data: race,
              listPosition: 0,
              type: CATEGORY,
              categoryRider: "",
            });
            previousCategory = race.category;
          }
          if (race.subcategory && race.subcategory !== "") {
            addSeed({
              id,
              data: race,
              listPosition: 0,
              type: SUBCATEGORY,
              categoryRider: "",
            });
            this.hasSubcategory = true;
          }
          (race.riders || []).forEach((rider, idx) => {
            addSeed({
              id,
              data: rider,
              listPosition: idx + 1,
              type: RIDER,
              categoryRider: race.category,
            });
          });
        });
    }
    return this.sortRLDDistByRank(seedsUI);
  }

  /**
   * Returns all races that match the raceName and the category.
   * @returns {IRace[]}
   */
  public getCurrentRaces(): IRace[] | undefined {
    const races = this.props.races.filter(race => {
      return (
        race.name === this.props.raceName &&
        race.category === this.props.raceCategory
      );
    });
    return races;
  }

  /**
   * Return the items re-arranged
   * @param {ISeedUI[]} reorderedItems Items type ISeedUI
   * @returns {ISeedUI[]} The ISeedUI re-arranged
   */
  public getDragDropItems(reorderedItems: ISeedUI[]): ISeedUI[] {
    const previousItems = this.state.seed;
    const increment = 0;
    const listLength = previousItems.length;
    for (let i = 0; i < listLength; i++) {
      if (previousItems[i].id !== reorderedItems[i + increment].id) {
        return [previousItems[i], reorderedItems[i]];
      }
    }
    return [];
  }

  /**
   * Gets the text of the column for the number of heats
   * @param {number} numHeats The number of heats of the current race
   * @returns {?string} The string
   */
  public getHeatsColumn(numHeats: number): string | void {
    if (this.props.raceType === KEIRIN || this.props.raceType === CHARIOT) {
      return `${numHeats} Heats`;
    }
  }

  /**
   * Gets all info for the category: number of Riders, whether results have been submitted, and number of riders that qualify
   * @param {IRace[]} races
   * @returns {Object} The map
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getInfoPerCategory(races: IRace[] | undefined): any {
    if (races) {
      const categoryHash = races.reduce((acc, race) => {
        if (race.riders) {
          if (acc.has(race.category)) {
            const subLength = race.subcategory
              ? race.subcategory.includes("Heat")
                ? race.riders.length
                : 0
              : race.riders.length;
            const length = acc.get(race.category).length + subLength;
            const hasHeat =
              race.subcategory && race.subcategory.includes("Heat");
            const numHeats =
              acc.get(race.category).numHeats + (hasHeat ? 1 : 0);
            const numQualify = acc.get(race.category).numQualify;
            const numLaps = acc.get(race.category).numLaps;
            acc.set(race.category, {
              length: length,
              numHeats: numHeats,
              numQualify: numQualify,
              numLaps: numLaps,
            });
          } else {
            const hasHeat =
              race.subcategory && race.subcategory.includes("Heat");
            const numHeats = hasHeat ? 1 : 0;
            const length = race.subcategory
              ? race.subcategory.includes("Heat")
                ? race.riders.length
                : 0
              : race.riders.length;
            const numLaps = race.subcategory
              ? race.subcategory.includes("Heat")
                ? race.numLaps
                : 0
              : race.numLaps;
            acc.set(race.category, {
              length: length,
              numHeats: numHeats,
              numQualify: race.numQualify,
              numLaps: numLaps,
            });
          }
          return acc;
        }
        return new Map();
      }, new Map());
      return categoryHash;
    }
    return null;
  }
  /**
   * Changes the color of the tile when it's being dragged
   */
  public getItemStyle = (isDragging, draggableStyle): {} => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    margin: `0 0 ${0.5}rem 0`,

    // change background colour if dragging
    background: isDragging ? "#0da7d67f" : null,

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  /**
   * Compares the rider placing and the number of qualifying riders in a race, returns the text Q for qualifying riders
   * @param {?number} numQualify  The number of riders that qualify to the next race
   * @param {string} riderPlacing  The placing of the rider in the race
   * @returns {Object}  The JSX.Element, 'Q' for qualifying riders or nothing
   */
  public getQualifyingText(
    riderPlacing: string,
    subcategory: string | undefined
  ): JSX.Element {
    if (
      this.state.currentRaces &&
      this.state.currentRaces[0] &&
      this.state.currentRaces[0].numQualify &&
      this.state.currentRaces[0].numQualify >= parseInt(riderPlacing) &&
      subcategory &&
      subcategory.includes("Heat")
    ) {
      return <p>Q</p>;
    }
    return <></>;
  }

  /**
   * Gets the string of the overview page url for that race
   * @param {string} currentRaceName The current race name
   * @returns {string} A url
   */
  public getRaceOverviewUrl(currentRaceName: string): string {
    return `/event/${this.props.event.id}/overview/${convertRaceNameForUrl(
      currentRaceName
    )}/${convertRaceTypeToUrl(this.raceTypesHash, this.props.raceType)}/`;
  }

  /**
   * Returns the starting order if the raceType is Keirin. Adds the starting order to the rider object if it doesn't have one already
   * @param {Rider} rider Rider to parse for starting order
   * @returns {?string} The starting order, number with ordinal
   */
  public getStartingOrder(rider: Rider): string | null {
    if (this.props.raceType === KEIRIN) {
      if (rider.order === undefined) {
        rider.order = this.orderIndex.toString();
        this.orderIndex += 1;
      }
      return getNumberWithOrdinal(rider.order);
    }
    return null;
  }
  /**
   * Format a RLDD list after it is changed by the user
   * @param {ISeedUI[]} reorderedItems The ISeedUI[] Object
   */
  public handleRLDDChange(reorderedItems: ISeedUI[]): void {
    const draggedItems = this.getDragDropItems(reorderedItems);

    // not items re-arranged
    if (draggedItems.length <= 0) {
      return;
    }
    // cannot move category if its not a Mass Start race
    if (
      this.hasSubcategory &&
      (draggedItems[0].type === CATEGORY || draggedItems[1].type === CATEGORY)
    ) {
      return;
    }
    // make sure a rider is not on top
    if (reorderedItems[1] && reorderedItems[1].type === RIDER) {
      return;
    }
    // cannot move a category/subcategory B to above category/subcategory A and vice versa
    let previousCategoryId = 0;
    for (const item of reorderedItems) {
      if (item.type === RIDER) {
        continue;
      }
      if (previousCategoryId !== 0 && item.id < previousCategoryId) {
        return;
      }
      previousCategoryId = item.id;
    }

    // change state and send data to the backend
    this.setState({ seed: reorderedItems });
    this.saveSeed(reorderedItems);
  }

  /**
   * Checks if the category is set up and ready for results to be entered
   * @param {IRace} race  The current race
   * @returns {boolean}   If the race is set up and ready for results, returns true. Otherwise, false
   */
  public isCategoryDetailsSetup(race: IRace): boolean {
    if (race.raceType === KEIRIN) {
      if (race.numQualify) {
        return true;
      }
      return false;
    } else if (race.numLaps) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Create a JSX Element with an item based on its type (Category or Rider)
   * @param {ISeedUI} item The item: rider, category, subcategory
   * @param {number} index Its index in the list
   * @returns {Object} The JSX.Element
   */
  public itemRenderer(item: ISeedUI, index: number): JSX.Element {
    let disabled = true;
    if (this.props.raceType === KEIRIN && !this.findIfEventIsLocked()) {
      disabled = false;
    }
    if (item.type === RIDER) {
      // Creating the tile is asynchronous, therefore one cannot use this.currentSubcategory
      // directly since it will grab the last one subcategory found, which would be 'Final'
      // Therefore, send the temporary subcategory
      const tempSubcategory = this.currentSubcategory;
      return (
        <Draggable
          key={item.id}
          draggableId={item.id.toString()}
          index={index}
          isDragDisabled={disabled}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {(provided: any, snapshot: any): JSX.Element => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={this.getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
              className="riders__rider"
            >
              {this.createTile(item, tempSubcategory)}
            </div>
          )}
        </Draggable>
      );
    }
    if (item.type === SUBCATEGORY) {
      const race = item.data as IRace;
      this.currentSubcategory = (item.data as IRace).subcategory;
      if ((item.data as IRace).raceType === KEIRIN) {
        return (
          <Draggable
            className="riders__subcategory"
            key={item.id}
            draggableId={item.id.toString()}
            index={index}
            isDragDisabled={disabled}
          >
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {(provided: any, snapshot: any): JSX.Element => (
              <div
                className="riders__subcategory"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={this.getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                <Row>
                  <Col xs="auto" sm="3" className="riders__heat-name">
                    {(item.data as IRace).subcategory}
                  </Col>
                  <Col
                    xs="auto"
                    sm={{ span: "9", offset: 0 }}
                    className="riders__category-secondary"
                  >
                    <Row>
                      <Col
                        xs="12"
                        md="auto"
                        className="riders__subcategory-buttons"
                      >
                        <RandomizeLineupWithHider
                          race={item.data as IRace}
                          callBackFn={this.loadRidersCallBack}
                        />
                      </Col>
                      <Col
                        xs="12"
                        md="auto"
                        className="riders__subcategory-buttons"
                      >
                        <AddResultsButtonWithHider
                          race={item.data as IRace}
                          key={(item.data as IRace).id}
                          eventId={this.props.eventId}
                          disabled={this.state.addResultsDisabled}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  {race.subcategory &&
                  race.subcategory.includes("Consolation") ? (
                    race.riders &&
                    race.riders.length > KEIRINCONSOLATIONMAXRIDERS ? (
                      <span className="riders__heat-warning">
                        Warning: Cannot have more than{" "}
                        {KEIRINCONSOLATIONMAXRIDERS} riders per consolation
                        race.
                      </span>
                    ) : (
                      <></>
                    )
                  ) : race.riders && race.riders.length > KEIRINMAXRIDERS ? (
                    <span className="riders__heat-warning">
                      Warning: Cannot have more than {KEIRINMAXRIDERS} riders
                      per heat.
                    </span>
                  ) : (
                    <></>
                  )}
                </Row>
                {this.createHeader(item.data as IRace)}
              </div>
            )}
          </Draggable>
        );
      }
    }
    // Need to return a key, otherwise will raise a 'unique key warning'
    return <div key="mynull"></div>;
  }

  /**
   * Run the loadRidersWrapper again
   */
  public loadRidersCallBack = (): Promise<void> | undefined => {
    return this.loadRidersWrapper();
  };

  /**
   * Load riders for a specific race name and category
   */
  public loadRidersWrapper(): Promise<void> | undefined {
    this.setState({ loading: true });
    if (this.props.races) {
      return store
        .dispatch(
          getEventRaceRiders({
            races: this.props.races,
            raceCategory: this.props.raceCategory,
            raceName: this.props.raceName,
          })
        )
        .then()
        .catch(() => {
          captureException("Error in loadRidersWrapper!");
        });
    }
  }

  /**
   * Fills in the "+ / - Laps" column for a rider.
   * @param {Rider} rider   The rider whose laps information we are looking for
   * @returns {string} The notes
   */
  public notes(rider: Rider): string {
    if (this.state.infoPerCategory) {
      const numLaps = this.state.infoPerCategory.numLaps;
      if (rider.numOfLaps && numLaps) {
        if (rider.numOfLaps < numLaps) {
          return `-${numLaps - rider.numOfLaps}`;
        } else if (rider.numOfLaps > numLaps) {
          return `+${rider.numOfLaps - numLaps}`;
        }
      }
    }
    return "";
  }
  /**
   * Updates the backend with the new races after dragging and dropping a rider
   * @param {Object} result  The new order of riders after drag-and-drop
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onDragEnd(result: any): void {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = this.reorder(
      this.state.seed,
      result.source.index,
      result.destination.index
    );
    this.handleRLDDChange(items);
  }
  /**
   * Returns the JSX.Element of the race info line: number of riders, heats, laps, riders that advance
   * @returns {JSX.Element}  The race info line
   */
  public renderRaceInfoLine(): JSX.Element {
    const infoPerCategory = this.state.infoPerCategory;
    if (infoPerCategory) {
      return (
        <div className="riders__info-per-category">
          {`${infoPerCategory.length} Riders
          ${
            infoPerCategory.numHeats
              ? `\u00A0\u2022\u00A0 ${this.getHeatsColumn(
                  infoPerCategory.numHeats
                )}`
              : ""
          } ${
            infoPerCategory.numLaps
              ? `\u00A0\u2022\u00A0 ${infoPerCategory.numLaps} Laps`
              : `\u00A0\u2022\u00A0 Unknown Laps`
          } ${
            infoPerCategory.numQualify
              ? `\u00A0\u2022\u00A0 ${infoPerCategory.numQualify} qualify`
              : ""
          }`}
        </div>
      );
    }
    return <></>;
  }
  /**
   * Shows the buttons depending on the whether the race category details have been set up or not
   * @param {?IRace[]} races  The races belonging to that category
   * @returns {JSX.Element} The JSX.Element of the buttons
   */
  public renderTopButtons(races: IRace[] | undefined): JSX.Element {
    if (
      races !== undefined &&
      races[0].raceType === KEIRIN &&
      this.isCategoryDetailsSetup(races[0])
    ) {
      return (
        <Row>
          <Col xs="12">
            <EditRaceDetailsWithHider />
          </Col>
        </Row>
      );
    } else if (
      races !== undefined &&
      (races[0].raceType === SCRATCHRACE || races[0].raceType === CUSTOM) &&
      this.isCategoryDetailsSetup(races[0])
    ) {
      return (
        <Row>
          <Col
            className="align-items-center riders__race-details-button"
            xs="12"
          >
            <span>
              <EditRaceDetailsWithHider />
            </span>
            <span className="pl-2">
              <AddResultsButtonWithHider
                race={races[0] as IRace}
                key={(races[0] as IRace).id}
                disabled={this.state.addResultsDisabled}
                eventId={this.props.eventId}
              />
            </span>
          </Col>
        </Row>
      );
    }
    return <></>;
  }

  /**
   * Reorders the seed acccording to the drag and drop
   * @param {ISeedUI[]} list The ISeedUI[] list
   * @param {number}  startIndex The index at the start
   * @param {number}  endIndex The index at the end
   * @returns {ISeedUI[]} The re-ordered ISeedUI[] list
   */
  public reorder = (
    list: ISeedUI[],
    startIndex: number,
    endIndex: number
  ): ISeedUI[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * Change the seed list with the new rider's category
   * @param {Object} reorderedItems
   */
  public saveSeed(reorderedItems): void {
    const updatedRaces: IRace[] = [];

    reorderedItems.reduce((acc, item) => {
      if (!(item.data instanceof Rider)) {
        acc = cloneDeep(item.data);
        acc.riders = [];
        updatedRaces.push(acc);
        return acc;
      }

      acc.riders.push(item.data);
      return acc;
    }, {});

    // call backend to save the data
    store.dispatch(saveRaceRiders(updatedRaces)).then(races => {
      if (races) {
        return Promise.all(
          Object.values(races as IRace[]).map((race: IRace) => {
            return store.dispatch(
              updateRaceRiders(race, race.riders as Rider[])
            );
          })
        ).then(() => {
          const newSeed = this.formatSeedToScreen();
          this.setState({ seed: newSeed });
          return newSeed;
        });
      }
    });
  }

  /**
   * Takes previous event bar state and compares it against the current state.
   * Returns true if race type or race category has changed.
   * @param {IRacePageProps} prevProps
   * @returns {boolean}
   */
  public selectedRaceChanged(prevProps: IRacePageProps): boolean {
    if (
      prevProps.raceName !== this.props.raceName ||
      prevProps.raceCategory !== this.props.raceCategory
    ) {
      this.setAddResultsButtonState();
      return true;
    }
    return false;
  }
  /**
   * Runs a check to see if the add results button state should be updated
   */
  public setAddResultsButtonState(): void {
    // For Keirin numQualify must be set, for others, the number of laps
    this.props.races.forEach(race => {
      if (
        race.name === this.props.raceName &&
        race.category === this.props.raceCategory
      ) {
        if (race.raceType === KEIRIN) {
          if (race.numQualify === null || race.numQualify === undefined) {
            this.setState({ addResultsDisabled: true });
          } else {
            this.setState({ addResultsDisabled: false });
          }
        } else {
          if (race.numLaps === null || race.numLaps === undefined) {
            this.setState({ addResultsDisabled: true });
          } else {
            this.setState({ addResultsDisabled: false });
          }
        }
      }
    });
  }
  /**
   * Returns the JSX.Element of the info box about needing to set up the category before able to add results if there are races otherwise it returns a fragment
   * @returns {?JSX.Element} The JSX.Element info box or a fragment if no races
   */
  public showEditCategoryPrompt(): JSX.Element | null {
    if (
      this.state.currentRaces &&
      this.state.currentRaces[0] &&
      this.isCategoryDetailsSetup(this.state.currentRaces[0]) === false
    ) {
      return (
        <div className="riders__edit-category-prompt">
          <span className="riders__edit-category-prompt-text">
            To add race results, you need to set up race details for{" "}
            <b>Category {this.state.currentRaces[0].category}</b> first.
          </span>
          <div className="riders__edit-category-prompt-button">
            <EditRaceDetailsWithHider />
          </div>
        </div>
      );
    }
    return null;
  }

  /**
   * Sorts the riders. Sorts by placing if results have been entered. Otherwise, it sorts by bib, and if there are no bibs, sorts by rank
   * @param {ISeedUI} riderA  One rider
   * @param {ISeedUI} riderB  The other rider
   * @returns {number} Whether one items goes before/after the other
   */
  public sortRiders(riderA: ISeedUI, riderB: ISeedUI): number {
    const riderAPlace = (riderA.data as Rider).placing;
    const riderABib = (riderA.data as Rider).bib;
    const riderARank = (riderA.data as Rider).ranking;
    const riderBPlace = (riderB.data as Rider).placing;
    const riderBBib = (riderB.data as Rider).bib;
    const riderBRank = (riderB.data as Rider).ranking;

    if (
      riderAPlace !== undefined &&
      riderBPlace !== undefined &&
      riderAPlace !== null &&
      riderBPlace !== null
    ) {
      if (riderAPlace === DIDNOTSTART || riderAPlace === DIDNOTFINISH) {
        return 1;
      }
      if (riderBPlace === DIDNOTSTART || riderBPlace === DIDNOTFINISH) {
        return -1;
      }
      return parseInt(riderAPlace) > parseInt(riderBPlace) ? 1 : -1;
    }
    if (riderABib !== undefined && riderBBib !== undefined) {
      return parseInt(riderABib) > parseInt(riderBBib) ? 1 : -1;
    }
    if (riderARank === riderBRank) {
      // keep items sorted by its list id
      return riderA.id >= riderB.id ? 1 : -1;
    }
    return riderARank < riderBRank ? 1 : -1;
  }

  /**
   * Sort a RLDD list by riders rank
   * @param {ISeedUI[]} reorderedItems The ISeedUI[] object
   * @returns {ISeedUI[]} The sorted ISeedUI[] object
   */
  public sortRLDDistByRank(reorderedItems: ISeedUI[]): ISeedUI[] {
    let race: IRace | Rider;
    const categoriesId = {};
    const subcategoriesId = {};
    let lastCategoryId = 0;

    // group items by category
    const itemsGrouped = reorderedItems.reduce((acc, item) => {
      if (item.data instanceof Rider && race) {
        acc[race.id].push(item);
      } else {
        if (this.hasSubcategory && item.type === CATEGORY) {
          lastCategoryId = item.id;
          return acc;
        }

        if (item.type === CATEGORY) {
          categoriesId[item.data.id] = item.id;
        } else {
          categoriesId[item.data.id] = lastCategoryId;
          subcategoriesId[item.data.id] = item.id;
        }

        race = item.data;
        acc[race.id] = [];
      }
      return acc;
    }, {});

    let category = "";
    // recreate a RLDD list with the riders sorted by rank
    return reorderedItems
      .filter(item =>
        this.hasSubcategory ? item.type === SUBCATEGORY : item.type === CATEGORY
      )
      .map(item => item.data.id)
      .reduce((sortedItems: ISeedUI[], key) => {
        // add category
        if (
          !isUndefined(this.raceHash[key]) &&
          this.raceHash[key].category !== category
        ) {
          sortedItems.push({
            id: categoriesId[key],
            data: this.raceHash[key],
            listPosition: 0,
            type: CATEGORY,
            categoryRider: "",
          });
          category = this.raceHash[key].category;
        }

        // add subcategory
        if (
          !isUndefined(this.raceHash[key]) &&
          this.raceHash[key].subcategory
        ) {
          sortedItems.push({
            id: subcategoriesId[key],
            data: this.raceHash[key],
            listPosition: 0,
            type: SUBCATEGORY,
            categoryRider: "",
          });
        }
        // add riders by category/subcategory
        itemsGrouped[key]
          .sort((a, b) => {
            return this.sortRiders(a, b);
          })
          .forEach(item => {
            sortedItems.push({
              id: item.id,
              data: item.data,
              listPosition: item.listPosition,
              type: RIDER,
              categoryRider: item.categoryRider,
            });
          });
        return sortedItems;
      }, []);
  }

  render(): JSX.Element {
    if (this.state.loading) {
      return <LoadingSpinner />;
    }
    const items = this.state.seed;

    return (
      <>
        <div className="riders__race-header">
          <Row>
            {this.state.currentRaces === undefined ? (
              <Col></Col>
            ) : (
              <>
                <Col xs="12" className="riders__go-back">
                  <Link
                    to={this.getRaceOverviewUrl(this.props.raceName)}
                    className="riders__go-back"
                  >
                    <span className="riders__arrow-icon">
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </span>
                    {this.props.raceName}
                  </Link>
                </Col>
                <Col xs="12" sm="6">
                  <div>
                    <span className="riders__race-category">
                      Category {this.props.raceCategory}
                    </span>
                  </div>
                  {this.renderRaceInfoLine()}
                </Col>
              </>
            )}
            <Col xs="12" sm="6" className="riders__top-buttons">
              {this.renderTopButtons(this.state.currentRaces)}
            </Col>
          </Row>
        </div>

        {/* The Edit Category prompt goes here */}
        {this.showEditCategoryPrompt()}

        {/* Headers are added below each heat title for Keirin, so don't need to add it here */}
        {this.state.currentRaces && this.props.raceType !== KEIRIN ? (
          this.createHeader(this.state.currentRaces[0])
        ) : (
          <></>
        )}
        {this.state.currentRaces ? (
          <DragDropContext
            onDragEnd={this.onDragEnd.bind(this)}
            key={
              this.state.currentRaces[0].id.toString() +
              this.props.raceName.toString() +
              this.props.raceCategory.toString()
            }
          >
            <Droppable
              droppableId={
                this.state.currentRaces[0].id.toString() +
                this.props.raceName.toString() +
                this.props.raceCategory.toString()
              }
              key={
                this.state.currentRaces[0].id.toString() +
                this.props.raceName.toString() +
                this.props.raceCategory.toString()
              }
            >
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              {(provided: any): JSX.Element => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  key={"aDroppable"}
                >
                  {items.map((item, index) => this.itemRenderer(item, index))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : null}
        {this.addHeatLine()}
      </>
    );
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any, any>(mapStateToProps)(RacePage);
