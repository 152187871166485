import { captureException } from "@sentry/browser";
import React from "react";
import { Provider } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";

// Auth
import AuthService from "./auth/Auth";
import Callback from "./auth/Callback";
import Login from "./auth/Login";
import Logout from "./auth/Logout";
import store from "./store";
import SecuredRoute from "./utils/SecuredRoute";
import history from "./utils/history";
import About from "./views/About";
import EnterResultsView from "./views/EnterResultsView";
import Event from "./views/Event";
import EventSetupView from "./views/EventSetupView";
import Home from "./views/Home";
import RaceOverviewView from "./views/RaceOverviewView";
import SeedingView from "./views/SeedingView";

interface IAppState {
  checkingSession: boolean;
}

/**
 * Entry point for Router and Redux
 */
class App extends React.Component<any, IAppState> {
  constructor(props) {
    super(props);
    this.state = {
      checkingSession: true,
    };
  }

  async componentDidMount(): Promise<void> {
    if (this.props.location && this.props.location.pathname === "/callback") {
      this.setState({ checkingSession: false });
      return;
    }
    try {
      await AuthService.silentAuth();
      this.forceUpdate();
    } catch (err) {
      if (err.error !== "login_required") {
        captureException(`Error with auth0: ${err.error}`);
      }
    }
    this.setState({ checkingSession: false });
  }

  render(): JSX.Element {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <SecuredRoute
              path="/"
              render={(): JSX.Element => <Home />}
              checkingSession={this.state.checkingSession}
              exact
            />
            <SecuredRoute
              path="/event/:id/setup"
              render={(props): JSX.Element => (
                <EventSetupView eventId={props.match.params.id} />
              )}
              checkingSession={this.state.checkingSession}
            />
            <SecuredRoute
              path="/event/:id/seeding"
              render={(props): JSX.Element => (
                <SeedingView eventId={props.match.params.id} />
              )}
              checkingSession={this.state.checkingSession}
            />
            {/* RaceOverviewView route needs to be listed before the Event route */}
            <SecuredRoute
              exact
              path="/event/:id/overview/:racename/:racetype"
              render={(props: { match }): JSX.Element => (
                <RaceOverviewView
                  eventId={props.match.params.id}
                  raceName={props.match.params.racename}
                  raceType={props.match.params.racetype}
                />
              )}
              checkingSession={this.state.checkingSession}
            />
            <SecuredRoute
              path="/event/:id/:racename/:racetype/:category/enter-results/:subcategory?"
              render={(props: { match }): JSX.Element => (
                <EnterResultsView
                  eventId={props.match.params.id}
                  raceType={props.match.params.racetype}
                  raceName={props.match.params.racename}
                  raceCategory={props.match.params.category}
                  raceSubcategory={props.match.params.subcategory}
                />
              )}
              checkingSession={this.state.checkingSession}
            />
            <SecuredRoute
              exact
              path="/event/:id/:racename/:racetype/:category"
              render={(props: { match }): JSX.Element => (
                <Event
                  eventId={props.match.params.id}
                  raceType={props.match.params.racetype}
                  raceName={props.match.params.racename}
                  raceCategory={props.match.params.category}
                />
              )}
              checkingSession={this.state.checkingSession}
            />
            <Route path="/login" exact render={(): JSX.Element => <Login />} />
            <Route
              path="/logout"
              exact
              render={(): JSX.Element => <Logout />}
            />
            <Route
              path="/callback"
              render={(props): JSX.Element => <Callback {...props} />}
            />
            <Route path="/about" render={(): JSX.Element => <About />} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}
export default App;
