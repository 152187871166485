import React from "react";
import { Button } from "react-bootstrap";

import store from "store";
import { updateCreateCSVState } from "store/views/actions";

export default class CreateCSVSelectionButton extends React.Component {
  /**
   * Update addRiderState to display add rider box .
   * @private
   * @memberof CreateCSVSelectionButton
   */
  public showCSVSelection(): void {
    store.dispatch(updateCreateCSVState(true));
  }

  public render(): JSX.Element {
    return (
      <div>
        <Button
          className="btn-cycling-secondary"
          onClick={(): void => this.showCSVSelection()}
        >
          Export...
        </Button>
      </div>
    );
  }
}
