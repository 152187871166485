import { action } from "typesafe-actions";

import { IRace } from "store/race/types";

import {
  Constants,
  UpdateAddriderStateAction,
  UpdateAddResultsStateAction,
  UpdateCreateCSVStateAction,
  SetShowcreateneweventdetailsAction,
  SetEnableupdatedetailsbuttonAction,
  UpdateRaceDetailsStateAction,
  UpdateLockRaceStateAction,
  UpdateInvalidBibsAction,
} from "./types";

export const updateAddRiderState = (
  newState: boolean
): UpdateAddriderStateAction =>
  action(Constants.UPDATE_ADDRIDER_STATE, { newState });

export const updateAddResultsState = (
  newState: IRace | null
): UpdateAddResultsStateAction =>
  action(Constants.UPDATE_ADDRESULTS_STATE, { newState });

export const updateCreateCSVState = (
  newState: boolean
): UpdateCreateCSVStateAction =>
  action(Constants.UPDATE_CREATECSV_STATE, { newState });

export const setShowCreateNewEventDetails = (
  showCreateNewEventDetails: boolean
): SetShowcreateneweventdetailsAction =>
  action(Constants.SET_SHOWCREATENEWEVENTDETAILS, {
    showCreateNewEventDetails,
  });

export const setEnableUpdateDetailsButton = (
  enableUpdateDetailsButton: boolean
): SetEnableupdatedetailsbuttonAction =>
  action(Constants.SET_ENABLEUPDATEDETAILSBUTTON, {
    enableUpdateDetailsButton,
  });

export const updateRaceDetailsState = (
  enableUpdateRaceDetailsButton: boolean
): UpdateRaceDetailsStateAction =>
  action(Constants.UPDATE_RACE_DETAILS_STATE, {
    enableUpdateRaceDetailsButton,
  });

export const updateLockRaceState = (
  newState: boolean
): UpdateLockRaceStateAction =>
  action(Constants.UPDATE_LOCK_RACE_STATE, { newState });

export const updateInvalidBibs = (
  invalidBibs: Set<string>
): UpdateInvalidBibsAction =>
  action(Constants.UPDATE_INVALID_BIBS, { invalidBibs });
