import React from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import ncimLogo from "assets/ncimLogo.png";
import { IEvent } from "models/Event";
import { formatDate } from "utils/utils";

interface IAppBarProps {
  event: IEvent;
  displayAppBar: boolean;
}

function mapStateToProps(state): { event: IEvent } {
  return { event: state.event.event };
}

/**
 * AppBar component
 * @export
 * @class AppBar
 * @extends {React.Component<IAppBar>}
 */
export class AppBar extends React.Component<IAppBarProps> {
  render(): JSX.Element {
    const showEventData = (): JSX.Element => {
      if (!this.props.displayAppBar) {
        return <span />;
      }

      return (
        <div className="app-bar__event-details">
          {this.props.event.name}
          <span className="date">
            {formatDate(this.props.event.startDatetime, "(MMM DD, YYYY)")}
          </span>
        </div>
      );
    };

    return (
      <div className="container-fluid app-bar">
        <Row>
          <Col xs="auto" className="align-items-center d-flex">
            <Link to="/">
              <img
                className="app-bar__ncim-logo"
                src={ncimLogo}
                alt="NCIM Logo: National Cycling Institute Milton"
              />
            </Link>
          </Col>
          <Col
            xs="auto"
            className="d-flex align-items-center app-bar__event-data"
          >
            {showEventData()}
          </Col>
        </Row>
      </div>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any, any>(mapStateToProps)(AppBar);
