import { captureException } from "@sentry/browser";
import React from "react";
import { Button } from "react-bootstrap";

import store from "store";
import { updateAddRiderState } from "store/views/actions";

export default class AddRiderButton extends React.Component<{}, {}> {
  /**
   * Update addRiderState to display add rider box .
   * @private
   * @memberof addRiderButton
   */
  public showAddRiderDetails(): void {
    try {
      store.dispatch(updateAddRiderState(true));
    } catch (err) {
      captureException(err);
    }
  }

  public render(): JSX.Element {
    return (
      <div>
        <Button
          className="btn-cycling"
          onClick={(): void => this.showAddRiderDetails()}
        >
          Add New Rider
        </Button>
      </div>
    );
  }
}
