import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { captureException } from "@sentry/browser";
import React from "react";
import { connect } from "react-redux";

import imagePlaceholder from "assets/event-placeholder.jpg";
import { Event } from "models/Event";
import store from "store";
import { setRacesOpen } from "store/eventbar/actions";
import { IRaceType, IRace } from "store/race/types";
import history from "utils/history";
import { formatDate, buildRaceTypesHash } from "utils/utils";

interface IEventTileProps {
  raceTypes: IRaceType[];
  event: Event;
  races: IRace[];
}

function mapStateToProps(state): { raceTypes: IRaceType[]; races: IRace[] } {
  return {
    raceTypes: state.raceTypes.raceTypes,
    races: state.races,
  };
}

export class EventTile extends React.Component<IEventTileProps> {
  private raceTypesHash: { [key: number]: string } = {};

  private isEmpty(obj): boolean {
    return Object.keys(obj).length === 0;
  }

  /**
   * Gets the race type names of the races included in an event
   * @returns {?string} The race types
   */
  getRaceNames(): string | null {
    if (!this.raceTypesHash || this.isEmpty(this.raceTypesHash)) {
      this.raceTypesHash = buildRaceTypesHash(this.props.raceTypes);
    }
    let races = "";
    if (this.props.event.racesIncluded) {
      const names: string[] = [];
      this.props.event.racesIncluded.forEach(raceTypeNumber => {
        const raceType = this.raceTypesHash[raceTypeNumber];
        if (raceType !== undefined) {
          names.push(raceType);
        }
      });
      races = names.join(", ");
      return races;
    }
    return null;
  }

  /**
   * Return if the event hasn't been set, null otherwise
   * @param {IEvent} event
   * @returns
   * @memberof EventTile
   */
  getStatus(event: Event): string | null {
    if (event.isEventSet()) {
      return null;
    } else {
      return "details have not been set up";
    }
  }

  render(): JSX.Element | null {
    if (!this.props.event) {
      return null;
    }

    const loadEvent = (): void => {
      if (this.props.event.isEventSet()) {
        history.push(`/event/${this.props.event.id}/seeding/`);
      } else {
        history.push(`/event/${this.props.event.id}/setup/`);
      }
      try {
        store.dispatch(setRacesOpen([]));
      } catch (err) {
        captureException(err);
      }
    };

    const status = this.getStatus(this.props.event);

    return (
      <div className="event-tile">
        <div onClick={loadEvent}>
          <img
            src={imagePlaceholder}
            className="event-tile__image"
            alt={this.props.event.name}
          />
        </div>
        {status && (
          <div className="event-tile__status" onClick={loadEvent}>
            {status}
          </div>
        )}
        <div className="event-tile__date" onClick={loadEvent}>
          {formatDate(this.props.event.startDatetime)}
          {this.props.event.lock ? (
            <span className="event-tile__locked">
              <FontAwesomeIcon
                className="event-tile__locked-icon"
                icon={faLock}
              />
              &nbsp;
            </span>
          ) : (
            <span />
          )}
        </div>
        <div className="event-tile__title" onClick={loadEvent}>
          {this.props.event.name}
        </div>
        <div className="event-tile__races" onClick={loadEvent}>
          {this.getRaceNames()}
        </div>
      </div>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any, any>(mapStateToProps)(EventTile);
