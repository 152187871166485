import { isNull } from "util";

import { plainToClass } from "class-transformer";

import { IRace } from "../store/race/types";

export interface IEventsAPIData {
  events: Event[];
  pages: number;
}

export interface IEventsAPISaveEventRaceTypes {
  updatedRaceNames: { [oldRaceName: string]: string };
  newRaces: {
    type: number;
    name: string;
  }[];
}

export interface IEvent {
  id: number;
  name: string;
  startDatetime: Date;
  endDatetime: Date;
  racesIncluded: number[];
  mindbodyId?: number;
  races?: IRace[];
  isSetup?: boolean;
  lastUpdated?: Date;
  lock?: boolean;
}

export class Event implements IEvent {
  public id = 0;
  public name = "";
  public startDatetime: Date = new Date();
  public endDatetime: Date = new Date();
  public racesIncluded: number[];
  public mindbodyId?: number;
  public races?: IRace[];
  public isSetup?: boolean;
  public lastUpdated?: Date;
  public lock?: boolean;

  constructor(
    id: number,
    name: string,
    startDatetime: Date,
    endDatetime: Date,
    racesIncluded: number[],
    mindbodyId?: number,
    isSetup?: boolean,
    lock?: boolean
  ) {
    this.id = id;
    this.name = name;
    this.startDatetime = startDatetime;
    this.endDatetime = endDatetime;
    this.mindbodyId = mindbodyId;
    this.isSetup = isSetup;
    this.lock = lock;
    this.racesIncluded = racesIncluded;
  }

  /**
   * Create a Event object from a IEvent interface
   */
  public static fromInterface = (event: IEvent): Event => {
    const newEvent = plainToClass(Event, event);
    return newEvent;
  };

  /**
   * Return true if event is set, false otherwise.
   * @returns {boolean}
   * @memberof Event
   */
  public isEventSet(): boolean {
    if (isNull(this.isSetup)) {
      return true;
    } else {
      return !!this.isSetup;
    }
  }
}
