import React from "react";

import AuthService from "./Auth";

export default class Login extends React.Component {
  componentDidMount(): void {
    AuthService.login();
  }

  render(): null {
    return null;
  }
}
