import React from "react";
import { Row, Col } from "react-bootstrap";

import history from "utils/history";

export class Footer extends React.Component {
  render(): JSX.Element {
    return (
      <Row className="footer">
        <Col sm="11" md="11" lg="11">
          Copyright National Cycling Institute Milton 2019
        </Col>
        <Col sm="1" md="1" lg="1">
          <span
            className="footer__about"
            onClick={(): void => {
              history.push("/about");
            }}
          >
            About
          </span>
        </Col>
      </Row>
    );
  }
}

export default Footer;
