import { captureException } from "@sentry/browser";
import log from "loglevel";
import React from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import WindowAlertModal from "components/Shared/WindowAlertModal";
import { Event } from "models/Event";
import store from "store";
import { IRace } from "store/race/types";
import { saveAddRider } from "store/races/actions";
import { updateAddRiderState } from "store/views/actions";
import { SEEDING } from "utils/constants";

interface IAddRiderDetailsProps {
  showAddRider: boolean;
  eventId: number;
  event: Event;
}

interface IAddRiderDetailsState {
  eventId: number;
  event: Event;
  onHide: boolean;
  firstName: string;
  lastName: string;
  bib: number | null;
  certified: string | null;
  riderLicense: string | null;
  team: string | null;
  madisonPartner: string | null;
  showModal: boolean;
  modalHeader: string;
  modalBody: JSX.Element;
}

function mapStateToProps(state): IAddRiderDetailsProps {
  return {
    showAddRider: state.views.event.showAddRider,
    eventId: state.event.event.id,
    event: state.event.event,
  };
}

export class AddRiderDetails extends React.Component<
  IAddRiderDetailsProps,
  IAddRiderDetailsState
> {
  constructor(props: IAddRiderDetailsProps) {
    super(props);
    this.state = {
      eventId: props.eventId,
      event: props.event,
      onHide: true,
      firstName: "",
      lastName: "",
      bib: null,
      certified: null,
      riderLicense: null,
      team: null,
      madisonPartner: null,
      showModal: false,
      modalHeader: "",
      modalBody: <span />,
    };
  }

  /**
   * Returns the modal body
   * @param {Object} params The parameters
   * @param {?string} params.error The error returned
   * @param {?string} params.firstName The firstName of the person
   * @param {?string} params.lastName The latName of the person
   * @returns {Object} The JSX Element with the modal body
   */
  public getModalBody(params): JSX.Element {
    const { error, firstName, lastName } = params;
    if (error) {
      return <span>{error}</span>;
    }
    return (
      <span>
        <strong>
          {firstName} {lastName}
        </strong>{" "}
        is already participating.
      </span>
    );
  }

  /**
   * It hides the modal window via a store dispatch and triggers the reset of the state
   */
  public async hideWindow(): Promise<void> {
    try {
      await store.dispatch(updateAddRiderState(false));
      this.resetStateValues();
    } catch (err) {
      captureException(err);
    }
  }

  /**
   * It resets the state to initial state
   */
  public resetStateValues(): void {
    this.setState({
      firstName: "",
      lastName: "",
      bib: null,
      certified: null,
      riderLicense: null,
      team: null,
      madisonPartner: null,
    });
  }

  /**
   * Finds if a rider is alraedy in the event. Returns true if the rider is in the event already, false otherwise
   * @param {Object} rider The rider info
   * @returns {boolean} Whether the rider is in the event or not
   */
  public riderInEvent(rider): boolean {
    const races: IRace[] = Object.values(store.getState().races);
    for (let i = 0; i < races.length; i++) {
      if (races[i].raceType === SEEDING) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        for (let j = 0; j < races[i].riders!.length; j++) {
          if (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            races[i].riders![j].firstName === rider.firstName &&
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            races[i].riders![j].lastName === rider.lastName
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /**
   * Save the rider details
   * @memberof AddRiderDetails
   */
  public saveRider(): void {
    const rider = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      bib: this.state.bib,
      certified: this.state.certified,
      riderLicense: this.state.riderLicense,
      team: this.state.team,
      madisonPartner: this.state.madisonPartner,
    };
    if (this.riderInEvent(rider)) {
      this.hideWindow();
      this.setState({
        showModal: true,
        modalHeader: "Oops!",
        modalBody: this.getModalBody({
          firstName: rider.firstName,
          lastName: rider.lastName,
        }),
      });
      return;
    }
    store
      .dispatch(saveAddRider(rider, this.state.eventId))
      .then(() => {
        this.hideWindow();
      })
      .catch(error => {
        log.error(error);
        this.setState({
          showModal: true,
          modalHeader: "Error!",
          modalBody: this.getModalBody({ error: error }),
        });
      });
  }

  /**
   * Sets the state to whether it shows the modal or not
   * @param {boolean} show Show the modal or not
   */
  public setShowModal(show: boolean): void {
    this.setState({ showModal: show });
  }

  /**
   * Sets the first name of the state with whatever was written in the input box
   * @param ev
   */
  private setFirstName(ev): void {
    this.setState({ firstName: ev.target.value.trim() });
  }

  /**
   * Sets the last name of the state with whatever was written in the input box
   * @param ev
   */
  private setLastName(ev): void {
    this.setState({ lastName: ev.target.value.trim() });
  }

  render(): JSX.Element {
    return (
      <>
        <Modal
          className="addRider-details"
          show={this.props.showAddRider}
          onHide={this.hideWindow}
          dialogClassName="modal-50w"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter-add-rider">
              Add New Rider
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="addRider__fields">
              <Form.Group as={Row} controlId="formPlaintextFirstName">
                <Col md={{ span: 3, offset: 0 }}>
                  <Form.Label className="addRider-details__input-description">
                    First Name:
                  </Form.Label>
                </Col>
                <Col md={{ span: 9, offset: 0 }}>
                  <Form.Control
                    className="addRider-details__input-boxes"
                    onChange={(ev): void => {
                      this.setFirstName(ev);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextLastName">
                <Col md="3">
                  <Form.Label>Last Name:</Form.Label>
                </Col>
                <Col md="9">
                  <Form.Control
                    className="addRider-details__input-boxes"
                    onChange={(ev): void => {
                      this.setLastName(ev);
                    }}
                  />
                </Col>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-cycling"
              onClick={(): void => this.saveRider()}
            >
              Add
            </Button>
            <Button
              className="btn-cycling-secondary"
              onClick={(): Promise<void> => this.hideWindow()}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <WindowAlertModal
          modalHeader={this.state.modalHeader}
          modalBody={this.state.modalBody}
          showModal={this.state.showModal}
          onHide={(): void => this.setShowModal(false)}
        />
      </>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any>(mapStateToProps)(AddRiderDetails);
