import React from "react";
import { connect } from "react-redux";

import { IEvent } from "models/Event";
import { IRace } from "store/race/types";
import { mapRaceTypeStringToInt, convertRaceNameFromUrl } from "utils/utils";

import CreateCSVSelection from "../../components/Race/CreateCSVSelection";
import EditRaceDetails from "../../components/Race/EditRaceDetails";
import RacePage from "../../components/Race/RacePage";
import Layout from "../../components/Shared/Layout";

interface IEventViewProps {
  event: IEvent;
  showAddRider: boolean;
  currentAddResultsRace: IRace;
  showEditRace: boolean;
  showCreateCSV: boolean;
  showLockRace: boolean;
  dontShowLockRaceAgain: boolean;
  eventId: string;
  raceName: string;
  raceType: string;
  raceCategory: string;
}

function mapStateToProps(
  state
): {
  event: IEvent;
  showAddRider: boolean;
  currentAddResultsRace: IRace;
  showEditRace: boolean;
  showCreateCSV: boolean;
  showLockRace: boolean;
  dontShowLockRaceAgain: boolean;
} {
  return {
    event: state.event,
    currentAddResultsRace: state.views.event.currentAddResultsRace,
    showAddRider: state.views.event.showAddRider,
    showEditRace: state.views.event.showEditRace,
    showCreateCSV: state.views.event.showCreateCSV,
    showLockRace: state.views.event.showLockRace,
    dontShowLockRaceAgain: state.views.event.dontShowLockRaceAgain,
  };
}

export class Event extends React.Component<IEventViewProps> {
  render(): JSX.Element | null {
    return (
      <Layout
        eventId={parseInt(this.props.eventId)}
        raceName={convertRaceNameFromUrl(this.props.raceName)}
        raceType={mapRaceTypeStringToInt(this.props.raceType)}
        raceCategory={this.props.raceCategory}
      >
        <div className="event container ml-2">
          <RacePage
            eventId={parseInt(this.props.eventId)}
            raceName={convertRaceNameFromUrl(this.props.raceName)}
            raceType={mapRaceTypeStringToInt(this.props.raceType)}
            raceCategory={this.props.raceCategory}
          />
        </div>

        {this.props.showEditRace && (
          <EditRaceDetails
            raceType={mapRaceTypeStringToInt(this.props.raceType)}
            raceName={convertRaceNameFromUrl(this.props.raceName)}
            raceCategory={this.props.raceCategory}
            show={this.props.showEditRace}
          />
        )}

        {this.props.showCreateCSV && (
          <CreateCSVSelection
            raceName={convertRaceNameFromUrl(this.props.raceName)}
            raceType={mapRaceTypeStringToInt(this.props.raceType)}
            show={this.props.showCreateCSV}
          />
        )}
      </Layout>
    );
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any>(mapStateToProps)(Event);
