import React from "react";
import { Modal, Button } from "react-bootstrap";

interface IWindowAlertModalProps {
  modalHeader: string | null;
  modalBody: JSX.Element;
  showModal: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onHide: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAccept?: any;
}

export class WindowAlertModal extends React.Component<IWindowAlertModalProps> {
  /**
   * Gets the header of the modal, if any
   * @memberof WindowAlertModal
   * @returns {?Object} The JSX.Element of the Modal.Header
   */
  getHeader(): JSX.Element | null {
    if (this.props.modalHeader !== "") {
      return (
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter-alert">
            {this.props.modalHeader}
          </Modal.Title>
        </Modal.Header>
      );
    }
    return null;
  }

  render(): JSX.Element {
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.props.onHide}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="window-alert-modal"
      >
        {this.getHeader()}
        <Modal.Body>{this.props.modalBody}</Modal.Body>
        <Modal.Footer>
          {this.props.onAccept ? (
            <>
              <Button
                onClick={this.props.onHide}
                className="btn-cycling-secondary"
              >
                Cancel
              </Button>
              <Button onClick={this.props.onAccept} className="btn-cycling">
                OK
              </Button>
            </>
          ) : (
            <Button onClick={this.props.onHide} className="btn-cycling">
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default WindowAlertModal;
