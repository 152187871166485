import { captureException } from "@sentry/browser";
import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";

import { Event } from "models/Event";
import store from "store";
import { createNewHeat } from "store/race/actions";
import { IRace, raceFromInterface } from "store/race/types";
import { updateEventRaces } from "store/races/actions";
import { KEIRIN, CHARIOT } from "utils/constants";
import { sortRaceSubcategories } from "utils/utils";

interface IAddHeatProps {
  event: Event;
  raceName: string;
  raceType: number;
  category: string;
}
interface IAddHeatState {
  event: Event;
  raceType: number;
}
/**
 * Retrive data from store.
 * @param {*} state
 * @returns
 */
function mapStateToProps(state): { event: Event } {
  return { event: state.event.event };
}

export class AddHeat extends React.Component<IAddHeatProps, IAddHeatState> {
  constructor(props: IAddHeatProps) {
    super(props);
    this.state = {
      event: props.event,
      raceType: 0,
    };
  }

  /**
   * Update addCategory to display add rider box .
   * @public
   * @memberof addCategory
   */
  public AddNewHeat(): void {
    store
      .dispatch(
        createNewHeat({
          eventId: this.state.event.id,
          raceName: this.props.raceName,
          raceType: this.props.raceType,
          category: this.props.category,
        })
      )
      .then(res => {
        if (Object.keys(res).includes("data")) {
          const races: IRace[] = Object.values(store.getState().races);
          if (res.data.newRace) {
            const newRace: IRace = raceFromInterface(res.data.newRace);
            races.push(newRace);
          }
          races.sort((a, b) => sortRaceSubcategories(a, b));
          store.dispatch(updateEventRaces(races));
        }
      })
      .catch(err => {
        captureException(err);
      });
  }

  public isRaceHeated(): boolean {
    const { raceType } = this.props;
    if (raceType === KEIRIN || raceType === CHARIOT) {
      return true;
    }
    return false;
  }

  public render(): JSX.Element {
    return (
      <Row>
        {this.isRaceHeated() ? (
          <span className="add-heat" onClick={(): void => this.AddNewHeat()}>
            + Add Heat
          </span>
        ) : (
          <span />
        )}
      </Row>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any, any>(mapStateToProps)(AddHeat);
