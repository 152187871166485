import React from "react";
import { Button } from "react-bootstrap";

interface IRemoveRowProps {
  onRemoveRow: () => void;
  rowId: string;
}

export class RemoveRowButton extends React.Component<IRemoveRowProps> {
  /**
   * Finds if a race is not new. New Races have not been assigned a numerical id, their ID is 'race#'
   * @returns {boolean} Whether a race is not new
   */
  findIfRaceIsNotNew(): boolean {
    return !this.props.rowId.includes("race");
  }

  public render(): JSX.Element {
    return (
      <Button
        className="btn-cycling-secondary"
        onClick={(): void => {
          this.props.onRemoveRow();
        }}
        disabled={this.findIfRaceIsNotNew()}
      >
        Remove
      </Button>
    );
  }
}
export default RemoveRowButton;
