import { captureException } from "@sentry/browser";
import React from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import { Event } from "models/Event";
import store from "store";
import { IRace } from "store/race/types";
import { deleteCategory } from "store/races/actions";

interface IDeleteCategoryProps {
  event: Event;
  race: IRace;
}
interface IDeleteCategoryState {
  categoryRace: IRace;
}
/**
 * Retrive data from store.
 * @param {*} state
 * @returns
 */
function mapStateToProps(state): { event: Event } {
  return { event: state.event.event };
}

export class DeleteCategory extends React.Component<
  IDeleteCategoryProps,
  IDeleteCategoryState
> {
  constructor(props: IDeleteCategoryProps) {
    super(props);

    this.deleteCategorySelected = this.deleteCategorySelected.bind(this);
  }

  /**
   * Distpach delete category by backend and refresh the page by reloading event.
   * @private
   * @memberof DeleteCategory
   */
  public deleteCategorySelected(): void {
    try {
      store.dispatch(deleteCategory(this.props.race));
    } catch (err) {
      captureException(err);
    }
  }

  public render(): JSX.Element {
    return (
      <div className="delete-category">
        <Row>
          <Col>This category is empty. Drag and drop riders to add.</Col>
        </Row>
        <Row
          className="delete-category__delete"
          onClick={(): void => this.deleteCategorySelected()}
        >
          <Col>Delete Category</Col>
        </Row>
      </div>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any>(mapStateToProps)(DeleteCategory);
