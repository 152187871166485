import { captureException } from "@sentry/browser";
import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { ActionType, action } from "typesafe-actions";

import api from "../../services/races/racesAPI";

import {
  Constants,
  IRaceType,
  IRace,
  UpdateRaceTypesAction,
  SetLastestNewRidersAction,
} from "./types";

export const updateRaceTypes = (
  raceTypes: IRaceType[]
): UpdateRaceTypesAction => action(Constants.UPDATE_RACE_TYPES, { raceTypes });

export const getRaceTypes = () => (
  dispatch: Dispatch<ActionType<typeof updateRaceTypes>>
): Promise<void> =>
  api
    .getRaceTypes()
    .then(raceTypes => {
      dispatch(updateRaceTypes(raceTypes));
    })
    .catch(err => {
      captureException(err);
    });

export const saveRaceRiders = (races: IRace[]) => (): Promise<IRace[]> => {
  const raceRiders = races.reduce((acc, race) => {
    const riders = (race.riders || []).map(rider => rider.id);
    acc[race.id] = riders;
    return acc;
  }, {});
  try {
    return api.saveRaceRiders(raceRiders);
  } catch {
    return Promise.resolve([]);
  }
};
// Dispatch createNewHeat in racesApi.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createNewHeat = (params: any) => (): Promise<AxiosResponse> => {
  return api.createNewHeat(params);
};

export const setLatestNewRiders = (
  latestNewRiders: number[]
): SetLastestNewRidersAction =>
  action(Constants.SET_LATEST_NEW_RIDERS, { latestNewRiders });
