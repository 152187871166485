import React from "react";
import { Button } from "react-bootstrap";

interface IAddNewRowButtonProps {
  onAddNewRow: () => void;
}

export class AddNewRowButton extends React.Component<IAddNewRowButtonProps> {
  public render(): JSX.Element {
    return (
      <Button
        className="btn-cycling-secondary"
        onClick={(): void => {
          this.props.onAddNewRow();
        }}
      >
        Add Another Race
      </Button>
    );
  }
}
export default AddNewRowButton;
