import React from "react";
import { Button } from "react-bootstrap";

import store from "store";
import { updateRaceDetailsState } from "store/views/actions";

export default class EditRaceDetailsButton extends React.Component {
  private showEditRaceDetails(): void {
    store.dispatch(updateRaceDetailsState(true));
  }

  public render(): JSX.Element {
    return (
      <>
        <Button
          className="btn-cycling-secondary"
          onClick={(): void => this.showEditRaceDetails()}
        >
          Edit Category
        </Button>
      </>
    );
  }
}
