import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";

import { eventBarReducer } from "store/eventbar/reducer";
import { eventsReducer, eventReducer } from "store/events/reducer";
import { globalReducer } from "store/global/reducer";
import { raceReducer } from "store/race/reducer";
import { racesReducer } from "store/races/reducer";
import { viewsReducer } from "store/views/reducer";

// Combining reducer in one to pass to Store
const reducers = combineReducers({
  events: eventsReducer,
  event: eventReducer,
  eventBar: eventBarReducer,
  global: globalReducer,
  raceTypes: raceReducer,
  views: viewsReducer,
  races: racesReducer,
});

// Store declaration, with reducer combined and activation of applyMiddleware
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const store = createStore<any, any, any, any>(reducers, applyMiddleware(thunk));

export default store;
