import React from "react";
import { connect } from "react-redux";

import { Event } from "models/Event";

interface IComponentHiderProps {
  event: Event;
}

function mapStateToProps(
  state
): {
  event: Event;
} {
  return {
    event: state.event.event,
  };
}

/**
 * Wrapper that renders a component. Hides it if the event is locked.
 * @param Component Component to render normally
 */
// I have no idea what type this returns, but React.ComponentType is not correct
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ComponentHider(Component): any {
  class ComponentHiderComponent extends React.Component<IComponentHiderProps> {
    render(): JSX.Element {
      // TODO: Change this to read event locked property when it's added
      if (this.props.event.lock === undefined || !this.props.event.lock) {
        return <Component {...this.props} />;
      }
      return <span></span>;
    }
  }

  return connect(mapStateToProps)(ComponentHiderComponent);
}
export default ComponentHider;
