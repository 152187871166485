import axios from "axios";

import Auth from "../../auth/Auth";
import { convertFromAPI } from "../utils";

const API_URL = process.env.REACT_APP_API_URL;

export default {
  /**
   * Adds a rider to an event
   * @param {Object} rider The IRider object
   * @param {number} eventId The event ID
   * @returns {Object} The Promise
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addRider(rider: any, eventId: number): Promise<any> {
    return axios.post(
      `${API_URL}/v1/event/${eventId}/add_rider/`,
      rider || [],
      { ...Auth.getAPIHeader() }
    );
  },

  /**
   * Randomizes the order of riders in the race
   * @param raceId : The id of the race for which the order of riders will be randomized
   * @returns {Object} The Promise
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  randomizeRiders(raceId: number): Promise<any> {
    return axios.post(`${API_URL}/v1/race/${raceId}/randomize/`, null, {
      ...Auth.getAPIHeader(),
    });
  },

  /**
   * Gets the Bib number from an event
   * @param {number} eventId The event ID
   * @returns {Object} The Promise
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getBibs(eventId: number): Promise<any> {
    return axios
      .get(`${API_URL}/v1/event/${eventId}/bibs/`, { ...Auth.getAPIHeader() })
      .then(response => {
        return convertFromAPI(response.data);
      });
  },
};
