import { isUndefined, isNull } from "util";

import React from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";

import WindowAlertModal from "components/Shared/WindowAlertModal";
import { Event } from "models/Event";
import store from "store";
import { IRace } from "store/race/types";
import { saveRaceDetails, sendRaceResults } from "store/races/actions";
import { updateRaceDetailsState } from "store/views/actions";
import { KEIRINNUMOFLAPS, KEIRIN } from "utils/constants";

interface IEditRaceDetailsProps {
  showEditRace: boolean;
  eventId: number;
  event: Event;
  raceName: string;
  raceCategory: string;
  raceType: number;
  races: IRace[];
}

interface IEditRaceDetailsState {
  numQualify: string;
  numLaps: string;
  showModal: boolean;
  modalHeader: string;
  modalBody: JSX.Element;
}

function mapStateToProps(
  state
): {
  showEditRace: boolean;
  eventId: number;
  event: Event;
  races: IRace[];
} {
  return {
    showEditRace: state.views.event.showEditRace,
    eventId: state.event.event.id,
    event: state.event.event,
    races: Object.values(state.races),
  };
}

export class EditRaceDetails extends React.Component<
  IEditRaceDetailsProps,
  IEditRaceDetailsState
> {
  constructor(props: IEditRaceDetailsProps) {
    super(props);
    this.state = {
      numQualify: this.getDefaultQuantity("numQualify"),
      numLaps: this.getDefaultQuantity("numLaps"),
      showModal: false,
      modalHeader: "",
      modalBody: <span />,
    };
  }

  /**
   * Checks if there are results in any of the Keirin final races
   * @returns {boolean}   Returns true if there are results, false if it's not a Keirin race or there are no results
   */
  areResultsEnteredForFinals(): boolean {
    if (this.props.raceType === KEIRIN) {
      let resultsEntered = false;
      this.props.races.forEach(race => {
        if (
          race.raceType === KEIRIN &&
          race.category === this.props.raceCategory &&
          race.subcategory &&
          race.subcategory.includes("Final") &&
          race.riders &&
          race.riders[0].placing
        ) {
          resultsEntered = true;
        }
      });
      return resultsEntered;
    }
    return false;
  }

  /**
   * Searches the races for current values of variableToSet
   * @param {string} variable numLaps or numQualify
   * @returns {string} The number of riders that qualified or the number of laps, depending on the race
   */
  public getDefaultQuantity(variable: string): string {
    const visibleRaces: IRace[] = [];
    const races: IRace[] = Object.values(store.getState().races);
    if (!isUndefined(races)) {
      for (let i = 0; i < races.length; i++) {
        if (
          races[i].category === this.props.raceCategory &&
          races[i].name === this.props.raceName
        ) {
          visibleRaces.push(races[i]);
        }
      }
    }
    if (visibleRaces.length !== 0) {
      const visibleRace0: IRace = visibleRaces[0];
      if (variable === "numQualify") {
        if (
          isNull(visibleRace0["numQualify"]) ||
          visibleRace0["numQualify"] === undefined
        ) {
          return "";
        } else {
          return visibleRace0["numQualify"].toString();
        }
      } else if (variable === "numLaps") {
        if (
          isNull(visibleRace0["numLaps"]) ||
          visibleRace0["numLaps"] === undefined
        ) {
          return "";
        } else {
          return visibleRace0["numLaps"].toString();
        }
      }
    }
    return "";
  }

  /**
   * Returns the modal to set the quantity, either number of riders that qualify or numLaps
   * @returns {Object} The JSX Element with the modal
   */
  public getModal(): JSX.Element {
    return (
      <Modal
        className="editRace-details"
        show={this.props.showEditRace}
        onHide={this.hideWindow}
        dialogClassName="modal-50w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter-lock">
            Edit {this.props.raceName}: Category {this.props.raceCategory}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* Number of riders that qualify for final */}
          <Form className="editRace-details__main-text">
            {this.props.raceType === KEIRIN ? (
              <Form.Group
                as={Row}
                controlId="formPlaintextNumQualifiers"
                className="editRace-details__input-container"
              >
                <Col xs="auto" md={{ span: 9, offset: 0 }}>
                  <Form.Label className="editRace-details__input-description">
                    Number of riders that qualify for final
                  </Form.Label>
                </Col>
                <Col xs="auto" md={{ span: 3, offset: 0 }}>
                  <Form.Control
                    className="editRace-details__input-boxes"
                    defaultValue={this.state.numQualify}
                    onChange={(ev): void => {
                      this.setVariableToSet(ev, "numQualify");
                    }}
                    disabled={this.areResultsEnteredForFinals()}
                  />
                </Col>
                {this.areResultsEnteredForFinals() ? (
                  <span className="editRace-details__input-warning">
                    You cannot change the number of riders that qualify, since
                    race results have already been added to the <b>Final</b> or{" "}
                    <b>Consolation Final</b> race.
                  </span>
                ) : (
                  <></>
                )}
              </Form.Group>
            ) : (
              <></>
            )}
            {/* Number of laps */}
            {this.props.raceType !== KEIRIN ? (
              <Form.Group
                as={Row}
                controlId="formPlaintextNumQualifiers"
                className="editRace-details__input-container"
              >
                <Col xs="auto" md={{ span: 9, offset: 0 }}>
                  <Form.Label className="editRace-details__input-description">
                    Number of laps
                  </Form.Label>
                </Col>
                <Col xs="auto" md={{ span: 3, offset: 0 }}>
                  <Form.Control
                    className="editRace-details__input-boxes"
                    defaultValue={this.state.numLaps}
                    onChange={(ev): void => {
                      this.setVariableToSet(ev, "numLaps");
                    }}
                  />
                </Col>
              </Form.Group>
            ) : (
              <></>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-cycling-secondary"
            onClick={(): void => this.hideWindow()}
          >
            Cancel
          </Button>
          <Button
            className="btn-cycling"
            onClick={(): void => this.saveVariableToSet()}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  /**
   * Returns the modal body
   * @param {string} variable numLaps or numQualify
   * @returns {Object} The JSX Element with the modal body
   */
  public getModalBody(variable: string): JSX.Element {
    switch (variable) {
      case "numQualify":
        return (
          <span>
            Number of riders that qualify for final must be a positive integer.
          </span>
        );
      case "numLaps":
        return <span>Number of laps must be a positive integer.</span>;
      default:
        return <span>Error.</span>;
    }
  }

  /**
   * Gets all the ids for the races for which num_qualify will be updated
   * @returns {Object} The array of race Ids (numbers)
   */
  private getRaceIds(): number[] {
    const raceIds: number[] = [];
    const races: IRace[] = Object.values(store.getState().races);
    if (!isUndefined(races)) {
      for (let i = 0; i < races.length; i++) {
        if (
          races[i].category === this.props.raceCategory &&
          races[i].name === this.props.raceName
        ) {
          raceIds.push(races[i].id);
        }
      }
    }
    return raceIds;
  }

  /**
   * Hides the dialog
   */
  public hideWindow(): void {
    store.dispatch(updateRaceDetailsState(false));
  }

  /**
   * Updates the database with the new variableToSet value
   */
  public saveVariableToSet(): void {
    const numQualify = parseInt(this.state.numQualify);
    let numLaps = parseInt(this.state.numLaps);
    let numQualifyPasses = false;
    let numLapsPasses = false;
    if (this.props.raceType === KEIRIN && isNaN(numLaps)) {
      numLaps = KEIRINNUMOFLAPS;
    }

    if (this.props.raceType === KEIRIN) {
      if (isNaN(numQualify) || numQualify < 1) {
        this.setState({
          showModal: true,
          modalHeader: "Error!",
          modalBody: this.getModalBody("numQualify"),
        });
      } else {
        numQualifyPasses = true;
        numLapsPasses = true;
      }
    }

    if (this.props.raceType !== KEIRIN) {
      if (isNaN(numLaps) || numLaps < 1) {
        this.setState({
          showModal: true,
          modalHeader: "Error!",
          modalBody: this.getModalBody("numLaps"),
        });
      } else {
        numQualifyPasses = true;
        numLapsPasses = true;
      }
    }

    if (numQualifyPasses && numLapsPasses) {
      const raceIds = this.getRaceIds();
      for (let i = 0; i < raceIds.length; i++) {
        store
          .dispatch(saveRaceDetails(raceIds[i], numQualify, numLaps))
          .then(() => {
            // resend race results if there are results
            let currentRace: IRace | null = null;
            const results = {};
            this.props.races.forEach(race => {
              // backend only wants one race's results, so we'll just send Heat 1
              if (
                race.id === raceIds[i] &&
                race.subcategory &&
                race.subcategory === "Heat 1"
              ) {
                currentRace = race;
                if (race.riders && race.riders[0].placing) {
                  race.riders.forEach(rider => {
                    results[rider.id] = { placing: rider.placing };
                  });
                }
              }
            });
            if (currentRace && Object.keys(results).length > 0) {
              store.dispatch(sendRaceResults(currentRace, results));
            }
          });
      }
      this.hideWindow();
    }
  }

  /**
   * Sets the state to whether it shows the modal or not
   * @param {boolean} show Show the modal or not
   */
  public setShowModal(show: boolean): void {
    this.setState({ showModal: show });
  }

  /**
   * Changes the state variable variableToSet when form entries are overwriten
   * @param {Object} ev The event
   */
  private setVariableToSet(ev, variable: string): void {
    if (variable === "numLaps") {
      this.setState({ numLaps: ev.target.value });
    }
    if (variable === "numQualify") {
      this.setState({ numQualify: ev.target.value });
    }
  }

  render(): React.ReactNode {
    return (
      <>
        {this.getModal()}
        <WindowAlertModal
          modalHeader={this.state.modalHeader}
          modalBody={this.state.modalBody}
          showModal={this.state.showModal}
          onHide={(): void => this.setShowModal(false)}
        />
      </>
    );
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any>(mapStateToProps)(EditRaceDetails);
