/**
 * Map relation between API and Frontend
 */
export const RaceAPIMap = {
  raceDatetime: {
    format: (value): Date => new Date(value),
  },
  lockedDatetime: {
    format: (value): Date | undefined => {
      if (value === null) {
        return undefined;
      } else {
        return new Date(value);
      }
    },
  },
};
