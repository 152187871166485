import { captureException } from "@sentry/browser";
import { cloneDeep, debounce } from "lodash";
import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Prompt, Link } from "react-router-dom";

import ComponentHider from "components/Shared/ComponentHider";
import GetMindBodyRiders from "components/Shared/GetMindBodyRiders";
import LoadingSpinner from "components/Shared/LoadingSpinner";
import { Event } from "models/Event";
import { EventBar } from "models/EventBar";
import ISeedUI from "models/ISeedUI";
import { Rider } from "models/Rider";
import store from "store";
import { updateWasSeedingChanged } from "store/events/actions";
import { saveRaceRiders } from "store/race/actions";
import { IRace, IRaceType, IRiderBibs } from "store/race/types";
import { getEventRaceRiders, updateEventRaces } from "store/races/actions";
import { updateInvalidBibs } from "store/views/actions";
import {
  SEEDING,
  SEEDING_STR,
  CATEGORY,
  SUBCATEGORY,
  RIDER,
  UNASSIGNED_STR,
} from "utils/constants";
import { sortRaceSubcategories } from "utils/utils";

import AddCategory from "../AddCategory";
import AddRiderButton from "../AddRiderButton";
import AddRiderDetails from "../AddRiderDetails";
import ButtonGroupClass from "../ButtonGroup";
import CreateCSV from "../CreateCSV";
import DeleteCategory from "../DeleteCategory";
import UpdateStartingLineups from "../UpdateStartingLineups";

interface ISeedProps {
  eventId: number;
  raceTypes: IRaceType[];
  event: Event;
  races: IRace[];
  invalidBibs: Set<string>;
  eventBar: EventBar;
}

interface ISeedState {
  event: Event;
  seed: ISeedUI[];
  raceTypes: IRaceType[];
  loading: boolean;
  dragAndDrop: boolean;
  riderBibs: IRiderBibs;
  bibChangesMade: boolean;
}

function mapStateToProps(
  state
): {
  raceTypes: IRaceType[];
  event: Event;
  races: IRace[];
  invalidBibs: Set<string>;
  eventBar: EventBar;
} {
  return {
    event: state.event.event,
    raceTypes: state.raceTypes.raceTypes,
    races: Object.values(state.races),
    invalidBibs: state.views.event.invalidBibs,
    eventBar: state.eventBar,
  };
}

const UpdateStartingLineupsWithHider = ComponentHider(UpdateStartingLineups);
const GetMindBodyRidersWithHider = ComponentHider(GetMindBodyRiders);
const AddRiderButtonWithHider = ComponentHider(AddRiderButton);
const AddCategoryWithHider = ComponentHider(AddCategory);

export class SeedingPage extends React.Component<ISeedProps, ISeedState> {
  public saveSeedDebounced = debounce(this.saveSeed, 2000);
  private raceHash: { [key: number]: IRace } = {};
  private hasSubcategory = false;

  constructor(props: ISeedProps) {
    super(props);
    this.state = {
      seed: [],
      raceTypes: this.props.raceTypes,
      event: this.props.event,
      loading: false,
      dragAndDrop: false,
      riderBibs: {},
      bibChangesMade: false,
    };
  }

  async componentDidMount(): Promise<void> {
    store.dispatch(updateInvalidBibs(new Set()));
    // Only load riders if the component was mounted with races
    if (this.props.races.length > 0) {
      this.loadRidersWrapper();
    }
  }

  componentDidUpdate(prevProps: ISeedProps): void {
    // Update if displayed races/riders have changed,
    // and duplicate bibs have not changed (do not re-render if user was typing bib numbers)
    if (
      this.props.races !== prevProps.races &&
      this.props.invalidBibs === prevProps.invalidBibs
    ) {
      if (this.props.races.length > 0 && prevProps.races.length === 0) {
        // Load everything again if no races were loaded before
        this.loadRidersWrapper();
      } else {
        // Only call the format function to update the UI if we already have races loaded
        if (
          !this.state.dragAndDrop ||
          this.props.races.length !== prevProps.races.length
        ) {
          const newSeed = this.formatSeedToScreen(false);
          this.setState({ seed: newSeed });
        }
      }
    }
  }

  shouldComponentUpdate(nextProps: ISeedProps, nextState: ISeedState): boolean {
    // Prevent updates while loading
    if (this.state.loading === true && nextState.loading === true) {
      return false;
    }
    // Prevent updates due to event bar changing i.e. when it's toggled
    if (
      this.props.eventBar !== nextProps.eventBar &&
      this.props.eventBar.racesOpenToggle.length > 0
    ) {
      return false;
    }
    return true;
  }

  /**
   * Selects between showing the GetMindBodyRiders or AddRiderButton button depending en
   * whether the event is a coming from MindBody or not
   * @returns {JSX.Element} The Button
   */
  chooseAddRiderButton(): JSX.Element {
    if (this.state.event.mindbodyId !== null) {
      return <GetMindBodyRidersWithHider view={"seeding"} />;
    } else {
      return <AddRiderButtonWithHider />;
    }
  }

  /**
   * Finds if an event is locked
   * @returns {boolean} Whether the event is locked or not
   */
  findIfEventIsLocked(): boolean {
    if (this.props.event.lock !== undefined && this.props.event.lock) {
      return true;
    }
    return false;
  }

  /**
   * Format a Seed object into a RLDD list
   * @param wasDragged Prevents built rider bibs from being overwritten
   */
  formatSeedToScreen(wasDragged): ISeedUI[] {
    this.raceHash = {};
    const seedsUI: ISeedUI[] = [];
    let id = 0;
    let previousCategory = "";
    const races: IRace[] = Object.values(store.getState().races);
    const riderBibs = {};
    const seenBibs: Set<string> = new Set();
    const invalidBibs: Set<string> = new Set();

    const addSeed = (data: ISeedUI): void => {
      seedsUI.push(data);
      id += 1;
    };
    // filter seeds by race type
    if (races) {
      races
        .filter((race: IRace) => race.raceType === SEEDING)
        .sort((a, b) => sortRaceSubcategories(a, b))
        .forEach((race: IRace) => {
          this.raceHash[race.id] = race;
          if (previousCategory !== race.category) {
            addSeed({
              id,
              data: race,
              listPosition: 0,
              type: CATEGORY,
              categoryRider: "",
            });
            previousCategory = race.category;
          }
          if (race.subcategory && race.subcategory !== "") {
            addSeed({
              id,
              data: race,
              listPosition: 0,
              type: SUBCATEGORY,
              categoryRider: "",
            });
            this.hasSubcategory = true;
          }
          // initially order by rank/name
          (race.riders || [])
            .sort((a, b) => {
              if (a.ranking === b.ranking) {
                return (a as Rider).getName() > (b as Rider).getName() ? 1 : -1;
              }
              return a.ranking < b.ranking ? 1 : -1;
            })
            .forEach((rider, idx) => {
              addSeed({
                id: rider.id,
                data: rider,
                listPosition: idx + 1,
                type: RIDER,
                categoryRider: race.category,
              });
              this.indexRiderBib(rider, riderBibs, seenBibs, invalidBibs);
            });
        });
    }

    // Stops built in store list from being overwritten if a rider was dragged
    // This is necessary as each bib box tracks its own internal state that can be separate from the one in the store
    // Without this, our seeding bibs state will be rebuilt from the ones in the store, and discard the internal states
    if (!wasDragged) {
      this.setState({ riderBibs });
      store.dispatch(updateInvalidBibs(invalidBibs));
    }

    return this.sortRLDDistByRank(seedsUI);
  }

  /**
   * Return the items re-arranged
   * @param reorderedItems
   * @returns {ISeedUI[]}
   */
  getDragDropItems(reorderedItems: ISeedUI[]): ISeedUI[] {
    const previousItems = this.state.seed;
    const increment = 0;
    const listLength = previousItems.length;

    for (let i = 0; i < listLength; i++) {
      if (previousItems[i].id !== reorderedItems[i + increment].id) {
        return [previousItems[i], reorderedItems[i]];
      }
    }
    return [];
  }

  /**
   * Checks riderBibs and returns a set of invalidBibs in it, if any
   * @param riderBibs
   */
  getInvalidBibs(riderBibs: IRiderBibs): Set<string> {
    const seenBibs: Set<string> = new Set();
    const invalidBibs: Set<string> = new Set();
    for (const bibNumber of Object.values(riderBibs)) {
      // Add to duplicate bibs if bib has already been seen
      if (seenBibs.has(bibNumber) || !this.isValidBib(bibNumber)) {
        invalidBibs.add(bibNumber);
      }
      seenBibs.add(bibNumber);
    }
    return invalidBibs;
  }

  /**
   * Get the style of the item being dragged, in this case, the background color
   * Needed for the drag and drop library being used
   * @param isDragging
   * @param draggableStyle
   */
  public getItemStyle = (isDragging, draggableStyle): {} => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    margin: `0 0 ${0.5}rem 0`,

    // change background colour if dragging
    background: isDragging ? "#0da7d67f" : null,

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  /**
   * Format a RLDD list after it is changed by the user
   * @param reorderedItems
   */
  handleRLDDChange(reorderedItems: ISeedUI[]): void {
    const draggedItems = this.getDragDropItems(reorderedItems);

    // not items re-arranged
    if (draggedItems.length <= 0) {
      return;
    }
    // cannot move category if its not a Mass Start race
    if (
      this.hasSubcategory &&
      (draggedItems[0].type === CATEGORY || draggedItems[1].type === CATEGORY)
    ) {
      return;
    }
    // make Category A fixed on top
    if ((reorderedItems[0].data as IRace).category !== "A") {
      return;
    }
    // cannot move a category/subcategory B to above category/subcategory A and vice versa
    let previousCategoryId = 0;
    for (const item of reorderedItems) {
      if (item.type === RIDER) {
        continue;
      }
      if (previousCategoryId !== 0 && item.id < previousCategoryId) {
        return;
      }
      previousCategoryId = item.id;
    }

    // sort by rank
    const itemsSorted = this.sortRLDDistByRank(reorderedItems);

    // change state and send data to the backend
    this.saveSeed(itemsSorted);
  }

  /**
   * Populates @riderBibs and @invalidBibs using @currentRider and @seenBibs
   */
  indexRiderBib(
    currentRider: Rider,
    riderBibs: IRiderBibs,
    seenBibs: Set<string>,
    invalidBibs: Set<string>
  ): void {
    const currentBib = currentRider.bib;
    if (currentBib !== undefined && currentBib !== null) {
      riderBibs[currentRider.id] = currentBib;

      // Add to duplicate bibs if bib has already been seen
      if (seenBibs.has(currentBib)) {
        invalidBibs.add(currentBib);
      } else {
        seenBibs.add(currentBib);
      }
    } else {
      riderBibs[currentRider.id] = "";
      invalidBibs.add("");
    }
  }

  /**
   * Checks if a bib number is valid
   * @param bibToTest
   */
  isValidBib(bibToTest: string): boolean {
    if (/^\+?\d+$/.test(bibToTest) === false || bibToTest === "0") {
      return false;
    }
    return true;
  }

  /**
   * Create a JSX Element with an item based on its type (Category or Rider)
   * @param {ISeedUI} item
   * @param {number} index Index in the list
   */
  itemRenderer(item: ISeedUI, index: number): JSX.Element {
    let disabled = false;
    if (this.findIfEventIsLocked()) {
      disabled = true;
    }
    if (item.type === RIDER) {
      return (
        <Draggable
          className="seeds__rider"
          key={item.id + "rider"}
          draggableId={item.id.toString() + "rider"}
          index={index}
          isDragDisabled={disabled}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {(provided: any, snapshot: any): JSX.Element => (
            <div
              className="seeds__rider"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={this.getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              <Row className="mr-0 ml-0 seeds__button-group-class">
                <ButtonGroupClass
                  item={item}
                  eventId={this.state.event.id}
                  onBibChange={(
                    riderId: number,
                    bibNumber: string
                  ): Promise<void> =>
                    this.onButtonGroupBibChange(riderId, bibNumber)
                  }
                />
              </Row>
            </div>
          )}
        </Draggable>
      );
    }
    // not really sure how to check if an object is of an interface. If you know how, we can remove the "item.data as IRace" from below
    if (item.type === CATEGORY) {
      return (
        <Draggable
          className="seeds__rider"
          key={item.id + "cat"}
          draggableId={item.id.toString() + "cat"}
          index={index}
          isDragDisabled={disabled}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {(provided: any, snapshot: any): JSX.Element => (
            <div
              className="header seeds__category"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={this.getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              <Row>
                <Col>
                  {(item.data as IRace).category !== UNASSIGNED_STR ? (
                    <span className="seeds__category-label-primary">
                      Category {(item.data as IRace).category}
                    </span>
                  ) : (
                    <span className="seeds__category-label-primary">
                      {(item.data as IRace).category}
                    </span>
                  )}
                </Col>
              </Row>
              <Row className="ml-0 mr-0 pt-2 seeds__category-secondary">
                <Col xs="1" className="seeds__drag-handle" />
                <Col xs="2" sm="3" md="2" lg="2">
                  <p>Bib #</p>
                </Col>
                <Col xs="4" sm="3" md="4" lg="4">
                  <p>Name</p>
                </Col>
                <Col xs="2" sm="2" md="2" lg="2">
                  <p className="seeds__category-label-centered">Skill Points</p>
                </Col>
                <Col xs="2" sm="2" md="2" lg="2">
                  <p className="seeds__category-label-centered seeds__confidence-header">
                    Confidence
                    <br />
                    Score (+/-)
                  </p>
                </Col>
                {/* Empty column to match the remove rider drowdown column */}
                <Col xs="1" />
              </Row>
              {((item.data as IRace).riders &&
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                (item.data as IRace).riders!.length > 0) ||
              (item.data as IRace).category === UNASSIGNED_STR ? (
                <span />
              ) : (
                <DeleteCategory race={item.data} />
              )}
            </div>
          )}
        </Draggable>
      );
    }
    return <div key="mynull"></div>;
  }

  /**
   * Triggers (everything) rendering the correct info. It all starts here.
   * @returns {?Object} The Promise, or undefined
   */
  loadRidersWrapper(): Promise<void> | undefined {
    this.setState({ loading: true });
    return store
      .dispatch(
        getEventRaceRiders({
          races: Object.values(this.props.races),
          raceName: SEEDING_STR,
        })
      )
      .then(() => {
        const newSeed = this.formatSeedToScreen(false);
        this.setState({ seed: newSeed, loading: false });
      });
  }

  /**
   * Handles rider bib numbers being changed in a button group
   * If bibNumber is valid, save it in the store automatically.
   * @param riderId
   * @param bibNumber
   */
  async onButtonGroupBibChange(
    riderId: number,
    bibNumber: string
  ): Promise<void> {
    const riderBibs: IRiderBibs = { ...this.state.riderBibs };
    riderBibs[riderId] = bibNumber;
    const invalidBibs = this.getInvalidBibs(riderBibs);
    try {
      this.setState({ riderBibs, bibChangesMade: true });
      await store.dispatch(updateInvalidBibs(invalidBibs));
      if (
        !this.getInvalidBibs(riderBibs).has(bibNumber) &&
        !Object.values(riderBibs).includes("")
      ) {
        this.updateRidersWithNewBibs();
      }
    } catch (err) {
      captureException(err);
    }
  }

  /**
   * When item is done being dragged do this (reorder, mark that seeding was changes, handleRLDDChange.)
   * @param result
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onDragEnd(result: any): void {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = this.reorder(
      this.state.seed,
      result.source.index,
      result.destination.index
    );

    const source = this.state.seed[result.source.index];
    const destination = this.state.seed[result.destination.index];
    try {
      this.setState({ seed: items, dragAndDrop: true });
      if (source.categoryRider !== destination.categoryRider) {
        // If different it means a rider was moved out of that category
        try {
          store.dispatch(
            updateWasSeedingChanged({
              wasRiderRemoved: null,
              wasRiderDragged: true,
            })
          );
        } catch (error) {
          captureException(
            `Error with updateWasSeedingChanged in SeedingPage: ${error}`
          );
        }
      }
    } catch (err) {
      captureException(err);
    }
    this.handleRLDDChange(items);
  }

  /**
   * Reorders the seeding list where the item with index was dragged and dropped
   * from startIndex to endIndex
   * @param {ISeedUI[]} list
   * @param {number} startIndex
   * @param {number} endIndex
   * @returns {ISeedUI[]}
   */
  public reorder = (
    list: ISeedUI[],
    startIndex: number,
    endIndex: number
  ): ISeedUI[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * Change the seed list with the new rider's category
   * @param reorderedItems
   */
  saveSeed(reorderedItems): void {
    const updatedRaces: IRace[] = [];

    reorderedItems.reduce((acc, item) => {
      if (!(item.data instanceof Rider)) {
        acc = cloneDeep(item.data);
        acc.riders = [];
        updatedRaces.push(acc);
        return acc;
      }

      acc.riders.push(item.data);
      return acc;
    }, {});

    // call backend to save the data
    store.dispatch(saveRaceRiders(updatedRaces)).then(races => {
      if (races) {
        return store
          .dispatch(
            getEventRaceRiders({
              races: Object.values(races),
              raceName: SEEDING_STR,
            })
          )
          .then(() => {
            const newSeed = this.formatSeedToScreen(true);
            this.setState({ seed: newSeed, dragAndDrop: false });
            return newSeed;
          });
      } else {
        this.setState({ seed: reorderedItems, dragAndDrop: false });
      }
    });
  }

  /**
   * Show menssage when an event is empty and details are not set yet.
   * @memberof SeedingPage
   * @returns {JSX.Element} The paragraph with the info
   */
  showEventEmpty(): JSX.Element {
    return (
      <p>
        The details of this event have not yet been set up. Visit the{" "}
        <Link
          to={`/event/${this.props.event.id}/setup/`}
          className="seeds__link"
        >
          Event Setup Page
        </Link>{" "}
        to set it up.
      </p>
    );
  }

  /**
   * Sort a RLDD list by riders rank
   * @param {ISeedUI[]} reorderedItems
   * @returns {ISeedUI[]} RLDD list sorted by rank
   */
  sortRLDDistByRank(reorderedItems: ISeedUI[]): ISeedUI[] {
    let race: IRace | null = null;
    const categoriesId = {};
    const subcategoriesId = {};
    let lastCategoryId = 0;

    // group items by category
    const itemsGrouped = reorderedItems.reduce((acc, item) => {
      if (item.data instanceof Rider && race) {
        acc[race.id].push(item);
      } else {
        if (this.hasSubcategory && item.type === CATEGORY) {
          lastCategoryId = item.id;
          return acc;
        }

        if (item.type === CATEGORY) {
          categoriesId[item.data.id] = item.id;
        } else {
          categoriesId[item.data.id] = lastCategoryId;
          subcategoriesId[item.data.id] = item.id;
        }
        race = item.data as IRace;
        acc[race.id] = [];
      }
      return acc;
    }, {});

    let category = "";
    // recreate a RLDD list with the riders sorted by rank
    return reorderedItems
      .filter(item =>
        this.hasSubcategory ? item.type === SUBCATEGORY : item.type === CATEGORY
      )
      .map(item => item.data.id)
      .reduce((sortedItems: ISeedUI[], key) => {
        // add category
        if (this.raceHash[key].category !== category) {
          sortedItems.push({
            id: categoriesId[key],
            data: this.raceHash[key],
            listPosition: 0,
            type: CATEGORY,
            categoryRider: "",
          });
          category = this.raceHash[key].category;
        }

        // add subcategory
        if (this.raceHash[key].subcategory) {
          sortedItems.push({
            id: subcategoriesId[key],
            data: this.raceHash[key],
            listPosition: 0,
            type: SUBCATEGORY,
            categoryRider: "",
          });
        }

        // add riders by category/subcategory
        itemsGrouped[key]
          .sort((a, b) => {
            if (a.data.ranking === b.data.ranking) {
              // keep items sorted by its list id
              return a.id >= b.id ? 1 : -1;
            }
            return a.data.ranking < b.data.ranking ? 1 : -1;
          })
          .forEach(item => {
            sortedItems.push({
              id: item.id,
              data: item.data,
              listPosition: item.listPosition,
              type: RIDER,
              categoryRider: item.categoryRider,
            });
          });
        return sortedItems;
      }, []);
  }

  /**
   * Add new bib number to riders for SEEDING in the store.
   * Return UpdateRaces for easy testing.
   * @memberof UpdateStartingLineups
   * @returns {Promise<void>}
   */
  public updateRidersWithNewBibs(): IRace[] {
    const updatedRaces: IRace[] = [];
    Object.values(this.props.races).forEach(race => {
      if (race.raceType === SEEDING) {
        if (race.riders) {
          race.riders.forEach(rider => {
            if (rider.id in this.state.riderBibs) {
              rider.bib = this.state.riderBibs[rider.id];
            }
          });
        }
      }
      updatedRaces.push(race);
    });
    try {
      store.dispatch(updateEventRaces(updatedRaces));
    } catch (err) {
      captureException(err);
    }
    return updatedRaces;
  }

  render(): JSX.Element {
    const items = this.state.seed || [];
    if (this.state.loading) {
      return <LoadingSpinner />;
    }
    if (!this.props.event.isSetup) {
      return this.showEventEmpty();
    }

    return (
      <div>
        <Prompt
          when={this.state.bibChangesMade}
          message="You have unsaved rider bib number changes. Are you sure you want to leave?"
        />
        {/* Modal to add a Rider */}
        <AddRiderDetails />
        <Row className="mt-3 mb-2 ml-0 mr-0 seeds__header">
          <Col xs="auto" className="pl-0">
            <span className="seeds__name">Seeding</span>
          </Col>
          <div className="seeds__header-buttons">
            <Col>
              <CreateCSV
                races={this.props.races}
                raceType={SEEDING}
                raceTypes={this.state.raceTypes}
                event={this.state.event}
              />
            </Col>
            <Col xs="auto" className="seeds__add-rider-button-container">
              {this.chooseAddRiderButton()}
            </Col>
          </div>
        </Row>
        <div className="seeds__update-starting-lineups">
          <UpdateStartingLineupsWithHider
            riderBibs={this.state.riderBibs}
            bibChangesMade={this.state.bibChangesMade}
            onButtonClicked={(): void => {
              this.setState({ bibChangesMade: false });
            }}
          />
        </div>
        <DragDropContext
          onDragEnd={this.onDragEnd.bind(this)}
          key={this.props.event.id.toString() + "ddc"}
        >
          <Droppable
            droppableId={this.props.event.id.toString()}
            key={this.props.event.id.toString() + "drop"}
          >
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {(provided: any): JSX.Element => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                key={"aDroppable"}
              >
                {items.map((item, index) => this.itemRenderer(item, index))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <AddCategoryWithHider />
      </div>
    );
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any, any>(mapStateToProps)(SeedingPage);
