import { IEvent } from "../../models/Event";

import { Constants, Actions } from "./types";

export const initialStatus1 = [];

export const initialStatus2 = {
  event: {},
  seedingChanges: {
    wasRiderRemoved: false,
    wasRiderDragged: false,
  },
};

export function eventsReducer(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any = initialStatus1,
  action: Actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  let events: IEvent[];
  switch (action.type) {
    case Constants.RECEIVE_EVENTS: {
      events = action.payload.events;
      return { ...state, ...events };
    }
    case Constants.UPDATE_EVENT: {
      const eventsList: IEvent[] = Object.values(state);
      events = eventsList.map((event: IEvent) => {
        if (action.payload.event.id === event.id) {
          return action.payload.event;
        }
        return event;
      });
      return { ...state, ...events };
    }
    default:
      return state;
  }
}

export function eventReducer(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any = initialStatus2,
  action: Actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  switch (action.type) {
    case Constants.UPDATE_EVENT:
      return { ...state, event: action.payload.event };
    case Constants.LOCK_EVENT: {
      const newEvent = state.event;
      newEvent.lock = action.payload.lock;
      return { ...state, event: newEvent };
    }
    case Constants.UPDATE_WAS_SEEDING_CHANGED: {
      let wasRiderRemoved = state.seedingChanges.wasRiderRemoved;
      let wasRiderDragged = state.seedingChanges.wasRiderDragged;

      if (action.payload.wasRiderRemoved !== null) {
        wasRiderRemoved = action.payload.wasRiderRemoved;
      }
      if (action.payload.wasRiderDragged !== null) {
        wasRiderDragged = action.payload.wasRiderDragged;
      }
      return {
        ...state,
        seedingChanges: {
          wasRiderRemoved: wasRiderRemoved,
          wasRiderDragged: wasRiderDragged,
        },
      };
    }
    default:
      return state;
  }
}
