import { ActionType } from "typesafe-actions";

import { Rider } from "../../models/Rider";
import { IRace } from "../../store/race/types";

// Allow wildcard import for all actions
// eslint-disable-next-line import/no-namespace
import * as actions from "./actions";

export type Actions = ActionType<typeof actions>;

export enum Constants {
  ADD_RACES = "ADD_RACES",
  UPDATE_RACES = "UPDATE_RACES",
  UPDATE_RACE_NAMES = "UPDATE_RACE_NAMES",
  UPDATE_RACE_RIDERS = "UPDATE_RACE_RIDERS",
  UPDATE_RACES_RIDERS = "UPDATE_RACES_RIDERS",
  UPDATE_RACE_DETAILS = "UPDATE_RACE_DETAILS",
  ADD_NEW_RIDER = "ADD_NEW_RIDER",
  DELETE_RACES = "DELETE_RACES",
  LOCK_RACE = "LOCK_RACE",
  UPDATE_BIB = "UPDATE_BIB",
  UPDATE_MULTIPLE_BIBS = "UPDATE_MULTIPLE_BIBS",
  ADD_FINAL_RACES = "ADD_FINAL_RACES",
}

export interface AddRacesAction {
  type: typeof Constants.ADD_RACES;
  payload: { races: IRace[] };
}

export interface DeleteRacesAction {
  type: typeof Constants.DELETE_RACES;
  payload: { races: Set<number> };
}

export interface UpdateRacesAction {
  type: typeof Constants.UPDATE_RACES;
  payload: { races: IRace[] };
}

export interface UpdateRaceNamesAction {
  type: typeof Constants.UPDATE_RACE_NAMES;
  payload: { racesToUpdate: { [oldRaceName: string]: string } };
}

export interface UpdateRaceRidersAction {
  type: typeof Constants.UPDATE_RACE_RIDERS;
  payload: { race: IRace; riders: Rider[] };
}

export interface UpdateRacesRidersAction {
  type: typeof Constants.UPDATE_RACES_RIDERS;
  payload: { raceIdsWithRiders: { [raceId: string]: Rider[] } };
}

export interface UpdateRaceDetailsAction {
  type: typeof Constants.UPDATE_RACE_DETAILS;
  payload: {
    raceId: number;
    numQualify: number | null;
    numLaps: number | null;
  };
}

export interface AddNewRiderAction {
  type: typeof Constants.ADD_NEW_RIDER;
  payload: { rider: Rider; category: string };
}

export interface LockRaceAction {
  type: typeof Constants.LOCK_RACE;
  payload: {
    eventID: number;
    raceName: string;
    status: boolean;
    lockedDatetime: string | null;
  };
}

export interface UpdateBibAction {
  type: typeof Constants.UPDATE_BIB;
  payload: { riderId: number; bib: string };
}

export interface UpdateMultipleBibsAction {
  type: typeof Constants.UPDATE_MULTIPLE_BIBS;
  payload: { riderId: number; bib: string }[];
}

export interface AddFinalRacesAction {
  type: typeof Constants.ADD_FINAL_RACES;
  payload: { races: IRace[] };
}
