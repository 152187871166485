import { Constants, Actions, IState } from "./types";

export const initialStatus: IState = {
  showCreateNewEventDetails: false,
  enableUpdateDetailsButton: false,
  event: {
    showAddRider: false,
    currentAddResultsRace: null,
    showCreateCSV: false,
    showEditRace: false,
    showLockRace: false,
    invalidBibs: new Set(),
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function viewsReducer(state: any = initialStatus, action: Actions): any {
  let event;
  switch (action.type) {
    case Constants.UPDATE_ADDRIDER_STATE:
      event = { ...state.event, showAddRider: action.payload.newState };
      return { ...state, event };
    case Constants.UPDATE_ADDRESULTS_STATE:
      event = {
        ...state.event,
        currentAddResultsRace: action.payload.newState,
      };
      return { ...state, event };
    case Constants.UPDATE_CREATECSV_STATE:
      event = { ...state.event, showCreateCSV: action.payload.newState };
      return { ...state, event };
    case Constants.SET_SHOWCREATENEWEVENTDETAILS:
      return {
        ...state,
        showCreateNewEventDetails: action.payload.showCreateNewEventDetails,
      };
    case Constants.SET_ENABLEUPDATEDETAILSBUTTON:
      return {
        ...state,
        enableUpdateDetailsButton: action.payload.enableUpdateDetailsButton,
      };
    case Constants.UPDATE_RACE_DETAILS_STATE:
      event = {
        ...state.event,
        showEditRace: action.payload.enableUpdateRaceDetailsButton,
      };
      return { ...state, event };
    case Constants.UPDATE_LOCK_RACE_STATE:
      event = { ...state.event, showLockRace: action.payload.newState };
      return { ...state, event };
    case Constants.UPDATE_INVALID_BIBS:
      event = { ...state.event, invalidBibs: action.payload.invalidBibs };
      return { ...state, event };
    default:
      return state;
  }
}
