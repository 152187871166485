import { ActionType } from "typesafe-actions";

import { Rider } from "../../models/Rider";

// Allow wildcard import for all actions
// eslint-disable-next-line import/no-namespace
import * as actions from "./actions";

export type Actions = ActionType<typeof actions>;

export enum Constants {
  UPDATE_RACE_TYPES = "UPDATE_RACE_TYPES",
  SET_LATEST_NEW_RIDERS = "SET_LATEST_NEW_RIDERS",
}

export interface IRace {
  id: number;
  raceType: number;
  raceDatetime: Date;
  name: string;
  numLaps: number | null;
  numQualify?: number | null;
  category: string;
  subcategory?: string;
  riders?: Rider[];
  locked?: boolean;
  lockedDatetime?: Date;
}

export interface IRaceType {
  id: number;
  name: string;
}

export interface UpdateRaceTypesAction {
  type: typeof Constants.UPDATE_RACE_TYPES;
  payload: { raceTypes: IRaceType[] };
}

export interface SetLastestNewRidersAction {
  type: typeof Constants.SET_LATEST_NEW_RIDERS;
  payload: { latestNewRiders: number[] };
}

export interface IRiderBibs {
  [riderId: number]: string;
}

/**
 *
 */
export const raceFromInterface = (race: IRace): IRace => {
  if (race.riders) {
    const newRiders = race.riders.map(rider => Rider.fromInterface(rider));
    race.riders = newRiders;
  }
  return race;
};
