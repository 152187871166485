import { IRace } from "store/race/types";

import { Rider } from "../../models/Rider";
import { SEEDING } from "../../utils/constants";

import { Actions, Constants } from "./types";

export const initialState = {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function racesReducer(state: any = initialState, action: Actions): any {
  switch (action.type) {
    case Constants.ADD_RACES: {
      const races = Object.values(state).concat(action.payload.races);
      return { ...state, ...races };
    }
    case Constants.ADD_FINAL_RACES: {
      const allRaces: IRace[] = [];
      const indexToSkip: number[] = [];
      const category = action.payload.races[0].category;
      const raceName = action.payload.races[0].name;

      // Replace old Finals/Consolations with new ones
      Object.values(state as IRace[]).forEach((race: IRace) => {
        let isDuplicate = false;
        for (let i = 0; i < action.payload.races.length; i++) {
          if (
            race.name === action.payload.races[i].name &&
            race.category === action.payload.races[i].category &&
            race.subcategory &&
            action.payload.races[i].subcategory &&
            race.subcategory === action.payload.races[i].subcategory
          ) {
            allRaces.push(action.payload.races[i]);
            indexToSkip.push(i);
            isDuplicate = true;
          }
        }

        // If the race wasn't replaced add it to allRaces (assuming it's not a final race)
        if (!isDuplicate) {
          if (
            race.subcategory &&
            race.category === category &&
            race.name === raceName
          ) {
            if (!race.subcategory.includes("Final")) {
              allRaces.push(race);
            }
          } else {
            allRaces.push(race);
          }
        }
      });

      // Add any new Finals/Consolations that haven't been added yet
      for (let j = 0; j < action.payload.races.length; j++) {
        if (!indexToSkip.includes(j)) {
          allRaces.push(action.payload.races[j]);
        }
      }

      return { ...allRaces };
    }
    case Constants.UPDATE_RACES: {
      return { ...action.payload.races };
    }
    case Constants.UPDATE_RACE_NAMES: {
      if (state) {
        const racesUpdated: IRace[] = Object.values(state as IRace[]).map(
          (race: IRace) => {
            if (race.name in action.payload.racesToUpdate) {
              race.name = action.payload.racesToUpdate[race.name];
              return race;
            }
            return race;
          }
        );
        return { ...state, ...racesUpdated };
      }
      return state;
    }
    case Constants.UPDATE_RACE_RIDERS: {
      if (state) {
        const racesUpdated: IRace[] = Object.values(state as IRace[]).map(
          (race: IRace) => {
            if (action.payload.race.id === race.id) {
              const updatedRiders: Rider[] = [];
              action.payload.riders.forEach(payloadRider => {
                updatedRiders.push(payloadRider);
              });
              action.payload.race.riders = updatedRiders;
              return action.payload.race;
            }
            return race;
          }
        );
        return { ...state, ...racesUpdated };
      }
      return state;
    }
    case Constants.UPDATE_RACES_RIDERS: {
      if (state) {
        const racesUpdated: IRace[] = [];
        Object.values(state as IRace[]).forEach((race: IRace) => {
          if (race.id in action.payload.raceIdsWithRiders) {
            race.riders = action.payload.raceIdsWithRiders[race.id];
          }
          racesUpdated.push(race);
        });
        return { ...state, ...racesUpdated };
      }
      return state;
    }
    case Constants.UPDATE_RACE_DETAILS: {
      const updatedRaces = Object.values(state as IRace[]).map(
        (race: IRace) => {
          if (race.id === action.payload.raceId) {
            const newRace = race;
            newRace.numQualify = action.payload.numQualify;
            newRace.numLaps = action.payload.numLaps;
            return newRace;
          }
          return race;
        }
      );
      return { ...state, ...updatedRaces };
    }
    case Constants.ADD_NEW_RIDER: {
      const updatedRaces = Object.values(state as IRace[]).map(
        (race: IRace) => {
          if (
            race.category === action.payload.category &&
            race.raceType === SEEDING &&
            race.riders
          ) {
            const riders = race.riders;
            const newRace = race;
            riders.push(action.payload.rider);
            newRace.riders = riders;
            return newRace;
          }
          return race;
        }
      );
      return { ...state, ...updatedRaces };
    }
    case Constants.DELETE_RACES: {
      const updatedRaces = Object.values(state as IRace[]).filter(
        (race: IRace) => {
          return !action.payload.races.has(race.id);
        }
      );
      return { ...updatedRaces };
    }
    case Constants.LOCK_RACE: {
      const updatedRaces = Object.values(state as IRace[]).map(
        (race: IRace) => {
          if (race.name === action.payload.raceName) {
            const newRace = race;
            newRace.locked = action.payload.status;
            if (
              action.payload.lockedDatetime !== null &&
              action.payload.lockedDatetime !== undefined
            ) {
              newRace.lockedDatetime = new Date(action.payload.lockedDatetime);
            } else {
              newRace.lockedDatetime = undefined;
            }
            return newRace;
          }
          return race;
        }
      );
      return { ...state, ...updatedRaces };
    }
    case Constants.UPDATE_BIB: {
      const races = Object.values(state as IRace[]).map((race: IRace) => {
        const newRace = race;
        const newRiders: Rider[] = [];
        if (race.riders) {
          race.riders.forEach(rider => {
            if (rider.id === action.payload.riderId) {
              const newRider = rider;
              newRider.bib = action.payload.bib;
              newRiders.push(newRider);
            } else {
              newRiders.push(rider);
            }
          });
          newRace.riders = newRiders;
          return newRace;
        } else {
          return race;
        }
      });
      return { ...state, ...races };
    }
    case Constants.UPDATE_MULTIPLE_BIBS: {
      const races = Object.values(state as IRace[]).map((race: IRace) => {
        const newRace = race;
        const newRiders: Rider[] = [];
        if (race.riders) {
          race.riders.forEach(rider => {
            const matchingRiderBib = action.payload.find(riderBib => {
              return rider.id === riderBib.riderId;
            });
            if (matchingRiderBib !== undefined) {
              const newRider = rider;
              newRider.bib = matchingRiderBib.bib;
              newRiders.push(newRider);
            } else {
              newRiders.push(rider);
            }
          });
          newRace.riders = newRiders;
          return newRace;
        } else {
          return race;
        }
      });
      return { ...state, ...races };
    }
    default:
      return state;
  }
}
