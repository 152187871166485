import React from "react";
import { Button } from "react-bootstrap";

import store from "store";
import { IRace } from "store/race/types";
import { randomizeRiders } from "store/races/actions";

interface IRandomizeLineupProps {
  race: IRace;
  callBackFn: () => Promise<void> | undefined;
}

export default class RandomizeLineup extends React.Component<
  IRandomizeLineupProps
> {
  /**
   * Checks if the number of qualifying riders is filled out before allowing results to be uploaded
   */
  public randomizeOrders(): void {
    // this.props.races is potentially outdated when randomizeOrders() is fired due to the props being
    // passed in here being too deep in the parent (Riders), so we have to get the latest props from the store.
    const races: IRace[] = store.getState().races;
    Object.values(races).forEach((race: IRace) => {
      if (race.id === this.props.race.id) {
        store
          .dispatch(randomizeRiders(race))
          // We also need to do the callback due to the lock button sometimes clearing the riders from a race,
          // so we have to manually request all of the riders from the backend again, and this callback can
          // potentially be removed in the future when the lock races button is refactored.
          .then(() => this.props.callBackFn());
      }
    });
  }
  /**
   * It return true if riders in the race, added by props, have results (placing), false otherwise.
   * It is going to be use for disable randomize button.
   *
   * @private
   * @returns {boolean}
   * @memberof RandomizeLineup
   */
  private ridersHaveResults(): boolean {
    const race = this.props.race;
    if (race.riders && race.riders.length && race.riders[0].placing) {
      return true;
    } else {
      return false;
    }
  }

  public render(): JSX.Element {
    return (
      <>
        <Button
          className="btn-cycling-secondary"
          id={`randomize ${this.props.race.id}`}
          onClick={(): void => this.randomizeOrders()}
          disabled={this.ridersHaveResults()}
        >
          Randomize Starting Order
        </Button>
      </>
    );
  }
}
