import { Constants, Actions } from "./types";

export const initialStatus = {
  raceTypes: [],
  latestNewRiders: [],
};

export function raceReducer(state: any = initialStatus, action: Actions) {
  switch (action.type) {
    case Constants.UPDATE_RACE_TYPES:
      return { ...state, raceTypes: action.payload.raceTypes };
    case Constants.SET_LATEST_NEW_RIDERS:
      return { ...state, latestNewRiders: action.payload.latestNewRiders };
    default:
      return state;
  }
}
