import React from "react";
import { ListGroup } from "react-bootstrap";

import history from "utils/history";
import { convertRaceTypeToUrl, convertRaceNameForUrl } from "utils/utils";

interface IEventBarProps {
  raceTypesHash: { [key: number]: string };
  eventId: number;
  raceName: string;
  raceType: number;
  raceCategory: string;
}

export class EventBarItem extends React.Component<IEventBarProps> {
  /**
   * Appends 'Category' to the start of strings, unless it is 'Unassigned'
   * @param {string} stringToAppend The string to which the word Category will be appended
   *  @returns {string} The new string: 'Category stringToAppend'
   */
  getCategoryString(stringToAppend: string): string {
    return `Category ${stringToAppend}`;
  }

  /**
   * Gets the string of the url for that category in a race
   * @param {string} currentRaceName The current race name
   * @param {number} currentRaceType The current race type
   * @param {string} currentRaceCategory The current race category
   *  @returns {string} A url
   */
  getRaceCategoryUrl(
    currentRaceName: string,
    currentRaceType: number,
    currentRaceCategory: string
  ): string {
    return `/event/${this.props.eventId}/${convertRaceNameForUrl(
      currentRaceName
    )}/${convertRaceTypeToUrl(
      this.props.raceTypesHash,
      currentRaceType
    )}/${currentRaceCategory}/`;
  }

  public render(): JSX.Element {
    return (
      <ListGroup.Item
        className={
          window.location.pathname ===
          this.getRaceCategoryUrl(
            this.props.raceName,
            this.props.raceType,
            this.props.raceCategory
          )
            ? "items items-indented items-highlighted"
            : "items items-indented"
        }
        onClick={(): void => {
          history.push(
            this.getRaceCategoryUrl(
              this.props.raceName,
              this.props.raceType,
              this.props.raceCategory
            )
          );
        }}
      >
        {this.getCategoryString(this.props.raceCategory)} &nbsp;
      </ListGroup.Item>
    );
  }
}

export default EventBarItem;
