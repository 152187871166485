// Race Types
// If these get changed, don't forget to change the mocks/raceTypes.json file, too.
export const SEEDING = 1;
export const CUSTOM = 2;
export const FLYINGSTART = 3;
export const TWOHUNDREDMTT = 4;
export const STANDINGSTART = 5;
export const ANTIOQUENARACE = 6;
export const AUSTRALIANPURSUIT = 7;
export const BELGIUMWINANDOUT = 8;
export const CHARIOT = 9;
export const ELIMINATION = 10;
export const FLYINGLAP = 11;
export const KEIRIN = 12;
export const LONGESTLAP = 13;
export const MADISON = 14;
export const MADISONFLYINGLAP = 15;
export const MATCHSPRINT = 16;
export const MOTOSCRATCH = 17;
export const OMNIUM = 18;
export const POINTSRACE = 19;
export const SCRATCHRACE = 20;
export const SNOWBALL = 21;
export const SPRINTSCRATCH = 22;
export const TEMPORACE = 23;
export const UNKNOWNDISTANCE = 24;
export const WHEELRACE = 25;
export const WINANDOUT = 26;

// Prevent prettier from removing double quotes for only
// some strings in the below map
// prettier-ignore
export const raceTypeStringToInt = {
  "Seeding": SEEDING,
  "Custom": CUSTOM,
  "200m-Flying-Start": FLYINGSTART,
  "200m-TT": TWOHUNDREDMTT,
  "250m-Standing-Start": STANDINGSTART,
  "Antioquena-Race":ANTIOQUENARACE,
  "Australian-Pursuit": AUSTRALIANPURSUIT,
  "Belgium-Win-&-Out": BELGIUMWINANDOUT,
  "Chariot": CHARIOT,
  "Elimination": ELIMINATION,
  "Flying-Lap": FLYINGLAP,
  "Keirin": KEIRIN,
  "Longest-Lap": LONGESTLAP,
  "Madison": MADISON,
  "Madison-Flying-Lap": MADISONFLYINGLAP,
  "Match-Sprint": MADISONFLYINGLAP,
  "Moto-Scratch": MOTOSCRATCH,
  "Omnium": OMNIUM,
  "Points-Race": POINTSRACE,
  "Scratch-Race": SCRATCHRACE,
  "Snowball": SNOWBALL,
  "Sprint-Scratch": SPRINTSCRATCH,
  "Tempo-Race": TEMPORACE,
  "Unknown-Distance": UNKNOWNDISTANCE,
  "Wheel-Race": WHEELRACE,
  "Win-&-Out": WINANDOUT,
};

// Seeding race string
export const SEEDING_STR = "Seeding";
// Unassigned Category
export const UNASSIGNED_STR = "Unassigned";

// ISeedUI Types (in models/ISeedUI)
export const CATEGORY = "category";
export const SUBCATEGORY = "subcategory";
export const RIDER = "rider";

// Placing Constants
export const DIDNOTFINISH = "DNF";
export const DIDNOTSTART = "DNS";

// Number of laps
export const KEIRINNUMOFLAPS = 7;

// Max riders
export const KEIRINMAXRIDERS = 7;
export const KEIRINCONSOLATIONMAXRIDERS = 6;
