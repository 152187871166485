import { captureException } from "@sentry/browser";
import moment from "moment";

import eventsApi from "services/events/eventsAPI";
import store from "store";
import { getEvent } from "store/events/actions";
import { getRaceTypes } from "store/race/actions";
import { IRace, IRaceType } from "store/race/types";
import { updateEventRaces } from "store/races/actions";
import {
  raceTypeStringToInt,
  DIDNOTSTART,
  DIDNOTFINISH,
} from "utils/constants";

/**
 * Return date in new format
 * @param date
 * @param format
 */
export const formatDate = (date: Date, format = "MMMM DD, YYYY"): string => {
  return moment(date).format(format);
};

/**
 * Sort rider in a category and subcategories considering Heat and Final
 * @param {*} a
 * @param {*} b
 * @returns number
 */
export const sortRaceSubcategories = (a: IRace, b: IRace): number => {
  if (
    a.category === b.category &&
    a.subcategory !== null &&
    a.subcategory !== undefined &&
    b.subcategory !== null &&
    b.subcategory !== undefined
  ) {
    if (
      (a.subcategory.includes("Heat") && !b.subcategory.includes("Heat")) ||
      (b.subcategory.includes("Heat") && !a.subcategory.includes("Heat"))
    ) {
      return 1;
    }
    if (
      (a.subcategory.includes("Final") && !b.subcategory.includes("Final")) ||
      (b.subcategory.includes("Final") && !a.subcategory.includes("Final"))
    ) {
      return 1;
    }
    return a.subcategory > b.subcategory ? 1 : -1;
  }
  return a.category > b.category ? 1 : -1;
};

/**
 * Returns number with ordinal suffix
 * @param inputNumber
 */
export const getNumberWithOrdinal = (
  inputNumber: number | string | undefined
): string => {
  if (inputNumber === undefined) {
    return "";
  }
  const suffix = ["th", "st", "nd", "rd"];
  let lastTwoDigits: number;
  if (typeof inputNumber === "string") {
    if (inputNumber === DIDNOTSTART || inputNumber === DIDNOTFINISH) {
      return inputNumber;
    }
    lastTwoDigits = parseInt(inputNumber) % 100;
  } else {
    lastTwoDigits = (inputNumber as number) % 100;
  }
  return (
    inputNumber +
    (suffix[(lastTwoDigits - 20) % 10] || suffix[lastTwoDigits] || suffix[0])
  );
};

/**
 * Map race type string to numerical representation
 * @param {string } raceType
 * @returns {number} The numerical race type
 */
export const mapRaceTypeStringToInt = (raceTypeString: string): number => {
  return raceTypeStringToInt[raceTypeString];
};

/**
 * Builds a mapping of race type ints to the race type names
 * @param raceTypes
 */
export const buildRaceTypesHash = (
  raceTypes: IRaceType[]
): { [key: number]: string } => {
  // Remove seeding (race id = 1) from raceTypes
  const raceTypesMinus1 = raceTypes.filter(race => {
    return race.id !== 1;
  });
  const raceTypesHash = raceTypesMinus1.reduce((acc, type) => {
    acc[type.id] = type.name;
    return acc;
  }, {});
  return raceTypesHash;
};

/**
 * Converts a race type hash with race type int into a URL for react router
 * @param {Object} raceTypesHash
 * @param {number} raceType
 */
export const convertRaceTypeToUrl = (
  raceTypesHash,
  raceType: number
): string => {
  return raceTypesHash[raceType].replace(/\s/g, "-");
};

/**
 * Replaces spaces in a race name string by hyphens so that we can use that string in the URL
 * @param {string} raceName
 * @returns {string} The new string where all spaces have been replaced by hyphens
 */
export const convertRaceNameForUrl = (raceName): string => {
  return raceName.trim().replace(/\s/g, "-");
};

/**
 * Replaces spaces in a race name string by hyphens so that we can use that string in the URL
 * @param {string} raceName
 * @returns {string} The new string where all spaces have been replaced by hyphens
 */
export const convertRaceNameFromUrl = (raceName): string => {
  return raceName.replace(/-/g, " ");
};

/**
 * Loads details for an event and its associated races
 * @param {number} eventId
 */
export const loadEvent = async (eventId: number): Promise<void> => {
  await store.dispatch(getRaceTypes());
  return store
    .dispatch(getEvent(eventId))
    .then(event => eventsApi.getEventRaces(event.id))
    .then(eventRaces => {
      store.dispatch(updateEventRaces(eventRaces));
    })
    .catch(err => {
      captureException(err);
    });
};
