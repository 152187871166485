import React from "react";
import { Spinner } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";

import CreateNewEvent from "../../components/Home/CreateNewEvent";
import EventTile from "../../components/Home/EventTile";
import Layout from "../../components/Shared/Layout";
import { IEvent, IEventsAPIData } from "../../models/Event";
import store from "../../store";
import { getEvents } from "../../store/events/actions";
import { getRaceTypes } from "../../store/race/actions";
import { IRaceType } from "../../store/race/types";

interface IHomeProps {
  raceTypes: IRaceType[];
  events: IEvent[];
}

function mapStateToProps(state): IHomeProps {
  return {
    raceTypes: state.raceTypes.raceTypes,
    events: state.events.events,
  };
}

export class Home extends React.Component<IHomeProps> {
  /**
   * Get all events and their related races.
   * @memberof Home
   */
  eventstoLoad = 8;

  state: {
    pageToLoad: number;
    events: IEvent[];
    oldEvents: [];
    hasMoreEvents: boolean;
  } = {
    pageToLoad: 1,
    events: [],
    oldEvents: [],
    hasMoreEvents: true,
  };

  componentDidMount(): void {
    this.loadEvents();
  }

  fetchNextTiles(): void {
    this.setState({ pageToLoad: this.state.pageToLoad + 1 });
    this.loadEvents();
  }

  loadEvents(): void {
    store
      .dispatch(getEvents(this.state.pageToLoad, this.eventstoLoad))
      .then((receivedData: IEventsAPIData) => {
        if (this.state.pageToLoad >= receivedData.pages) {
          this.setState({ hasMoreEvents: false });
        }

        const events = [...this.state.oldEvents, ...receivedData.events];
        this.setState({ events, oldEvents: events });
        store.dispatch(getRaceTypes());
      });
  }

  onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ inputText: e.target.value });
  };

  render(): JSX.Element | null {
    if (!this.state.events) {
      return null;
    }

    const events = this.state.events.sort((eventA: IEvent, eventB: IEvent) =>
      eventA.startDatetime.getTime() < eventB.startDatetime.getTime() ? 1 : -1
    );

    const tiles: JSX.Element[] = events.map(event => (
      <EventTile event={event} key={event.id} />
    ));

    return (
      <Layout>
        <div className="home container">
          <InfiniteScroll
            className="home__events-container"
            dataLength={tiles.length}
            next={this.fetchNextTiles.bind(this)}
            hasMore={this.state.hasMoreEvents}
            loader={
              <div className="home__events-container-loader">
                <div className="home__events-container-loader-content">
                  <span>
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  </span>
                  <h2>Loading</h2>
                </div>
              </div>
            }
          >
            <div id="events-list" className="home__events-list">
              <CreateNewEvent />
              {tiles}
            </div>
          </InfiniteScroll>
        </div>
      </Layout>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any>(mapStateToProps)(Home);
