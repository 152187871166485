import { isNullOrUndefined } from "util";

import log from "loglevel";
// eslint-disable-next-line import/named
import moment, { Moment } from "moment";
import React from "react";
import {
  Row,
  Col,
  Button,
  Dropdown,
  Table,
  Form,
  ButtonGroup,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { DateTimePicker } from "react-widgets";

import ComponentHider from "components/Shared/ComponentHider";
import GetMindBodyRiders from "components/Shared/GetMindBodyRiders";
import { Event } from "models/Event";
import store from "store";
import {
  saveEventRaceTypes,
  updateEvent,
  saveLockEvent,
} from "store/events/actions";
import { IRaceType, IRace } from "store/race/types";
import { updateRaceNames } from "store/races/actions";
import {
  SEEDING,
  SEEDING_STR,
  CUSTOM,
  SCRATCHRACE,
  KEIRIN,
} from "utils/constants";
import { buildRaceTypesHash } from "utils/utils";

import AddNewRowButton from "../AddNewRowButton";
import DeleteEventButton from "../DeleteEventButton";
import RemoveRowButton from "../RemoveRowButton";
import UpdateSettingsButton from "../UpdateSettingsButton";
import UploadFile from "../UploadFile";

interface IEventSetupPageProps {
  event: Event;
  raceTypes: IRaceType[];
  races: IRace[];
  enableUpdateDetailsButton: boolean;
}

export interface IEventSetupPageState {
  event: Event;
  currentRaceTypes: Set<number>;
  counter: number;
  selectedRaceTypes: ISelectedRaceType;
  updateStatus: string;
}

interface ISelectedRaceType {
  [raceId: string]: IRaceType | undefined;
}

function mapStateToProps(
  state
): {
  event: Event;
  raceTypes: IRaceType[];
  races: IRace[];
  enableUpdateDetailsButton;
} {
  return {
    event: state.event.event,
    raceTypes: state.raceTypes.raceTypes,
    races: Object.values(state.races),
    enableUpdateDetailsButton: state.views.enableUpdateDetailsButton,
  };
}

const AddNewRowButtonWithHider = ComponentHider(AddNewRowButton);
const RemoveRowButtonWithHider = ComponentHider(RemoveRowButton);
const UpdateSettingsButtonWithHider = ComponentHider(UpdateSettingsButton);

export class EventSetupPage extends React.Component<
  IEventSetupPageProps,
  IEventSetupPageState
> {
  private raceTypesHash: { [key: number]: string } = {};
  private raceNames = {};
  constructor(props: IEventSetupPageProps) {
    super(props);
    this.raceTypesHash = buildRaceTypesHash(this.props.raceTypes);
    this.state = {
      event: this.props.event,
      currentRaceTypes: new Set<number>(
        this.props.races.map(race => race.raceType)
      ),
      counter: 0,
      selectedRaceTypes: {},
      updateStatus: "",
    };
  }

  componentDidMount(): void {
    this.buildSelectedRaceTypesFromStore();

    // Create an empty race type to be selected if the event has
    // not been set up before
    if (this.props.races.length === 0) {
      this.addNewRow();
    }
  }

  /**
   * Takes the first unique race name for all races in the store and builds
   * selectedRaceNames from it, to represent races already in the event.
   */
  buildSelectedRaceTypesFromStore(): void {
    const selectedRaceTypes: ISelectedRaceType = {};
    const seenRaceNames: Set<string> = new Set([SEEDING_STR]);
    this.props.races.forEach(race => {
      if (!seenRaceNames.has(race.name)) {
        selectedRaceTypes[race.id.toString()] = {
          id: race.raceType,
          name: race.name,
        };
        this.addToRaceNames(race.name);
        seenRaceNames.add(race.name);
      }
    });
    this.setState({ selectedRaceTypes });
  }

  /**
   * Add the race name to the array with race names
   * @param {string} raceName The race name
   */
  addToRaceNames(raceName: string): void {
    const [raceNameWithoutNumber, raceNumber] = this.getStrippedRaceName(
      raceName
    );

    if (raceNameWithoutNumber in this.raceNames) {
      this.raceNames[raceNameWithoutNumber] = Math.max(
        this.raceNames[raceNameWithoutNumber],
        raceNumber
      );
    } else {
      this.raceNames[raceNameWithoutNumber] = raceNumber;
    }
  }

  /**
   * Get the race name from the race type plus a number
   * @param {string} raceName The race type
   * @returns {string} The race name which is the race type and a number
   */
  getRaceName(raceName: string): string {
    const raceNameWithoutNumber = this.getStrippedRaceName(raceName)[0];
    if (raceNameWithoutNumber in this.raceNames) {
      return `${raceNameWithoutNumber} ${this.raceNames[raceNameWithoutNumber] +
        1}`;
    }
    return `${raceNameWithoutNumber} 1`;
  }

  /**
   * Change the race name
   * @param {string} newRaceName The new race name
   * @param {string} id The id of the race
   */
  changeRaceName(id: string, newRaceName: string): void {
    const selectedRaceTypes = { ...this.state.selectedRaceTypes };
    const selectedRaceType = selectedRaceTypes[id];
    if (selectedRaceType !== undefined) {
      selectedRaceType.name = newRaceName;
    }
    this.setState({ selectedRaceTypes });
  }

  /**
   * Strip the number from the race name
   * @param {string} raceName The race name
   * @returns {Object<string, number>} [raceNameWithoutNumber, raceNumber]
   */
  getStrippedRaceName(raceName: string): [string, number] {
    let raceNameWithoutNumber = raceName;
    let raceNumber = 0;
    const raceNameNumberSplit = raceName.lastIndexOf(" ");

    // If there was a space, get the last characters after the space and check
    // if they are the race number
    // If there was no space, the string has no race number
    if (raceNameNumberSplit !== -1) {
      const testRaceNumber = raceName.slice(
        raceNameNumberSplit,
        raceName.length
      );

      // If raceNumber is indeed a number, strip it from the race name
      if (!isNaN(Number(testRaceNumber))) {
        raceNameWithoutNumber = raceName.slice(0, raceNameNumberSplit);
        raceNumber = Number(testRaceNumber);
      }
    }
    return [raceNameWithoutNumber, raceNumber];
  }

  /**
   * Remove row from list of races
   * @param {string} id The race id
   */
  removeRow(id: string): void {
    const selectedRaceTypes = { ...this.state.selectedRaceTypes };
    delete selectedRaceTypes[id];
    this.setState({ selectedRaceTypes });
  }

  /**
   * Set the selected race type
   * @param {string} id The race id
   * @param {IRaceType} raceType The race type
   */
  selectRaceType(id: string, raceType: IRaceType): void {
    const selectedRaceTypes = { ...this.state.selectedRaceTypes };
    let newRaceType: IRaceType;
    let raceName: string;
    if (
      raceType.id === SCRATCHRACE ||
      raceType.id === KEIRIN ||
      raceType.id === CUSTOM
    ) {
      newRaceType = { ...raceType };
      raceName = this.getRaceName(newRaceType.name);
    }
    // if the race type is not supported yet, treat it as a custom race type with a different name
    else {
      newRaceType = { id: CUSTOM, name: "Custom" };
      raceName = this.getRaceName(raceType.name);
    }
    this.addToRaceNames(raceName);
    newRaceType.name = raceName;
    selectedRaceTypes[id] = newRaceType;

    this.setState({ selectedRaceTypes });
  }

  /**
   * Get the new row ID based on the current counter
   * @returns {string} The id of the new race
   */
  createNewRow(): string {
    const id = `race${this.state.counter}`;
    const counterPlus = this.state.counter;
    this.setState({ counter: counterPlus + 1 });
    return id;
  }

  /**
   * Add a new row in list of selectedRaceTypes
   */
  addNewRow(): void {
    const selectedRaceTypes = { ...this.state.selectedRaceTypes };
    selectedRaceTypes[this.createNewRow()] = undefined;
    this.setState({ selectedRaceTypes });
  }

  /**
   * As it is using Datetime to store date and time, it is necessary to merge the date and the time
   * This function will merge a passing date and time and set it to a specified event field.
   * @param {Object } date The event data
   * @param {Object } time The event time for start or finish field
   * @param {Object } field The event time field (start or finish)
   */
  setEventDateTime(
    date: Moment,
    time: Moment,
    field: "startDatetime" | "endDatetime"
  ): void {
    const { event } = this.props;
    date.set({
      hour: time.get("hour"),
      minute: time.get("minute"),
    });
    if (event !== undefined) {
      event[field] = date.toDate();
      this.setState({ event });
    }
  }

  /**
   * Set a event date
   * @param {Object} value The date
   */
  setDate(value): void {
    if (this.state.event !== undefined) {
      this.setEventDateTime(
        moment(value),
        moment(this.state.event.startDatetime),
        "startDatetime"
      );
      this.setEventDateTime(
        moment(value),
        moment(this.state.event.endDatetime),
        "endDatetime"
      );
    }
  }

  /**
   * Set a event time
   * @param {Object} value The time
   * @param {Object} field Start time or finish(end) time
   */
  setTime(value, field): void {
    if (this.state.event !== undefined) {
      this.setEventDateTime(
        moment(this.state.event.startDatetime),
        moment(value),
        field
      );
    }
  }

  /**
   * Find out if the event has riders
   * @public
   * @returns {boolean} Whether the event has riders or not
   */
  public doesEventHaveRiders(): boolean {
    if (this.props.races) {
      return Object.values(this.props.races)
        .filter(race => race.raceType === SEEDING)
        .map(race => {
          if (race.riders) {
            return race.category !== "Unassigned" && race.riders.length > 0;
          }
          return true;
        })
        .reduce((a, b) => a || b, false);
    }
    return false;
  }

  /**
   * Display upload file or GetMindBodyRiders button depending on whether the event has riders
   * @public
   * @returns {Object} The button component
   */
  public getAddRidersButton(): JSX.Element {
    // TODO: Instead of just wrapping and hiding the button here, we might want to hide
    // this entire section with the <hr/> lines or it'll look weird
    if (!this.doesEventHaveRiders()) {
      if (this.state.event && this.state.event.mindbodyId === null) {
        return <UploadFile />;
      }
      return <GetMindBodyRiders view="eventDetails" />;
    }
    return (
      <p>
        Riders have already been added to this event. Visit the{" "}
        <Link
          to={`/event/${this.props.event.id}/seeding/`}
          className="event-setup__riders-added-text"
        >
          Seeding page
        </Link>{" "}
        to add more riders.
      </p>
    );
  }

  /**
   * Determines if the event details should be saved
   * @memberof EventSetupPage
   */
  checkSaveDetails(): void {
    // Checks that the event has riders added to it before,
    // or that riders were just added (via reading enabled props),
    // and that any race types have been selected
    if (
      (this.props.races.length > 0 ||
        this.props.enableUpdateDetailsButton === true) &&
      (this.state.currentRaceTypes.size > 0 ||
        Object.values(this.state.selectedRaceTypes).length > 0)
    ) {
      this.saveDetails();
    } else {
      this.setState({
        updateStatus:
          "There was an issue with updating the settings. You must select at least one race type and add riders to the race before proceeding.",
      });
    }
  }

  /**
   * Save the event details
   * @memberof EventSetupPage
   * @returns { Promise<void>} saveEventRaceTypes gets dispatch
   */
  async saveDetails(): Promise<void> {
    this.setState({ updateStatus: "Updating..." });
    const event = this.state.event;

    // Construct a mapping of old race names to new race names,
    // and store the race IDs in the store in a set
    const updatedRaceNames: { [oldRaceName: string]: string } = {};
    const raceIdsInStore = new Set<string>();
    this.props.races.forEach(race => {
      if (race.id in this.state.selectedRaceTypes) {
        const selectedRaceType = this.state.selectedRaceTypes[race.id];
        let updatedRaceName = "";
        if (!isNullOrUndefined(selectedRaceType)) {
          updatedRaceName = selectedRaceType.name;
        }
        if (race.name !== updatedRaceName) {
          updatedRaceNames[race.name] = updatedRaceName;
        }
      }
      raceIdsInStore.add(race.id.toString());
    });

    // Find out what the new races are by comparing our selected race types
    // against the race IDs in the store
    const newRaces: {
      type: number;
      name: string;
    }[] = [];
    Object.entries(this.state.selectedRaceTypes).forEach(([id, raceType]) => {
      if (!raceIdsInStore.has(id) && !isNullOrUndefined(raceType)) {
        newRaces.push({ type: raceType.id, name: raceType.name });
      }
    });

    try {
      await store.dispatch(
        saveEventRaceTypes(event.id.toString(), { updatedRaceNames, newRaces })
      );
      // Update event setup status to be true if it wasn't before
      if (isNullOrUndefined(event.isSetup) || event.isSetup === false) {
        event.isSetup = true;
        store.dispatch(updateEvent(event));
      }
      // Update race names in the store to the ones entered
      if (Object.values(updatedRaceNames).length > 0) {
        store.dispatch(updateRaceNames(updatedRaceNames));
      }
      // Make sure that the built race types match the ones in the backend
      // Prevents issues where after adding races, updating settings then trying to change race names
      // will result in more races being generated rather than the existing race having its name changed
      this.buildSelectedRaceTypesFromStore();
      this.setState({ updateStatus: "Settings have been updated!" });
    } catch (error) {
      if (error.response.status === 400) {
        this.setState({
          updateStatus: `There was an issue with updating the settings: ${error.response.data.error}`,
        });
      } else {
        log.error(error, error.response);
        this.setState({
          updateStatus:
            "There was an error with updating the settings. Please refresh the page to continue.",
        });
      }
    }
  }

  /**
   * Locks or Unlocks the current event, depending on its current status. If it's undefined, we treat it's current status as "unlocked".
   */
  public lockEvent(): void {
    const locked =
      this.props.event.lock !== undefined ? !this.props.event.lock : true;
    store.dispatch(saveLockEvent(this.props.event.id, locked));
  }

  /**
   * Create the rows for the table that shows which races are included
   * @memberof EventSetupPage
   * @returns {JSX.Element[]} The table rows with the races
   */
  createRows(): JSX.Element[] {
    const tableRows: JSX.Element[] = [];
    Object.entries(this.state.selectedRaceTypes).forEach((row, i) => {
      const [rowId, raceType] = row;
      const dropdownItems: JSX.Element[] = [];

      this.props.raceTypes.forEach((rowRaceType, j) => {
        if (rowRaceType.id !== SEEDING) {
          dropdownItems.push(
            <Dropdown.Item
              key={`dropdown${i}_item${j}`}
              onSelect={(): void => this.selectRaceType(rowId, rowRaceType)}
              className="event-setup__dropdown-items"
            >
              {rowRaceType.name}
            </Dropdown.Item>
          );
        }
      });

      // need to change that Dropdown.Toggle text with the selection
      let toggleText = "Choose a race type";
      let raceName = "";
      if (raceType) {
        toggleText = this.raceTypesHash[raceType.id];
        raceName = raceType.name;
      }

      tableRows.push(
        <tr key={rowId} id={rowId} className="event-setup__table-row">
          <td className="event-setup__table-td align-middle">Race Type:</td>
          <td className="align-middle">
            <Dropdown id={rowId + "main"} title="" as={ButtonGroup}>
              <Button className="event-setup__race-type-input">
                {" "}
                {toggleText}
              </Button>
              <Dropdown.Toggle
                id="toggle"
                className="event-setup__race-type-chevron"
                variant="secondary"
                disabled={
                  this.props.event.lock !== undefined && this.props.event.lock
                }
              ></Dropdown.Toggle>
              <Dropdown.Menu id={rowId + "menu"}>{dropdownItems}</Dropdown.Menu>
            </Dropdown>
          </td>
          <td className="align-middle">Race Name:</td>
          <td className="align-middle">
            <Form>
              <Form.Control
                className="event-setup__race-name-input"
                value={raceName}
                onChange={(value): void => {
                  this.changeRaceName(rowId, value.target.value);
                }}
                disabled={
                  this.props.event.lock !== undefined && this.props.event.lock
                }
              ></Form.Control>
            </Form>
          </td>
          <td className="align-middle">
            <RemoveRowButtonWithHider
              onRemoveRow={(): void => this.removeRow(rowId)}
              rowId={rowId}
            />
          </td>
        </tr>
      );
      tableRows.push(
        <tr key={rowId + "custom"} className="event-setup__table-row">
          {raceType && raceType.id === CUSTOM ? (
            <td colSpan={5} className="event-setup__warning-text">
              <b>Note:</b> this race type will track placings, not start or
              finish order.
            </td>
          ) : (
            <></>
          )}
        </tr>
      );
    });
    return tableRows;
  }

  /**
   * Create the table that shows which races are included
   * @memberof EventSetupPage
   * @returns {JSX.Element} The table with the races
   */
  createTable(): JSX.Element {
    return (
      <Table borderless responsive="sm" className="event-setup__table">
        <tbody className="event-setup__table-tbody">{this.createRows()}</tbody>
      </Table>
    );
  }

  render(): JSX.Element {
    const event = this.state.event;

    if (!event) {
      return <></>;
    }

    return (
      <div>
        <Row className="event-setup__header">
          <Col xs="auto" className="align-items-center">
            <span className="event-setup__title">{event.name}</span>
          </Col>
          <div className="ml-auto event-setup__event-buttons">
            <Col
              xs="auto"
              className="pl-0 pr-0 align-items-center event-setup__delete-event-button"
            >
              <Button
                className="btn-cycling"
                onClick={(): void => {
                  this.lockEvent();
                }}
              >
                {this.props.event.lock === undefined || !this.props.event.lock
                  ? "Lock"
                  : "Unlock"}
              </Button>
            </Col>
            <Col
              xs="auto"
              className="align-items-center event-setup__delete-event-button"
            >
              <DeleteEventButton event={event} />
            </Col>
          </div>
        </Row>
        <Row className="event-setup__fields">
          <Col xs="auto" className="event-setup__fields-text">
            <span>Event date:</span>
          </Col>
          <Col xs="auto">
            <DateTimePicker
              disabled={true}
              className="event-setup__datetime"
              name="startDate"
              value={event.startDatetime}
              editFormat="DD MMM, YYYY"
              format="DD MMM, YYYY"
              placeholder="31 Dec, 2021"
              time={false}
              onChange={(value): void => {
                this.setDate(value);
              }}
            />
          </Col>
        </Row>
        <Row className="event-setup__fields">
          <Row className="event-setup__datefields-row">
            <Col xs="auto" className="event-setup__fields-text">
              <span className="event-setup__starttime-text">Start time:</span>
            </Col>
            <Col xs="auto" className="event-setup__starttime-box">
              <DateTimePicker
                disabled={true}
                className="event-setup__datetime"
                name="startTime"
                value={event.startDatetime}
                editFormat="HH:mm"
                format="HH:mm"
                step={10}
                placeholder="14:15"
                date={false}
                onChange={(value): void => {
                  this.setTime(value, "startDatetime");
                }}
              />
            </Col>
          </Row>
          <Row className="event-setup__datefields-row">
            <Col xs="auto" className="event-setup__fields-text">
              <span className="event-setup__endtime-text">End time:</span>
            </Col>
            <Col xs="auto">
              <DateTimePicker
                disabled={true}
                className="event-setup__datetime"
                name="endTime"
                value={event.endDatetime}
                editFormat="HH:mm"
                format="HH:mm"
                step={10}
                placeholder="20:15"
                date={false}
                onChange={(value): void => {
                  this.setTime(value, "endDatetime");
                }}
              />
            </Col>
          </Row>
        </Row>
        <hr className="event-setup__divider" />
        <Row className="event-setup__fields">
          <Col>
            <p>Select the races that will take place during this event:</p>
          </Col>
        </Row>
        <Row className="event-setup__fields">
          <Col>
            <div className="event-setup__table-rows">{this.createTable()}</div>
          </Col>
        </Row>
        <Row className="event-setup__fields">
          <Col>
            <AddNewRowButtonWithHider
              onAddNewRow={(): void => {
                this.addNewRow();
              }}
            />
          </Col>
        </Row>
        <hr className="event-setup__divider" />
        <Row className="event-setup__fields">
          <Col>{this.getAddRidersButton()}</Col>
        </Row>
        <hr className="event-setup__divider" />
        <Row className="event-setup__fields">
          <Col xs="auto">
            <UpdateSettingsButtonWithHider
              onCheckSaveDetails={(): void => this.checkSaveDetails()}
            />
          </Col>
          <Col>
            <p>{this.state.updateStatus}</p>
          </Col>
        </Row>
      </div>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any, any>(mapStateToProps)(EventSetupPage);
