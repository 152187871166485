import { action } from "typesafe-actions";

import { Constants } from "./types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setRacesOpen = (racesOpenToggle: Array<boolean>): any =>
  action(Constants.SET_RACES_OPEN, { racesOpenToggle });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toggleRaceOpen = (raceToToggle: number): any =>
  action(Constants.TOGGLE_RACE_OPEN, { raceToToggle });
