import { captureException } from "@sentry/browser";
import log from "loglevel";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";

import WindowAlertModal from "components/Shared/WindowAlertModal";
import { Event } from "models/Event";
import { Rider } from "models/Rider";
import store from "store";
import { removeRider, updateWasSeedingChanged } from "store/events/actions";
import { IRace } from "store/race/types";
import { updateEventRaces } from "store/races/actions";
import { SEEDING } from "utils/constants";

interface IRemoveRiderProps {
  event: Event;
  rider: Rider;
}
interface IRemoveRiderState {
  event: Event;
  rider: Rider;
  showModal: boolean;
  modalHeader: string;
  modalBody: JSX.Element;
}
/**
 * Retrive data from store.
 * @param {*} state
 * @returns
 */
function mapStateToProps(state): { event: Event } {
  return {
    event: state.event.event,
  };
}

export class RemoveRider extends React.Component<
  IRemoveRiderProps,
  IRemoveRiderState
> {
  constructor(props: IRemoveRiderProps) {
    super(props);
    this.state = {
      event: props.event,
      rider: props.rider,
      showModal: false,
      modalHeader: "",
      modalBody: <span />,
    };
  }

  /**
   * Triggers deleting a rider while closing the modal
   */
  acceptDeleting(): void {
    this.setShowModal(false);
    this.removeRiderCall();
  }

  /**
   * Returns the modal body
   * @returns {Object} The JSX Element with the modal body
   */
  getModalBody(): JSX.Element {
    return (
      <span>
        Are you sure you want to remove{" "}
        <strong>{this.props.rider.getName()}</strong>?
        <br />
        <br />
        Performing this action will also remove the rider immediately from all
        the races where no results have been added.
      </span>
    );
  }

  /**
   * Triggers removing a rider in the backend, then in the frontend,
   * and marks changes in the seeding page.
   */
  public removeRiderCall(): void {
    store
      .dispatch(removeRider(this.state.event, this.state.rider.id))
      .then(res => {
        if (res.data.data === "Rider deleted") {
          this.removeRiderInFrontEnd();
          try {
            store.dispatch(
              updateWasSeedingChanged({
                wasRiderRemoved: true,
                wasRiderDragged: null,
              })
            );
          } catch (error) {
            captureException(
              `Error with updateWasSeedingChanged in RemoveRider: ${error}`
            );
          }
        }
      })
      .catch(e => {
        log.error(e);
        captureException(`Error with removeRiderCall in RemoveRider: ${e}`);
      });
  }

  /**
   * Remove the rider from the state in the frontend.
   * @public
   * @memberof Riders
   * @returns {?IRace[]} The races without the rider
   */
  public removeRiderInFrontEnd(): IRace[] | undefined {
    // Remove rider from seed, and from seeding-type of race
    // Could remove from all races, if desired, just
    // remove the statement && race.raceType === 1
    const races: IRace[] = Object.values(store.getState().races);
    if (races) {
      const newRaces = races.map(race => {
        if (race.riders && race.raceType === SEEDING) {
          const filteredRiders = race.riders.filter(
            rider => rider.id !== this.state.rider.id
          );
          const newRace = race;
          newRace.riders = filteredRiders;
          return newRace;
        }
        return race;
      });
      store.dispatch(updateEventRaces(newRaces));
      return newRaces;
    }
  }

  /**
   * Sets the state to whether it shows the modal or not
   * @param {boolean} show Show the modal or not
   */
  setShowModal(show: boolean): void {
    this.setState({ showModal: show });
  }

  public render(): JSX.Element {
    return (
      <>
        <Dropdown.Item
          id="remove-rider"
          eventKey="1"
          onClick={(): void => {
            this.setState({
              modalHeader: "Remove Rider?",
              modalBody: this.getModalBody(),
              showModal: true,
            });
          }}
        >
          Remove Rider
        </Dropdown.Item>
        <WindowAlertModal
          modalHeader={this.state.modalHeader}
          modalBody={this.state.modalBody}
          showModal={this.state.showModal}
          onHide={(): void => this.setShowModal(false)}
          onAccept={(): void => this.acceptDeleting()}
        />
      </>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any, any>(mapStateToProps)(RemoveRider);
