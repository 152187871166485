import { captureException } from "@sentry/browser";
import React from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import AppBar from "components/Shared/AppBar";
import EventBar from "components/Shared/EventBar";
import Footer from "components/Shared/Footer";
import LoadingFullScreen from "components/Shared/LoadingFullScreen";
import { IEvent } from "models/Event";
import { loadEvent } from "utils/utils";

interface ILayoutProps {
  eventId?: number;
  hideEventBar?: boolean;
  raceName?: string;
  raceType?: number;
  raceCategory?: string;
  event: IEvent;
}

interface ILayoutState {
  loadingEventDetails: boolean;
}

function mapStateToProps(
  state
): {
  event: IEvent;
} {
  return {
    event: state.event.event,
  };
}

export class Layout extends React.Component<ILayoutProps, ILayoutState> {
  constructor(props: ILayoutProps) {
    super(props);
    this.state = {
      loadingEventDetails: false,
    };
  }

  async componentDidMount(): Promise<void> {
    const eventId = this.props.eventId;
    // If event id is provided, check that our store contains event details
    if (eventId !== undefined) {
      if (
        Object.keys(this.props.event).length === 0 ||
        this.props.event.id !== eventId
      ) {
        // If store does not contain event details, put up a loading screen
        // and fetch event details
        try {
          this.setState({ loadingEventDetails: true });
          await loadEvent(eventId);
        } catch (err) {
          captureException(err);
        }
      }
    }
    this.setState({ loadingEventDetails: false });
  }

  /**
   * Display AppBar and NavBar or not depending on view: home or races
   *
   * @memberof Layout
   */
  showContent(): JSX.Element {
    if (this.props.eventId === undefined) {
      return (
        <div>
          <AppBar displayAppBar={false} />
          <div className="content">{this.props.children}</div>
        </div>
      );
    }

    let contentsWithSidebar = [
      <Col key="layout-eventbar" xs="12" sm="4" md="3" className="pr-0">
        <EventBar
          raceName={this.props.raceName}
          raceType={this.props.raceType}
          raceCategory={this.props.raceCategory}
        />
      </Col>,
      <Col key="layout-content" xs="12" sm="8" md="9">
        <div className="content">{this.props.children}</div>
      </Col>,
    ];
    if (
      this.props.hideEventBar !== undefined &&
      this.props.hideEventBar === true
    ) {
      contentsWithSidebar = [
        <Col key="layout-content" className="layout__children">
          <div className="content">{this.props.children}</div>
        </Col>,
      ];
    }

    return (
      <div>
        <AppBar displayAppBar />
        <Row className="mr-0 layout">{contentsWithSidebar}</Row>
      </div>
    );
  }

  render(): JSX.Element {
    if (this.state.loadingEventDetails) {
      return <LoadingFullScreen forceLoading={true} />;
    }

    return (
      <>
        <LoadingFullScreen />
        {this.showContent()}
        {/* // TODO: Footer is only visible half-way, half of it is hidden */}
        {/* Should be visible without having to scroll down */}
        <Footer />
      </>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any>(mapStateToProps)(Layout);
