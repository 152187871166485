import { captureException } from "@sentry/browser";
import React from "react";
import { Button, OverlayTrigger, Tooltip, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import store from "store";
import { getMindBodyEvents } from "store/events/actions";
import { setShowCreateNewEventDetails } from "store/views/actions";

import CreateNewEventDetails from "../CreateNewEventDetails";

interface ICreateNewEventProps {
  show: boolean;
}
interface ICreateNewEventState {
  eventDetails: {
    name: string;
    date: string;
    startDatetime: string;
    endDatetime: string;
  };
}

function mapStateToProps(state): { show: boolean } {
  return { show: state.views.showCreateNewEventDetails };
}

export class CreateNewEvent extends React.Component<
  ICreateNewEventProps,
  ICreateNewEventState
> {
  private tooltip = (
    <Tooltip id="tooltip-mindbody-warning">
      Warning: this event won&apos;t show up in MindBody.
    </Tooltip>
  );

  constructor(props: ICreateNewEventProps) {
    super(props);
    this.state = {
      eventDetails: {
        name: "Event",
        date: new Date().toJSON(),
        startDatetime: new Date().toJSON(),
        endDatetime: new Date().toJSON(),
      },
    };
  }

  /**
   * Load the root page to show all the availables events
   * @private
   * @memberof CreateNewEvent
   */
  private callMindBody(): void {
    try {
      store.dispatch(getMindBodyEvents()).finally(() => {
        window.location.reload();
      });
    } catch (err) {
      captureException(err);
    }
  }

  /**
   * Show details window if it is not shown. Do nothing otherwise.
   * @private
   * @memberof CreateNewEvent
   */
  private showCreateEvent(): void {
    if (!this.props.show) {
      try {
        store.dispatch(setShowCreateNewEventDetails(true));
      } catch (err) {
        captureException(err);
      }
    }
  }

  public render(): JSX.Element {
    return (
      <div className="event-tile">
        <div className="create-new-event__header">Add Event</div>
        <div>
          <Row className="create-new-event__row">
            <Col>
              <Button
                className="create-new-event__btn-outline-create-event mb"
                onClick={(): void => this.callMindBody()}
              >
                Get new events from MindBody
              </Button>
            </Col>
          </Row>
          <Row className="create-new-event__row">
            <Col>
              <CreateNewEventDetails eventDetails={this.state.eventDetails} />
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="bottom"
                overlay={this.tooltip}
              >
                <Button
                  className="create-new-event__btn-outline-create-event scratch"
                  onClick={(): void => this.showCreateEvent()}
                >
                  Create an empty event
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any>(mapStateToProps)(CreateNewEvent);
