import { faCheck, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Form,
  Button,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import { connect } from "react-redux";

import WindowAlertModal from "components/Shared/WindowAlertModal";
import { Rider } from "models/Rider";
import store from "store";
import { IRace } from "store/race/types";
import {
  sendRaceResults,
  getEventRaceRiders,
  lockRace,
} from "store/races/actions";
import { DIDNOTSTART, DIDNOTFINISH, KEIRIN, CUSTOM } from "utils/constants";
import history from "utils/history";
import {
  getNumberWithOrdinal,
  buildRaceTypesHash,
  convertRaceTypeToUrl,
  convertRaceNameForUrl,
} from "utils/utils";

interface IPosition {
  finishOrder: number;
  bib: string;
  rider: Rider | undefined;
}

interface IEnterResultsPageProps {
  raceName: string;
  raceType: number;
  raceCategory: string;
  eventId: string;
  races: IRace[];
  raceTypes: { id: number; name: string }[];
  raceSubcategory: string;
  isEventLocked: boolean;
}

interface IEnterResultsPageState {
  riders: Rider[];
  positions: IPosition[];
  currentBibs: string[];
  lapsCompleted: number[];
  curTile: number;
  isInvalid: boolean[];
  showModal: boolean;
  modalHeader: string;
  modalBody: JSX.Element;
  newRiderIdsWithDNSDNF: number[];
  raceNumLaps: number;
}

function mapStateToProps(
  state
): {
  races: IRace[];
  raceTypes: { id: number; name: string }[];
  isEventLocked: boolean;
} {
  return {
    races: Object.values(state.races),
    raceTypes: state.raceTypes.raceTypes,
    isEventLocked: state.event.event.lock,
  };
}

export class EnterResultsPage extends React.Component<
  IEnterResultsPageProps,
  IEnterResultsPageState
> {
  private thisRace: IRace | undefined = undefined;
  private raceTypesHash: { [key: number]: string } = {};

  constructor(props: IEnterResultsPageProps) {
    super(props);
    this.state = {
      riders: [],
      positions: [],
      currentBibs: [],
      lapsCompleted: [],
      curTile: -1,
      isInvalid: [],
      showModal: false,
      modalHeader: "",
      modalBody: <span />,
      newRiderIdsWithDNSDNF: [],
      raceNumLaps: 0,
    };
  }

  async componentDidMount(): Promise<void> {
    this.raceTypesHash = buildRaceTypesHash(this.props.raceTypes);
    const currentRace = this.props.races.find(race => {
      return (
        race.name === this.props.raceName &&
        race.category === this.props.raceCategory &&
        (race.subcategory
          ? race.subcategory.replace(" ", "") === this.props.raceSubcategory
          : true)
      );
    });
    this.thisRace = currentRace ? currentRace : undefined;
    const raceNumLaps =
      currentRace && currentRace.numLaps ? currentRace.numLaps : 0;

    // Load riders if they don't exist (first visit to enter results page)
    if (currentRace !== undefined && currentRace.riders === undefined) {
      await store.dispatch(
        getEventRaceRiders({
          races: this.props.races,
          raceName: this.props.raceName,
        })
      );
    }

    if (currentRace && currentRace.riders) {
      // build all of the arrarys needed in the state (should be the same length as the number of riders)
      const currentState = this.state.riders;
      const currentBibs = this.state.currentBibs;
      const lapsCompleted = this.state.lapsCompleted;
      const invalidList = this.state.isInvalid;
      currentRace.riders.forEach(rider => {
        currentState.push(rider);
        currentBibs.push("");
        lapsCompleted.push(raceNumLaps);
        invalidList.push(false);
      });
      this.setState({
        riders: currentState,
        currentBibs: currentBibs,
        lapsCompleted: lapsCompleted,
        isInvalid: invalidList,
        raceNumLaps: raceNumLaps,
      });
    }

    // setting up the positions array
    if (this.state.riders) {
      const positions = this.state.positions;
      for (let x = 0; x < this.state.riders.length; x++) {
        const newPosition = { finishOrder: x, bib: "", rider: undefined };
        positions.push(newPosition);
      }
      this.setState({ positions: positions });
    }
  }
  /**
   * Return string with the correct heat number for keirin races, nothing for
   * other type of races.
   * This is to be used on title of the page.
   *
   * @returns {string}
   * @memberof EnterResultsPage
   */
  private addKeirinHeatOnH3(): string {
    const heatNumber = this.thisRace ? this.thisRace.subcategory : "";
    if (this.props.raceType === KEIRIN) {
      return ": " + heatNumber;
    } else {
      return "";
    }
  }
  /**
   * Adds a lap up to a certain position
   * @param position  The position in the array to add a lap up to
   */
  addLap(position: number): void {
    const lapsCompleted = [...this.state.lapsCompleted];
    lapsCompleted[position] += 1;
    this.setState({ lapsCompleted });
  }
  /**
   * Cancels the results entering screen and goes back to the Scratch Race page
   */
  cancel(): void {
    history.push(
      `/event/${this.props.eventId}/${convertRaceNameForUrl(
        this.props.raceName
      )}/${convertRaceTypeToUrl(this.raceTypesHash, this.props.raceType)}/${
        this.props.raceCategory
      }/`
    );
  }
  /**
   * Changes the bib number in the state and picks the rider that matches that bib number
   * @param {string} bibNumber   The bib number of the rider in that position
   * @param {number} position    The position the rider came in
   */
  changeBibNumber(bibNumber: string, position: number): void {
    this.checkValidity(bibNumber, position);
    const thisRider = this.state.riders.find(rider => {
      if (rider.bib) {
        const riderBib: string = rider.bib.toString();
        return riderBib === bibNumber;
      } else {
        return false;
      }
    });
    const positions = this.state.positions;
    positions[position] = {
      finishOrder: position,
      bib: bibNumber,
      rider: thisRider,
    };
    const currentBibs = this.state.currentBibs;
    currentBibs[position] = bibNumber;
    this.setState({
      positions: positions,
      currentBibs: currentBibs,
    });
  }

  /**
   * Checks the validity of the newest bib number entered
   * @param {string} bibNumber   Newest bib number
   * @param {number} position    The position of that bib number
   */
  checkValidity(bibNumber: string, position: number): void {
    const clearedList = this.state.isInvalid;
    const bibNums: string[] = this.state.riders.map(rider => rider.bib || "");
    const matchingBib = bibNums.find(bib => {
      return bib.toString() === bibNumber;
    });
    const previousBib = bibNums.find(bib => {
      return bib.toString() === this.state.positions[position].bib;
    });
    let dups = 0; // used to track if there is more than 1 duplicate
    const dupPosition: number[] = []; // used to track the positons that may need to be changed

    // remove errors if error position has changed
    if (this.state.isInvalid[position]) {
      // if it was invalid because it was a not valid bib
      if (previousBib === undefined) {
        // if it's fixed, remove the error. Otherwise, keep it
        if (matchingBib !== undefined) {
          clearedList[position] = false;
        }
      } else {
        for (let z = 0; z < this.state.positions.length; z++) {
          // if the old bib number matches another bib, can clear that bib error
          if (
            this.state.positions[position].bib === this.state.positions[z].bib
          ) {
            dupPosition.push(z);
            dups += 1;
          }
        }
        // if there are more duplicates than just one, then those should stay in an error state
        if (dups > 2) {
          clearedList[position] = false;
        } else {
          dupPosition.forEach(p => {
            clearedList[p] = false;
          });
        }
      }
      this.setState({ isInvalid: clearedList });
    }
    // check for duplicates
    const duplicateList = this.state.isInvalid;
    for (let y = 0; y < this.state.positions.length; y++) {
      if (bibNumber === this.state.positions[y].bib && bibNumber !== "") {
        // make both the repeated bib and the current bib invalid
        duplicateList[y] = true;
        duplicateList[position] = true;
      }
    }
    this.setState({ isInvalid: duplicateList });
    // check if the bib matches a rider
    const newInvalidList = this.state.isInvalid;
    if (matchingBib === undefined) {
      newInvalidList[position] = true;
      this.setState({ isInvalid: newInvalidList });
    }
  }
  /**
   * Creates table for the bib numbers to be inputted into. It creates as many rows as there are riders.
   */
  createTable(): JSX.Element {
    if (this.state.riders.length > 0) {
      const tableRows: JSX.Element[] = [];
      for (let i = 0; i < this.state.riders.length; i++) {
        const positionRider =
          this.state.positions[i] && this.state.positions[i].rider
            ? this.state.positions[i].rider
            : undefined;
        // Disable lap buttons if their tile is not currently being hovered
        let lapBtnsDisabled = true;
        if (this.state.curTile === i) {
          lapBtnsDisabled = false;
        }

        tableRows.push(
          <tr
            onMouseEnter={(): void => this.setState({ curTile: i })}
            onMouseLeave={(): void => this.setState({ curTile: -1 })}
            key={"curTile" + i.toString()}
          >
            <td className="text-center">
              <b>
                {this.state.positions[i]
                  ? getNumberWithOrdinal(
                      this.state.positions[i].finishOrder + 1
                    )
                  : ""}
              </b>
            </td>
            <td>
              <Form.Control
                className="results__tb-bib-input buttonGroup__bib"
                value={this.getInputValue(i)}
                onChange={(value): void => {
                  this.changeBibNumber(value.target.value, i);
                }}
                isInvalid={this.state.isInvalid[i]}
              ></Form.Control>
            </td>
            {this.getRiderDisplayDetails(positionRider, i, lapBtnsDisabled)}
          </tr>
        );
      }

      return (
        <Table borderless striped hover className="results__table">
          <thead>
            <tr>
              <th className="results__th-finish-order text-center">
                Finish Order
              </th>
              <th className="results__th-bib">Bib #</th>
              <th className="results__th-name">Name</th>
              <th className="results__th-laps">
                {this.showsLapsOptionTitle()}
              </th>
              <th className="results__th-dns-dnf"></th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
      );
    }
    return <></>;
  }
  /**
   * Delete DNS/DNF for a rider once is set.
   *
   * @private
   * @param {number} riderId
   * @memberof EnterResultsPage
   */
  private deleteDNSDNF(riderId: number): void {
    const riders = this.state.riders;
    const newRiderIdsWithDNSDNF = this.state.newRiderIdsWithDNSDNF.filter(
      element => element !== riderId
    );
    const riderToAddPlacing = riders.find(element => element.id === riderId);
    if (riderToAddPlacing) {
      riderToAddPlacing.placing = undefined;
    }
    this.setState({
      riders: riders,
      newRiderIdsWithDNSDNF: newRiderIdsWithDNSDNF,
    });
  }
  /**
   * Formats the laps completed by the rider
   * Converts from the raw value to a +/- number
   * @param lapsCompleted Raw value of laps completed
   */
  getFormattedLapsCompleted(lapsCompleted: number): string {
    const numLaps = this.state.raceNumLaps;
    if (lapsCompleted > numLaps) {
      return `+${lapsCompleted - numLaps}`;
    } else if (lapsCompleted < numLaps) {
      return `-${numLaps - lapsCompleted}`;
    }
    return "-";
  }
  /**
   * Gets the input values to display based on the position.
   * @param position  The position in the input array (and race) that we're looking up
   */
  getInputValue(position: number): string {
    if (
      this.state.positions[position] &&
      this.state.positions[position].bib !== ""
    ) {
      return this.state.positions[position].bib;
    } else {
      return "";
    }
  }
  /**
   * Return a JSX.Element with instruction text depending on the raceType
   * @returns {JSX.Element}
   * @memberof EnterResultsPage
   */
  getInstructionText(): JSX.Element {
    if (this.props.raceType === CUSTOM) {
      return (
        <small className="results__info">
          Enter the bib #&apos;s of the riders in their{" "}
          <strong>final placing order</strong>. <br />
          Note: Lap Up/Down can be recorded but will not affect the inputted
          placing.
        </small>
      );
    }
    return (
      <small className="results__info">
        Enter the bib #&apos;s of the riders as they came across the finish
        line.
      </small>
    );
  }

  /**
   * Returns the modal body
   * @returns {Object} The JSX Element with the modal body
   */
  getModalBody(): JSX.Element {
    return (
      <span>
        Results cannot be submitted until all finish positions have been
        populated with unique bib numbers.
      </span>
    );
  }
  /**
   * Returns the dynamic part of the row for the table
   * Contains the rider name (or invalid text) and +/- laps buttons
   * @param curRider The current rider in the table
   * @param curIndex The current table index
   * @param isLapsBtnsDisabled Whether the table row is highlighted
   */
  getRiderDisplayDetails(
    curRider: Rider | undefined,
    curIndex: number,
    isLapsBtnsDisabled: boolean
  ): JSX.Element[] {
    // Returns an empty row if undefined, or state is invalid
    // If invalid, returns an empty row stating that it's invalid
    if (curRider === undefined || this.state.isInvalid[curIndex]) {
      return [
        <td key="1" className="results__tb-invalid">
          {this.state.isInvalid[curIndex] ? "Invalid or Duplicate Bib" : ""}
        </td>,
        <td key="2"></td>,
        <td key="3"></td>,
      ];
    }
    return [
      <td key="1">{curRider.firstName + " " + curRider.lastName}</td>,
      <td key="2">
        {this.showLapsOption(curIndex, isLapsBtnsDisabled, curRider)}
      </td>,
      <td key="3">{this.showDNSDNF(curRider)}</td>,
    ];
  }
  /**
   * Set DNS/DNF for rider with riderId.
   *
   * @param {number} riderId
   * @param {string} placing
   * @memberof EnterResultsPage
   */
  public handleDropDown(riderId: number, placing: string): void {
    const riders = this.state.riders;
    const newRiderIdsWithDNSDNF = this.state.newRiderIdsWithDNSDNF;
    const riderToAddPlacing = riders.find(element => element.id === riderId);
    if (riderToAddPlacing) {
      riderToAddPlacing.placing = placing;
      newRiderIdsWithDNSDNF.push(riderId);
    }
    this.setState({
      riders: riders,
      newRiderIdsWithDNSDNF: newRiderIdsWithDNSDNF,
    });
  }
  /**
   * Set finishOrder returning a number with finish order or DNF/DNS.
   * When we update result, backend return value as string, so we need to account for that.
   *
   * @private
   * @param {Rider} rider
   * @param {number} index
   * @returns {(number | string)}
   * @memberof EnterResultsPage
   */
  private setFinishOrder(rider: Rider, index: number): number | string {
    if (this.state.newRiderIdsWithDNSDNF.includes(rider.id) && rider.placing) {
      return rider.placing;
    } else {
      return this.state.positions[index].finishOrder + 1;
    }
  }
  /**
   * Set placing returning a string with placing or DNF/DNS
   *
   * @private
   * @param {Rider} rider
   * @param {number} index
   * @returns {string}
   * @memberof EnterResultsPage
   */
  private setPlacing(rider: Rider, index: number): string {
    if (this.state.newRiderIdsWithDNSDNF.includes(rider.id) && rider.placing) {
      return rider.placing;
    } else {
      return String(this.state.positions[index].finishOrder + 1);
    }
  }
  /**
   * Send a request to the API with the result as payload and then router the page to
   * the right address.
   *
   * @private
   * @param {*} results
   * @memberof EnterResultsPage
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private sendResultToAPI(race: IRace, results: any): void {
    store.dispatch(sendRaceResults(race, results)).then(() => {
      history.push(
        `/event/${this.props.eventId}/${convertRaceNameForUrl(
          this.props.raceName
        )}/${convertRaceTypeToUrl(this.raceTypesHash, this.props.raceType)}/${
          this.props.raceCategory
        }/`
      );
    });
  }
  /**
   * Sets the state to whether it shows the modal or not
   * @param {boolean} show Show the modal or not
   */
  setShowModal(show: boolean): void {
    this.setState({ showModal: show });
  }
  showBibNums(): JSX.Element[] {
    if (this.state.riders.length > 0) {
      const tiles: JSX.Element[] = [];
      const bibNums: string[] = this.state.riders
        .map(rider => rider.bib || "")
        .sort((a, b) => parseInt(a) - parseInt(b));
      const bibNumsNotInCurrentBibs: string[] = bibNums.filter(
        bib => !this.state.currentBibs.includes(bib)
      );
      const bibNumsInCurrentBibs: string[] = bibNums.filter(bib =>
        this.state.currentBibs.includes(bib)
      );

      bibNumsNotInCurrentBibs.forEach((bib, index) => {
        tiles.push(
          <Col key={"notInCurrent" + index.toString()}>
            <b>{bib}</b>
          </Col>
        );
      });
      bibNumsInCurrentBibs.forEach((bib, index) => {
        tiles.push(
          <Col
            className="results__bib-check"
            key={"bibCheck" + index.toString()}
          >
            {bib}
            <FontAwesomeIcon className="ml-2" icon={faCheck} />
          </Col>
        );
      });
      return tiles;
    }
    // Otherwise will raise a 'unique key warning'
    return [<div key="mynull"></div>];
  }
  /**
   * Return a JSX.Element with the Dropdown button for DNS/DNF
   *
   * @param {Rider} curRider
   * @returns {JSX.Element}
   * @memberof EnterResultsPage
   */
  private showDNSDNF(curRider: Rider): JSX.Element {
    const newRiderIdsWithDNSDNF = this.state.newRiderIdsWithDNSDNF;
    if (newRiderIdsWithDNSDNF.includes(curRider.id)) {
      return (
        <Button
          className="btn-cycling-secondary"
          onClick={(): void => this.deleteDNSDNF(curRider.id)}
        >
          <span className="results__delete-legend">Delete DNS/DNF</span>
        </Button>
      );
    }
    return (
      <Dropdown
        as={ButtonGroup}
        id="dns-dnf-dropdown"
        className="btn-cycling-secondary"
        alignRight
      >
        <Dropdown.Toggle
          id="dns-dnf-dropdown-toggle"
          className="btn-cycling-secondary"
          bsPrefix="toggle"
        >
          <span className="d-flex align-items-center">
            DNS/DNF <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            id={curRider.id.toString()}
            onSelect={(): void => this.handleDropDown(curRider.id, DIDNOTSTART)}
            className="btn-cycling-secondary"
          >
            Mark as DNS (Did Not Start)
          </Dropdown.Item>
          <Dropdown.Item
            id={curRider.id.toString()}
            onSelect={(): void =>
              this.handleDropDown(curRider.id, DIDNOTFINISH)
            }
            className="btn-cycling-secondary"
          >
            Mark as DNF (Did Not Finish)
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  /**
   * Return a JSX.Element object if it should show Laps option, empty object otherwise.
   *
   * @private
   * @param {number} curIndex
   * @param {boolean} isLapsBtnsDisabled
   * @returns {JSX.Element}
   * @memberof EnterResultsPage
   */
  private showLapsOption(
    curIndex: number,
    isLapsBtnsDisabled: boolean,
    curRider: Rider
  ): JSX.Element {
    if (this.state.newRiderIdsWithDNSDNF.includes(curRider.id)) {
      return <>{curRider.placing}</>;
    }
    if (this.props.raceType === KEIRIN) {
      return <></>;
    } else {
      return (
        <div className="d-flex flex-row">
          <p className="mr-auto">
            {this.getFormattedLapsCompleted(this.state.lapsCompleted[curIndex])}
          </p>
          <Button
            className="btn-cycling results__tb-btn results__tb-btn-sub"
            onClick={(): void => this.subLap(curIndex)}
            disabled={isLapsBtnsDisabled}
          >
            -
          </Button>
          <Button
            className="btn-cycling results__tb-btn"
            onClick={(): void => this.addLap(curIndex)}
            disabled={isLapsBtnsDisabled}
          >
            +
          </Button>
        </div>
      );
    }
  }
  /**
   * Return a string with the Laps option title for non Keirin races.
   * Empty string otherwise.
   *
   * @private
   * @returns {string}
   * @memberof EnterResultsPage
   */
  private showsLapsOptionTitle(): string {
    if (this.props.raceType === KEIRIN) {
      return "";
    } else {
      return "+ / - Laps";
    }
  }
  /**
   * Adds a lap down to a certain position
   * @param position  The position in the array to add a lap down to
   */
  subLap(position: number): void {
    const lapsCompleted = [...this.state.lapsCompleted];
    lapsCompleted[position] -= 1;
    this.setState({ lapsCompleted });
  }
  /**
   * Submits the results to the back end.
   */
  submitResults(): void {
    // only submit if all positions have a rider && bibs aren't repeated
    const allFilledIn = this.state.positions.every(positionObj => {
      return positionObj.rider !== undefined;
    });
    const currentBibsSet = new Set(this.state.currentBibs);
    if (
      allFilledIn &&
      this.thisRace &&
      currentBibsSet.size === this.state.positions.length
    ) {
      const results = {};
      for (let i = 0; i < this.state.positions.length; i++) {
        const currentPositionRider = this.state.positions[i].rider;
        if (currentPositionRider) {
          let completedLaps: number = this.state.raceNumLaps;
          if (this.state.lapsCompleted[i]) {
            completedLaps = this.state.lapsCompleted[i];
          }
          if (this.props.raceType === KEIRIN) {
            results[currentPositionRider.id] = {
              placing: this.setPlacing(currentPositionRider, i),
            };
          } else if (this.props.raceType === CUSTOM) {
            results[currentPositionRider.id] = {
              placing: this.setPlacing(currentPositionRider, i),
              numOfLaps: completedLaps,
            };
          } else {
            results[currentPositionRider.id] = {
              finishOrder: this.setFinishOrder(currentPositionRider, i),
              numOfLaps: completedLaps,
            };
          }
        }
      }
      this.sendResultToAPI(this.thisRace, results);
      store.dispatch(
        lockRace({
          status: true,
          eventID: this.props.eventId,
          raceName: this.props.raceName,
        })
      );
    } else {
      this.setState({
        showModal: true,
        modalHeader: "Oops!",
        modalBody: this.getModalBody(),
      });
      // want to highlight empty fields too
      const missingList = this.state.isInvalid;
      for (let k = 0; k < this.state.positions.length; k++) {
        if (this.state.positions[k].bib === "") {
          missingList[k] = true;
        }
      }
      this.setState({ isInvalid: missingList });
    }
  }

  render(): JSX.Element {
    const currentRaceType = this.props.raceTypes.find(type => {
      return type.id === this.props.raceType;
    });
    const raceTypeName = currentRaceType ? currentRaceType.name : "";

    return (
      <>
        <Container className="mt-2">
          <Row>
            <Col xs="auto" className="d-flex flex-column">
              <Button
                className="btn-cycling results__button"
                onClick={(): void => this.submitResults()}
                disabled={
                  this.props.isEventLocked !== undefined &&
                  this.props.isEventLocked
                }
              >
                Save Results
              </Button>
              <Button
                className="btn-cycling-secondary results__button"
                onClick={(): void => this.cancel()}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Col>
                <h3 className="results__race-name">
                  {this.props.raceName} ({raceTypeName}) Category{" "}
                  {this.props.raceCategory}
                  {this.addKeirinHeatOnH3()} Results
                </h3>
                <h3 className="results__race-name">
                  {this.getInstructionText()}
                </h3>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <div>{this.createTable()}</div>
                  </Col>
                  <Col sm="auto" className="results__bib-container">
                    <h3 className="results__bib-header">
                      Bib #&apos;s in starting lineup
                    </h3>
                    <br />
                    <div>{this.showBibNums()}</div>
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
        </Container>
        <WindowAlertModal
          modalHeader={this.state.modalHeader}
          modalBody={this.state.modalBody}
          showModal={this.state.showModal}
          onHide={(): void => this.setShowModal(false)}
        />
      </>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any, any>(mapStateToProps)(EnterResultsPage);
