import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { captureException } from "@sentry/browser";
import React from "react";
import {
  ButtonGroup,
  DropdownButton,
  Alert,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { connect } from "react-redux";

import ISeedUI from "models/ISeedUI";
import { Rider } from "models/Rider";

import RemoveRider from "../RemoveRider";

interface IButtonGroupProps {
  item: ISeedUI;
  eventId: number;
  latestNewRiders: number[];
  onBibChange: (riderId: number, bibNumber: string) => Promise<void>;
  invalidBibs: Set<string>;
  numInvalidBibs: number;
  isEventLocked: boolean;
}

interface IButtonGroupState {
  bibNumber: string;
  bibChanged: boolean;
  isInvalid: boolean;
}

function mapStateToProps(
  state
): {
  latestNewRiders: number[];
  invalidBibs: Set<string>;
  isEventLocked: boolean;
} {
  return {
    latestNewRiders: state.raceTypes.latestNewRiders,
    invalidBibs: state.views.event.invalidBibs,
    isEventLocked: state.event.event.lock,
  };
}

export class ButtonGroupClass extends React.Component<
  IButtonGroupProps,
  IButtonGroupState
> {
  constructor(props: IButtonGroupProps) {
    super(props);
    this.state = {
      bibNumber: "",
      bibChanged: false,
      isInvalid: false,
    };
  }

  public componentDidMount(): void {
    this.checkValidBib(this.getBib());
  }

  public componentDidUpdate(): void {
    this.checkValidBib(this.getBib());
  }

  public shouldComponentUpdate(
    nextProps: IButtonGroupProps,
    nextState: IButtonGroupState
  ): boolean {
    const currentBib = this.getBib();

    // Update component if new bibs are typed
    if (currentBib !== nextState.bibNumber) {
      return true;
    }

    // Re-render if valid/invalid state changes
    // If duplicate bib found here and is not already invalid, re-render
    if (nextProps.invalidBibs.has(currentBib) && !this.state.isInvalid) {
      return true;
    }
    // If duplicate bib not found here and is invalid, re-render
    else if (!nextProps.invalidBibs.has(currentBib) && this.state.isInvalid) {
      return true;
    }

    return false;
  }

  public displayName(): JSX.Element {
    const ridersName = (this.props.item.data as Rider).getName();
    const riderId = this.props.item.data.id;
    const isIn = this.props.latestNewRiders.includes(riderId);
    if (isIn) {
      return (
        <Row>
          <Col>
            <Alert className="buttonGroup__alert-info" variant="info">
              New Registration!
            </Alert>
            <span className="buttonGroup__riders-name">{ridersName}</span>
          </Col>
        </Row>
      );
    } else {
      return <span className="buttonGroup__riders-name">{ridersName}</span>;
    }
  }

  /**
   * Sets isValid state to true or false depending on provided bib
   * Checks that the bib is a valid string,
   * that it is not 0,
   * and that it isn't in the list of duplicate/invalid bibs
   * @param {string} strToTest
   */
  public checkValidBib(strToTest: string): void {
    if (
      /^\+?\d+$/.test(strToTest) === false ||
      strToTest === "0" ||
      this.props.invalidBibs.has(strToTest)
    ) {
      // Only updates the state if it is different than the prior state
      if (!this.state.isInvalid) {
        try {
          this.setState({ isInvalid: true });
        } catch (err) {
          captureException(err);
        }
      }
    } else {
      // Only updates the state if it is different than the prior state
      if (this.state.isInvalid) {
        try {
          this.setState({ isInvalid: false });
        } catch (err) {
          captureException(err);
        }
      }
    }
  }

  /**
   * Sets the bib number in the state of this component, and sends it to the Seeding component.
   * @param {string} value   The value inputted by the user
   */
  public setBib(value: string): void {
    try {
      this.setState({ bibNumber: value, bibChanged: true });
    } catch (err) {
      captureException(err);
    }
    this.checkValidBib(value);
    this.props.onBibChange((this.props.item.data as Rider).id, value);
  }

  /**
   * Gets the bib number from the item object itself to pre-populate the bib number in the input field if it exists. If the bib number
   * exists in the state of this component, takes the bib from there instead. Returns the bib number or an empty string.
   */
  public getBib(): string {
    if (this.state.bibNumber === "" && !this.state.bibChanged) {
      if (
        (this.props.item.data as Rider).bib === undefined ||
        (this.props.item.data as Rider).bib === null
      ) {
        return "";
      } else {
        const propsBib = (this.props.item.data as Rider).bib;
        if (propsBib !== undefined) {
          return propsBib.toString();
        }
      }
    }
    return this.state.bibNumber;
  }

  /**
   * Finds if an event is locked
   * @returns {boolean} Whether the event is locked or not
   */
  public findIfEventIsLocked(): boolean {
    if (this.props.isEventLocked !== undefined && this.props.isEventLocked) {
      return true;
    }
    return false;
  }

  public render(): JSX.Element {
    const rider = this.props.item.data;
    const ranking = (this.props.item.data as Rider).ranking;
    const stdev = (this.props.item.data as Rider).stdev;
    return (
      <ButtonGroup className="buttonGroup__button-group">
        <Col xs="1" className="buttonGroup__drag-handle">
          {this.findIfEventIsLocked() ? (
            <span />
          ) : (
            <span>
              <FontAwesomeIcon icon={faEllipsisV} />
            </span>
          )}
        </Col>
        <Col xs="2" sm="3" md="2" lg="2" xl="2" className="buttonGroup__bib">
          <Form.Control
            className="buttonGroup__bib-input buttonGroup__bib"
            onMouseDown={(e): void => e.stopPropagation()} // Allows the user to click on the input inside the draggable element
            value={this.getBib()}
            onChange={(value): void => {
              this.setBib(value.target.value);
            }}
            isInvalid={this.state.isInvalid}
            disabled={this.findIfEventIsLocked()}
          ></Form.Control>
          <Form.Control.Feedback
            className="buttonGroup__bib-feedback"
            type="invalid"
          >
            Invalid or Duplicate Bib
          </Form.Control.Feedback>
        </Col>
        <Col xs="4" sm="3" md="4" lg="4" className="buttonGroup__name">
          {this.displayName()}
        </Col>
        <Col xs="2" className="buttonGroup__ranking">
          {ranking.toFixed(1)}
        </Col>
        <Col xs="2" className="buttonGroup__stdev">
          {stdev.toFixed(2)}
        </Col>
        <Col xs="1" className="buttonGroup__dropdown">
          <DropdownButton
            variant="light"
            as={ButtonGroup}
            title=""
            id="bg-nested-dropdown"
            disabled={this.findIfEventIsLocked()}
          >
            <RemoveRider rider={rider} />
            {/* Add more dropdown options here */}
          </DropdownButton>
        </Col>
      </ButtonGroup>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any, any>(mapStateToProps)(ButtonGroupClass);
