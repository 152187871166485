import React from "react";
import { Route, Redirect } from "react-router-dom";

import AuthService from "auth/Auth";
import LoadingFullScreen from "components/Shared/LoadingFullScreen";

/**
 * Set router needed for auth
 * @param param0
 */
// Disable eslint here as it is a function supplied by Auth0
// eslint-disable-next-line
const SecuredRoute = ({ render: renderCallback, checkingSession, ...rest }) => {
  if (checkingSession) {
    return <LoadingFullScreen forceLoading={true} />;
  }
  return (
    <Route
      {...rest}
      render={(props): any => {
        return AuthService.isAuthenticated() === true ? (
          renderCallback(props)
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

export default SecuredRoute;
