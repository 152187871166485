import { captureException } from "@sentry/browser";
import axios from "axios";

import Auth from "../../auth/Auth";
import { Rider } from "../../models/Rider";
import { IRace, raceFromInterface, IRiderBibs } from "../../store/race/types";
import { convertFromAPI } from "../utils";

const API_URL = process.env.REACT_APP_API_URL;

export default {
  /**
   * Gets the raceTypes from the backend, the 20+
   * @returns {Object} The promise
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getRaceTypes: (): Promise<any | null> =>
    axios
      .get(`${API_URL}/v1/race_types/`, { ...Auth.getAPIHeader() })
      .then(response => response.data)
      .catch(err => {
        captureException(err);
      }),

  /**
   * Gets the race riders from given a set of race ids
   * @param {Object} raceIds The array of number ids
   * @returns {Object} The promise
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getRaceRiders: (raceIds: number[]): Promise<any | null> => {
    return axios
      .get(`${API_URL}/v1/race/riders/`, {
        params: { raceIds },
        ...Auth.getAPIHeader(),
      })
      .then(response => {
        const convertedResp: { [raceId: string]: Rider[] } = {};
        for (const [raceId, riders] of Object.entries(
          convertFromAPI(response.data)
        )) {
          const convertedRiders: Rider[] = [];
          (riders as never[]).forEach(rider => {
            convertedRiders.push(Rider.fromInterface(rider));
          });
          convertedResp[raceId] = convertedRiders;
        }
        return convertedResp;
      });
  },

  /**
   * It saves the race details, the number of riders that qualify for the final (not null if KEIRIN) and the number of laps
   * @param {number} raceId The race ID
   * @param {?number} numQualify The number of riders that qualify for the final
   * @param {?number} numLaps The number of laps in the race
   */
  saveRaceDetails(
    raceId: number,
    numQualify: number | null,
    numLaps: number | null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    return axios.post(
      `${API_URL}/v1/race/${raceId}/race_details/?numQualify=${numQualify}&numLaps=${numLaps}`,
      null,
      { ...Auth.getAPIHeader() }
    );
  },

  /**
   * Saves the race riders
   * @async
   * @param {Object} racesRiders
   * @returns {Object} The promise
   */
  saveRaceRiders: async (racesRiders: {
    [key: string]: number[];
  }): Promise<IRace[]> => {
    return axios
      .post(`${API_URL}/v1/race/riders/`, racesRiders || [], {
        ...Auth.getAPIHeader(),
      })
      .then(response => {
        return convertFromAPI(response.data).raceRiders.map(race =>
          raceFromInterface(race)
        ) as IRace[];
      });
  },

  /**
   * Send riders starting order for a race to the backend.
   * @public
   * @param {Object} race The IRace race
   */
  saveRaceRidersOrder: (race: IRace): void => {
    const raceRidersOrder = {};
    raceRidersOrder[race.id] = [];
    if (race.riders) {
      for (const rider of race.riders) {
        const riderId = rider.id;
        const riderOrder = Number(rider.order);
        raceRidersOrder[race.id].push({
          riderId: riderId,
          riderOrder: riderOrder,
        });
      }
    }
    axios
      .post(`${API_URL}/v1/race/riders_order/`, raceRidersOrder || {}, {
        ...Auth.getAPIHeader(),
      })
      .catch(err => {
        captureException(err);
      });
  },

  /**
   * Saves a new category
   * @async
   * @param {number} eventId The event ID
   * @param {number} raceType The raceType
   * @param {string} newCategoryName The new cwtegory name
   * @returns {Object} The promise
   */
  saveNewCategory: async (
    eventId: number,
    raceType: number,
    newCategoryName: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any | null> =>
    axios
      .post(
        `${API_URL}/v1/new_category/`,
        { eventId, raceType, newCategory: newCategoryName },
        { ...Auth.getAPIHeader() }
      )
      .catch(err => {
        captureException(err);
      }),

  /**
   * Deleted a category
   * @async
   * @param {number} raceId The race ID
   * @returns {Object} The promise
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteCategory: async (raceId: number): Promise<any | null> =>
    axios
      .delete(`${API_URL}/v1/delete_category/${raceId}/`, {
        ...Auth.getAPIHeader(),
      })
      .catch(err => {
        captureException(err);
      }),

  /**
   * Propagates the seeding changes to all unlock races
   * @async
   * @param {number} eventId The event ID
   * @param {Object} riders The IRiderBibs Object
   * @returns {Object} The promise
   */
  updateStartingLineups: async (
    eventId: number,
    riders: IRiderBibs
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any | null> =>
    axios
      .post(
        `${API_URL}/v1/event/${eventId}/race_types/populate/`,
        { riders },
        { ...Auth.getAPIHeader() }
      )
      .catch(err => {
        captureException(err);
      }),

  /**
   * It locks a race
   * @async
   * @param {boolean} status The new status: locked/unlocked
   * @param {number} eventId The event ID
   * @param {string} raceName The race name
   * @returns {Object} The promise
   */
  lockRace: async (
    status: boolean,
    eventId: number,
    raceName: string
  ): Promise<string | null> => {
    return axios
      .post(
        `${API_URL}/v1/race/${eventId}/lock/`,
        { raceName: raceName, locked: status },
        { ...Auth.getAPIHeader() }
      )
      .then(response => {
        return response.data.locked_datetime;
      });
  },

  /**
   * It creates a new heat
   * @async
   * @param {Object} params The parameters
   * @param {number} params.eventId The event ID
   * @param {string} params.raceName The race name
   * @param {number} params.raceType The race type
   * @param {string} params.category The race category
   * @returns {Object} The promise
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createNewHeat: async (params: any): Promise<any | null> => {
    const { eventId, raceName, raceType, category } = params;
    return axios.post(
      `${API_URL}/v1/new_heat/`,
      { eventId, raceName, raceType, category },
      { ...Auth.getAPIHeader() }
    );
  },

  /**
   * It gets the MindBody riders
   * @async
   * @param {number} eventId The event ID
   * @returns {Object} The promise
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMindBodyRiders: (eventId: number): Promise<any | null> =>
    axios
      .put(`${API_URL}/v1/event/${eventId}/mindbody/get_riders/`, [], {
        ...Auth.getAPIHeader(),
      })
      .then(response => response)
      .catch(err => {
        captureException(err);
      }),
};
