import React from "react";

import EventSetupPage from "components/EventSetup/EventSetupPage";
import Layout from "components/Shared/Layout";

interface IEventSetupViewProps {
  eventId: string;
}

export class EventSetupView extends React.Component<IEventSetupViewProps> {
  constructor(props: IEventSetupViewProps) {
    super(props);
    this.state = {
      eventId: parseInt(props.eventId),
    };
  }

  render(): JSX.Element | null {
    return (
      <Layout eventId={parseInt(this.props.eventId)}>
        <div className="event container ml-2">
          <EventSetupPage eventId={parseInt(this.props.eventId)} />
        </div>
      </Layout>
    );
  }
}

export default EventSetupView;
