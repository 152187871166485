import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";

import { Event } from "models/Event";
import store from "store";
import { createNewCategory } from "store/races/actions";
import { SEEDING } from "utils/constants";

interface IAddCategoryProps {
  event: Event;
}
interface IAddCategoryState {
  event: Event;
  raceType: number;
}
/**
 * Retrive data from store.
 * @param {*} state
 * @returns
 */
function mapStateToProps(state): IAddCategoryProps {
  return { event: state.event.event };
}

export class AddCategory extends React.Component<
  IAddCategoryProps,
  IAddCategoryState
> {
  constructor(props: IAddCategoryProps) {
    super(props);
    this.state = {
      event: props.event,
      raceType: SEEDING,
    };
  }

  /**
   * Update addCategory to display add rider box .
   * @public
   * @memberof addCategory
   */
  public addNewCategory(): void {
    store.dispatch(
      createNewCategory(this.state.event.id, this.state.raceType, "")
    );
  }

  public render(): JSX.Element {
    return (
      <Row>
        <span
          className="add-category"
          onClick={(): void => this.addNewCategory()}
        >
          <span className="add-category__text">+ Add Category</span> &mdash;
          &mdash; &mdash; &mdash; &mdash; &mdash; &mdash; &mdash; &mdash;
          &mdash; &mdash; &mdash; &mdash; &mdash; &mdash; &mdash; &mdash;
          &mdash; &mdash; &mdash; &mdash; &mdash; &mdash; &mdash; &mdash;
          &mdash; &mdash; &mdash; &mdash; &mdash; &mdash; &mdash; &mdash;
          &mdash; &mdash; &mdash; &mdash; &mdash; &mdash; &mdash; &mdash;
          &mdash; &mdash; &mdash; &mdash; &mdash; &mdash; &mdash; &mdash;
          &mdash; &mdash; &mdash; &mdash;
        </span>
      </Row>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any, any>(mapStateToProps)(AddCategory);
