import { init } from "@sentry/browser";
import moment from "moment";
import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import momentLocalizer from "react-widgets-moment";
import "bootstrap/dist/css/bootstrap.css";
import "react-widgets/dist/css/react-widgets.css";

import App from "./App";
import "./styles/index.scss";
import unregister from "./registerServiceWorker";

if ("REACT_APP_JOB_ID" in process.env) {
  init({
    dsn: "https://4ba11928189d4c6cae39172fe9bc1d89@sentry.io/1728737",
    release: `${process.env.REACT_APP_JOB_ID}_${process.env.REACT_APP_COMMIT_ID}`,
  });
  // eslint-disable-next-line no-console
  console.log(
    `Production build detected. Sentry initialized with ID: ${process.env.REACT_APP_JOB_ID}_${process.env.REACT_APP_COMMIT_ID}`
  );
}
moment.locale("en");
momentLocalizer();

// Application entry point
const rootEl = document.getElementById("root") as HTMLElement;
render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  rootEl
);

unregister();
