import { captureException } from "@sentry/browser";
import React from "react";
import { Button } from "react-bootstrap";

import store from "store";
import { IRace } from "store/race/types";
import history from "utils/history";
import { convertRaceNameForUrl } from "utils/utils";

import api from "../../../services/races/racesAPI";

interface IAddResultsButtonProps {
  race: IRace;
  disabled: boolean;
  eventId?: number;
}

export default class AddResultsButton extends React.Component<
  IAddResultsButtonProps
> {
  /**
   * It routes to the enter-results page corrensponding to that raceName, category, and if needed, subcategory
   */
  public addResults(): void {
    let stringRaceType;
    try {
      this.saveRidersOrder(this.props.race);
      stringRaceType = store.getState().raceTypes.raceTypes.find(raceType => {
        return this.props.race.raceType === raceType.id;
      });
    } catch (err) {
      captureException(err);
    }

    if (!this.props.race.subcategory) {
      // For non-heated races
      history.push(
        `/event/${this.props.eventId}/${convertRaceNameForUrl(
          this.props.race.name
        )}/${stringRaceType.name.replace(/\s/g, "-")}/${
          this.props.race.category
        }/enter-results/`
      );
    } else {
      // For heated races
      history.push(
        `/event/${this.props.eventId}/${convertRaceNameForUrl(
          this.props.race.name
        )}/${stringRaceType.name.replace(/\s/g, "-")}/${
          this.props.race.category
        }/enter-results/${this.props.race.subcategory.replace(" ", "")}`
      );
    }
  }

  /**
   * If race has riders with starting order set, like Keirin has, it sends these starting orders to
   * backend to be stored in db to use it after adding result.
   *
   * @private
   * @param {IRace} race
   * @memberof AddResultsButton
   */
  private saveRidersOrder(race: IRace): void {
    if (race.riders && race.riders[0].order) {
      api.saveRaceRidersOrder(race);
    }
  }

  public render(): JSX.Element {
    return (
      <Button
        className="btn-cycling"
        disabled={this.props.disabled}
        onClick={(): void => this.addResults()}
      >
        {this.props.race.riders &&
        this.props.race.riders[0] &&
        this.props.race.riders[0].placing
          ? "Update Race Results"
          : "Add Race Results"}
      </Button>
    );
  }
}
