import React from "react";
import { Button } from "react-bootstrap";

import WindowAlertModal from "components/Shared/WindowAlertModal";
import { Event } from "models/Event";
import store from "store";
import { deleteEvent } from "store/events/actions";
import history from "utils/history";

interface IDeleteEventButtonProps {
  event: Event;
}

interface IDeleteEventButtonState {
  event: Event;
  showModal: boolean;
  modalHeader: string;
  modalBody: JSX.Element;
}

export class DeleteEventButton extends React.Component<
  IDeleteEventButtonProps,
  IDeleteEventButtonState
> {
  constructor(props: IDeleteEventButtonProps) {
    super(props);
    this.state = {
      event: props.event,
      showModal: false,
      modalHeader: "",
      modalBody: <span />,
    };
  }

  /**
   * Sets the state to whether it shows the modal or not
   * @param {boolean} show Show the modal or not
   */
  setShowModal(show: boolean): void {
    this.setState({ showModal: show });
  }

  /**
   * Dispatches to store to delete event and route to homepage
   */
  public DeleteEvent(): void {
    store.dispatch(deleteEvent(this.props.event)).then(() => {
      history.push("/");
    });
  }

  /**
   * Triggers deleting an event while closing the modal
   */
  acceptDeleting(): void {
    this.setShowModal(false);
    this.DeleteEvent();
  }

  /**
   * Returns the modal body
   * @returns {Object} The JSX Element with the modal body
   */
  getModalBody(): JSX.Element {
    return (
      <span>
        Are you sure you want to delete this event{" "}
        <strong>{this.props.event.name}</strong>?
      </span>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        <Button
          className="btn-cycling-secondary"
          onClick={(): void => {
            this.setState({
              modalHeader: "Warning",
              modalBody: this.getModalBody(),
              showModal: true,
            });
          }}
          disabled={
            this.props.event.lock !== undefined && this.props.event.lock
          }
        >
          Delete Event
        </Button>
        <WindowAlertModal
          modalHeader={this.state.modalHeader}
          modalBody={this.state.modalBody}
          showModal={this.state.showModal}
          onHide={(): void => this.setShowModal(false)}
          onAccept={(): void => this.acceptDeleting()}
        />
      </>
    );
  }
}
export default DeleteEventButton;
