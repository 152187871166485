import React from "react";
import { Button } from "react-bootstrap";

interface IUpdateSettingsButtonProps {
  onCheckSaveDetails: () => void;
}

export class UpdateSettingsButton extends React.Component<
  IUpdateSettingsButtonProps
> {
  public render(): JSX.Element {
    return (
      <Button
        id="btn-cycling"
        className="btn-cycling"
        onClick={(): void => this.props.onCheckSaveDetails()}
      >
        Update Settings
      </Button>
    );
  }
}
export default UpdateSettingsButton;
