import React from "react";

import Layout from "../../components/Shared/Layout";

export class About extends React.Component {
  render(): JSX.Element {
    return (
      <Layout>
        <div className="about container">
          <div id="events-list" className="about__info">
            Font license info
            <br />
            ## Font Awesome
            <br />
            Copyright (C) 2016 by Dave Gandy
            <br />
            Author: Dave Gandy
            <br />
            License: SIL ()
            <br />
            Homepage: http://fortawesome.github.com/Font-Awesome/
            <br />
          </div>
        </div>
      </Layout>
    );
  }
}

export default About;
