import { captureException } from "@sentry/browser";
import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import CsvDownloader from "react-csv-downloader";
import { connect } from "react-redux";

import { Event } from "models/Event";
import { Rider } from "models/Rider";
import store from "store";
import { IRace, IRaceType } from "store/race/types";
import { updateCreateCSVState } from "store/views/actions";
import { formatDate, sortRaceSubcategories } from "utils/utils";

interface ICreateCSVSelectionProps {
  event: Event;
  show: boolean;
  races: IRace[];
  raceTypes: IRaceType[];
  raceType: number;
  raceName: string;
}

interface ICreateCSVSelectionState {
  sortOrder: number;
}

function mapStateToProps(
  state
): {
  event: Event;
  raceTypes: IRaceType[];
  races: IRace[];
} {
  return {
    event: state.event.event,
    raceTypes: state.raceTypes.raceTypes,
    races: state.races,
  };
}

// Sort order constants
const ORDER_BIB = 1;
const ORDER_ALPHABETICAL = 2;
const ORDER_CURRENT_RANK = 3;

interface ICsv {
  lastname: string;
  firstname: string;
  bib: string;
  finishorder: string;
  placing: string;
  lapping: string;
}

export class CreateCSVSelection extends React.Component<
  ICreateCSVSelectionProps,
  ICreateCSVSelectionState
> {
  constructor(props: ICreateCSVSelectionProps) {
    super(props);
    this.state = {
      sortOrder: ORDER_BIB,
    };
  }

  public hideWindow(): void {
    try {
      store.dispatch(updateCreateCSVState(false));
    } catch (err) {
      captureException(err);
    }
  }

  public sortRiders(a: Rider, b: Rider): number {
    const bibA = a.bib;
    const bibB = b.bib;
    if (
      this.state.sortOrder === ORDER_BIB &&
      bibA !== undefined &&
      bibB !== undefined
    ) {
      return parseInt(bibA) > parseInt(bibB) ? 1 : -1;
    } else if (this.state.sortOrder === ORDER_CURRENT_RANK) {
      return b.ranking > a.ranking ? 1 : -1;
    }
    // Default to sorting alphabetically
    return `${a.lastName} ${a.firstName}` > `${b.lastName} ${b.firstName}`
      ? 1
      : -1;
  }

  /**
   * Set data for export in CSV format.
   * @public
   * @memberof CreateCSVSelection
   */

  generateCsv(): ICsv[] {
    const csv: ICsv[] = [];
    let formerRace = "None";
    const currentRaceName = this.props.raceName;
    // filter seeds by race type
    const { races } = this.props;
    if (races) {
      Object.values(races)
        .filter((race: IRace) => race.name === this.props.raceName)
        .sort((a, b) => sortRaceSubcategories(a, b))
        .forEach((race: IRace) => {
          if (formerRace !== race.category && currentRaceName) {
            let numLaps = "";
            if (race.numLaps !== null) {
              numLaps = race.numLaps.toString();
            }
            csv.push({
              lastname: currentRaceName,
              firstname: race.category,
              bib: "",
              finishorder: formatDate(race.raceDatetime, "MMM DD, YYYY"),
              placing: "",
              lapping: "",
            });
            csv.push({
              lastname: "X Laps",
              firstname: numLaps,
              bib: "BIB",
              finishorder: "Finish Order",
              placing: "Placing",
              lapping: "Laps +/-",
            });
            formerRace = race.category;
          }
          if (race.subcategory !== undefined && race.subcategory !== null) {
            csv.push({
              lastname: race.subcategory,
              firstname: "",
              bib: "",
              finishorder: "",
              placing: "",
              lapping: "",
            });
          }
          (race.riders || [])
            .sort((a, b) => this.sortRiders(a, b))
            .forEach(rider => {
              const bib = rider.bib !== undefined ? rider.bib : "";
              const placing = rider.placing !== undefined ? rider.placing : "";

              let finishOrder = "";
              if (typeof rider.finishOrder === "string") {
                finishOrder = rider.finishOrder;
              } else if (typeof rider.finishOrder === "number") {
                finishOrder = rider.finishOrder.toString();
              } else if (rider.finishOrder === undefined) {
                finishOrder = placing;
              }

              csv.push({
                lastname: rider.lastName,
                firstname: rider.firstName,
                bib: bib,
                finishorder: finishOrder,
                placing: placing,
                lapping: "",
              });
            });
          csv.push({
            lastname: "",
            firstname: "",
            bib: "",
            finishorder: "",
            placing: "",
            lapping: "",
          });
          csv.push({
            lastname: "",
            firstname: "",
            bib: "",
            finishorder: "",
            placing: "",
            lapping: "",
          });
        });
    }
    return csv;
  }

  public getColumns(): { id: string; displayName: string }[] {
    return [
      {
        id: "lastname",
        displayName: "",
      },
      {
        id: "firstname",
        displayName: "",
      },
      {
        id: "bib",
        displayName: "",
      },
      {
        id: "finishorder",
        displayName: "",
      },
      {
        id: "placing",
        displayName: "",
      },
      {
        id: "lapping",
        displayName: "",
      },
    ];
  }

  render(): JSX.Element {
    const columns = this.getColumns();
    let filenameVar = "";
    let currentRaceType;
    if (this.props.raceType) {
      currentRaceType = this.props.raceTypes.find(
        raceTypeFound => raceTypeFound.id === this.props.raceType
      );
    }

    if (this.props.raceName && currentRaceType) {
      filenameVar = `${this.props.event.name}_${this.props.raceName}_${currentRaceType.name}`;
    }

    return (
      <Modal show={this.props.show} className="csv-selection" size="lg">
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter-csv-selection">
            Select the order to display riders within their category or heat.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Check
              type="radio"
              name="export-radios"
              id="radio-bib"
              label="By Bib Number"
              onChange={(): void => {
                this.setState({ sortOrder: ORDER_BIB });
              }}
              defaultChecked
            />
            <Form.Check
              type="radio"
              name="export-radios"
              id="radio-alphabetical"
              label="Alphabetical"
              onChange={(): void => {
                this.setState({ sortOrder: ORDER_ALPHABETICAL });
              }}
            />
            <Form.Check
              type="radio"
              name="export-radios"
              id="radio-rank"
              label="By Current System Ranking"
              onChange={(): void => {
                this.setState({ sortOrder: ORDER_CURRENT_RANK });
              }}
            />
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <CsvDownloader
            filename={filenameVar}
            separator=","
            columns={columns}
            datas={this.generateCsv()}
          >
            <Button
              className="btn-cycling"
              onClick={(): void => this.hideWindow()}
            >
              Download
            </Button>
          </CsvDownloader>
          <Button
            className="btn-cycling-secondary"
            onClick={(): void => this.hideWindow()}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any>(mapStateToProps)(CreateCSVSelection);
