import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";

import SeedingPage from "components/Seeding/SeedingPage";

import Layout from "../../components/Shared/Layout";
import { IRaceType } from "../../store/race/types";

interface ISeedingViewsProps {
  raceTypes: IRaceType[];
  showAddRider: boolean;
  eventId: string;
}

function mapStateToProps(
  state
): {
  raceTypes: IRaceType[];
  showAddRider: boolean;
} {
  return {
    raceTypes: state.raceTypes.raceTypes,
    showAddRider: state.views.event.showAddRider,
  };
}
export class SeedingView extends React.Component<ISeedingViewsProps> {
  render(): JSX.Element {
    return (
      <Layout eventId={parseInt(this.props.eventId)}>
        <Container className="event ml-2">
          <SeedingPage eventId={parseInt(this.props.eventId)} />
        </Container>
      </Layout>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any>(mapStateToProps)(SeedingView);
