import { plainToClass } from "class-transformer";

export interface IRider {
  id: number;
  firstName: string;
  lastName: string;
  ranking: number;
  stdev: number;
  order?: string;
}

export class Rider implements IRider {
  public id = 0;
  public firstName = "";
  public lastName = "";
  public ranking = 25;
  public stdev = 3;
  public bib?: string; // is a string because it's entered as a string and then stored in the database as a string
  public order?: string;
  public finishOrder?: number | string;
  public placing?: string; // is a string because it's stored in the database as a string
  public numOfLaps?: number;

  constructor(
    id: number,
    firstName: string,
    lastName: string,
    ranking: number,
    stdev: number,
    bib?: string,
    order?: string,
    finishOrder?: number | string,
    placing?: string,
    numOfLaps?: number
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.ranking = ranking;
    this.stdev = stdev;
    this.bib = bib;
    this.order = order;
    this.finishOrder = finishOrder;
    this.placing = placing;
    this.numOfLaps = numOfLaps;
  }

  /**
   * Return the Rider's full name
   */
  public getName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  /**
   * Create a Rider object from a IRider interface
   */
  public static fromInterface = (rider: IRider): Rider => {
    const newRider = plainToClass(Rider, rider);
    return newRider;
  };
}
