import log from "loglevel";
import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { connect } from "react-redux";

import WindowAlertModal from "components/Shared/WindowAlertModal";
import { Event } from "models/Event";
import { Rider } from "models/Rider";
import { convertFromAPI } from "services/utils";
import store from "store";
import { getMindBodyRiders } from "store/events/actions";
import { setLatestNewRiders } from "store/race/actions";
import { addNewRider } from "store/races/actions";
import { setEnableUpdateDetailsButton } from "store/views/actions";

interface IGetMindBodyRidersProps {
  event: Event;
  enableUpdateDetailsButton: boolean;
  view: string;
}
interface IGetMindBodyRidersState {
  event: Event;
  loading: boolean;
  showModal: boolean;
  modalHeader: string;
  modalBody: JSX.Element;
}

interface IApiRiderObject {
  bib: string;
  certified: null;
  firstName: string;
  firstNameEng: string;
  id: number;
  lastName: string;
  lastNameEng: string;
  madisonPartner: number | null;
  ranking: number;
  riderLicense: string | null;
  stdev: number;
  team: string | null;
}

function mapStateToProps(
  state
): { event: Event; enableUpdateDetailsButton: boolean } {
  return {
    event: state.event.event,
    enableUpdateDetailsButton: state.views.enableUpdateDetailsButton,
  };
}

export class GetMindBodyRiders extends React.Component<
  IGetMindBodyRidersProps,
  IGetMindBodyRidersState
> {
  constructor(props: IGetMindBodyRidersProps) {
    super(props);
    this.state = {
      event: props.event,
      loading: false,
      showModal: false,
      modalHeader: "",
      modalBody: <span />,
    };
  }

  /**
   * Enable/disable UpdateDetails Button.
   * @public
   * @memberof GetMindBodyRiders
   */
  public enableUpdateDetailsButtonFunction(outcome): void {
    if (outcome === true) {
      try {
        store.dispatch(setEnableUpdateDetailsButton(true));
      } catch (err) {
        log.error(err);
      }
    } else {
      try {
        store.dispatch(setEnableUpdateDetailsButton(false));
      } catch (err) {
        log.error(err);
      }
    }
  }

  /**
   * Sets the state to whether it shows the modal or not
   * @param {boolean} show Show the modal or not
   */
  setShowModal(show: boolean): void {
    this.setState({ showModal: show });
  }

  /**
   * Returns the modal body
   * @param {string} string The string to return wrapped in <span>
   * @returns {Object} The JSX Element with the modal body
   */
  getModalBody(string: string): JSX.Element {
    return <span>{string}</span>;
  }

  public updateLatestNewRiders(ridersArray): void {
    try {
      store.dispatch(setLatestNewRiders(ridersArray));
    } catch (err) {
      log.error(err);
    }
  }

  /**
   * Call Backend to retrieve riders
   * @private
   * @memberof GetMindBodyRiders
   */
  private callMindBody(): void {
    try {
      this.setState({ loading: true });
    } catch (err) {
      log.error(err);
    }

    store
      .dispatch(getMindBodyRiders(this.state.event))
      .then(res => convertFromAPI(res.data))
      .then(res => {
        if (res.state === "New") {
          const newRiders: number[] = [];
          let text = "";
          // eslint-disable-next-line prefer-const
          for (let [category, riderHolder] of Object.entries(res.newRiders)) {
            // Uppercase the first letter of category
            category = category.charAt(0).toUpperCase() + category.slice(1);

            // Convert the rider object from the API into a proper Rider object
            // and add it into the store
            for (const [riderId, riderObj] of Object.entries(
              riderHolder as never
            )) {
              newRiders.push(parseInt(riderId));
              store.dispatch(
                addNewRider(
                  Rider.fromInterface(riderObj as IApiRiderObject),
                  category
                )
              );
            }
          }
          if (newRiders.length === 1) {
            text = `${newRiders.length} Rider Loaded`;
          } else if (newRiders.length > 1) {
            text = `${newRiders.length} Riders Loaded`;
          }
          this.setState({
            modalHeader: "Success!",
            modalBody: this.getModalBody(text),
            showModal: true,
          });
          this.enableUpdateDetailsButtonFunction(true);
          this.updateLatestNewRiders(newRiders);
        } else if (res.state === "Old") {
          this.setState({
            modalHeader: "",
            modalBody: this.getModalBody("No new Riders found."),
            showModal: true,
          });
          this.enableUpdateDetailsButtonFunction(true);
          this.updateLatestNewRiders([]);
        } else if (res.state === "None") {
          this.setState({
            modalHeader: "Oops!",
            modalBody: this.getModalBody(
              "There are no riders registered for this event."
            ),
            showModal: true,
          });
          this.enableUpdateDetailsButtonFunction(false);
          this.updateLatestNewRiders([]);
        } else {
          this.setState({
            modalHeader: "Error!",
            modalBody: this.getModalBody(`${res.data}`),
            showModal: true,
          });
          this.enableUpdateDetailsButtonFunction(false);
          this.updateLatestNewRiders([]);
        }
        this.setState({ loading: false });
      })
      .catch(error => {
        log.error(error);
      });
  }

  public showSpinner(): JSX.Element {
    return (
      <span>
        &nbsp;{" "}
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      </span>
    );
  }

  public render(): JSX.Element {
    return (
      <div>
        {this.props.view === "eventDetails" ? (
          <Button
            className="btn-cycling rider-details"
            onClick={(): void => this.callMindBody()}
          >
            Get Riders from MindBody
            {this.state.loading === true ? this.showSpinner() : <span />}
          </Button>
        ) : (
          <Button
            className="btn-cycling updating"
            onClick={(): void => this.callMindBody()}
          >
            Update Riders
            {this.state.loading === true ? this.showSpinner() : <span />}
          </Button>
        )}
        <WindowAlertModal
          modalHeader={this.state.modalHeader}
          modalBody={this.state.modalBody}
          showModal={this.state.showModal}
          onHide={(): void => this.setShowModal(false)}
        />
      </div>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any, any>(mapStateToProps)(GetMindBodyRiders);
