import { ActionType } from "typesafe-actions";

import { IRace } from "store/race/types";

// Allow wildcard import for all actions
// eslint-disable-next-line import/no-namespace
import * as actions from "./actions";

export type Actions = ActionType<typeof actions>;

export interface IState {
  event: {
    showAddRider: boolean;
    currentAddResultsRace: IRace | null;
    showCreateCSV: boolean;
    showEditRace: boolean;
    showLockRace: boolean;
    invalidBibs: Set<string>;
  };
  showCreateNewEventDetails: boolean;
  enableUpdateDetailsButton: boolean;
}

export enum Constants {
  UPDATE_ADDRIDER_STATE = "UPDATE_ADDRIDER_STATE",
  UPDATE_ADDRESULTS_STATE = "UPDATE_ADDRESULTS_STATE",
  UPDATE_CREATECSV_STATE = "UPDATE_CREATECSV_STATE",
  SET_SHOWCREATENEWEVENTDETAILS = "SET_SHOWCREATENEWEVENTDETAILS",
  SET_ENABLEUPDATEDETAILSBUTTON = "SET_ENABLEUPDATEDETAILSBUTTON",
  UPDATE_RACE_DETAILS_STATE = "UPDATE_RACE_DETAILS_STATE",
  UPDATE_LOCK_RACE_STATE = "UPDATE_LOCK_RACE_STATE",
  UPDATE_INVALID_BIBS = "UPDATE_INVALID_BIBS",
}

export interface UpdateAddriderStateAction {
  type: typeof Constants.UPDATE_ADDRIDER_STATE;
  payload: { newState: boolean };
}

export interface UpdateAddResultsStateAction {
  type: typeof Constants.UPDATE_ADDRESULTS_STATE;
  payload: { newState: IRace | null };
}

export interface UpdateCreateCSVStateAction {
  type: typeof Constants.UPDATE_CREATECSV_STATE;
  payload: { newState: boolean };
}

export interface SetShowcreateneweventdetailsAction {
  type: typeof Constants.SET_SHOWCREATENEWEVENTDETAILS;
  payload: { showCreateNewEventDetails: boolean };
}

export interface SetEnableupdatedetailsbuttonAction {
  type: typeof Constants.SET_ENABLEUPDATEDETAILSBUTTON;
  payload: { enableUpdateDetailsButton: boolean };
}

export interface UpdateRaceDetailsStateAction {
  type: typeof Constants.UPDATE_RACE_DETAILS_STATE;
  payload: { enableUpdateRaceDetailsButton: boolean };
}

export interface UpdateLockRaceStateAction {
  type: typeof Constants.UPDATE_LOCK_RACE_STATE;
  payload: { newState: boolean };
}

export interface UpdateInvalidBibsAction {
  type: typeof Constants.UPDATE_INVALID_BIBS;
  payload: { invalidBibs: Set<string> };
}
