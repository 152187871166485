import { captureException } from "@sentry/browser";
import axios from "axios";

import { RaceAPIMap } from "services/races/racesAPIUtil";

import Auth from "../../auth/Auth";
import {
  IEvent,
  Event,
  IEventsAPISaveEventRaceTypes,
} from "../../models/Event";
import { IRace } from "../../store/race/types";
import { convertFromAPI } from "../utils";

import { EventAPIMap } from "./eventsAPIUtils";

const API_URL = process.env.REACT_APP_API_URL;

export default {
  getEvents: (
    page: number,
    pageSize: number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<{ events: any; pages: any }> =>
    axios
      .get(`${API_URL}/v1/event/`, {
        ...Auth.getAPIHeader(),
        params: {
          page,
          pageSize: pageSize,
        },
      })
      .then(response => ({
        events: convertFromAPI(
          response.data.events,
          EventAPIMap
        ).map((event: Event) => Event.fromInterface(event)),
        pages: response.data.pages,
      }))
      .catch(error => {
        captureException(error);
        return { events: [], pages: 1 };
      }),

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMindBodyEvents: (): Promise<any> =>
    axios
      .put(`${API_URL}/v1/event/mindbody/get_events/`, [], {
        ...Auth.getAPIHeader(),
      })
      .then(response => {
        return convertFromAPI(response.data, EventAPIMap).map(event =>
          Event.fromInterface(event)
        );
      }),

  getEvent: (id): Promise<Event> =>
    axios
      .get(`${API_URL}/v1/event/${id}/`, { ...Auth.getAPIHeader() })
      .then(response => {
        const event = convertFromAPI(response.data, EventAPIMap);
        return Event.fromInterface(event);
      }),

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lockEvent: async (eventId: number, lock: boolean): Promise<any> => {
    axios
      .post(
        `${API_URL}/v1/event/lock/`,
        { eventId, lock },
        { ...Auth.getAPIHeader() }
      )
      .then(response => {
        return response;
      });
  },

  saveEventRaceTypes: async (
    eventId: string,
    eventRaceTypes: IEventsAPISaveEventRaceTypes
  ): Promise<IRace[]> =>
    axios
      .post(
        `${API_URL}/v1/event/${eventId}/race_types/`,
        eventRaceTypes || [],
        {
          ...Auth.getAPIHeader(),
        }
      )
      .then(response => convertFromAPI(response.data.newRaces, RaceAPIMap)),

  getEventRaces: (eventId: number): Promise<IRace[]> =>
    axios
      .get(`${API_URL}/v1/event/${eventId}/race/`, { ...Auth.getAPIHeader() })
      .then(response => convertFromAPI(response.data, RaceAPIMap)),

  sendExcelFile: (event: IEvent, excelFile: FormData): Promise<void> =>
    axios.post(`${API_URL}/v1/event/${event.id}/rider/`, excelFile || [], {
      ...Auth.getAPIHeader(),
    }),

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendRaceResults: (race: IRace, results: any): Promise<any> =>
    axios.post(`${API_URL}/v1/race/${race.id}/post_results/`, results || [], {
      ...Auth.getAPIHeader(),
    }),

  // TODO: Remove, used in the defunct UploadResults
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendRaceFile: (race: IRace, excelFile: FormData): Promise<any> =>
    axios.post(
      `${API_URL}/v1/race/${race.id}/post_results_xlsx/`,
      excelFile || [],
      {
        ...Auth.getAPIHeader(),
      }
    ),

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeRider(event: IEvent, riderId: number): Promise<any> {
    return axios.delete(
      `${API_URL}/v1/event/${event.id}/remove_rider/${riderId}/`,
      { ...Auth.getAPIHeader() }
    );
  },

  // Send a empty POST request to create a new event.
  createNewEvent: (details: {
    name: string;
    startDatetime: Date;
    endDatetime: Date;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): Promise<any> => {
    return axios.post(`${API_URL}/v1/event/create/`, details, {
      ...Auth.getAPIHeader(),
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteEvent: (event: Event): Promise<any> => {
    return axios.delete(`${API_URL}/v1/event/delete/${event.id}/`, {
      ...Auth.getAPIHeader(),
    });
  },
};
