import React from "react";

import { mapRaceTypeStringToInt, convertRaceNameFromUrl } from "utils/utils";

import EnterResultsPage from "../../components/RaceResults/EnterResultsPage";
import Layout from "../../components/Shared/Layout";

interface IEnterResultsViewProps {
  raceName: string;
  raceType: string;
  raceCategory: string;
  eventId: string;
  raceSubcategory?: string;
}

export default class EnterResultsView extends React.Component<
  IEnterResultsViewProps
> {
  render(): JSX.Element {
    return (
      <Layout eventId={parseInt(this.props.eventId)} hideEventBar={true}>
        <div className="event container ml-2">
          <EnterResultsPage
            raceType={mapRaceTypeStringToInt(this.props.raceType)}
            raceName={convertRaceNameFromUrl(this.props.raceName)}
            raceCategory={this.props.raceCategory}
            eventId={this.props.eventId}
            raceSubcategory={this.props.raceSubcategory}
          ></EnterResultsPage>
        </div>
      </Layout>
    );
  }
}
