import { captureException } from "@sentry/browser";
import React from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import WindowAlertModal from "components/Shared/WindowAlertModal";
import store from "store";
import { createNewEvent, getEvents } from "store/events/actions";
import { setShowCreateNewEventDetails } from "store/views/actions";

interface ICreateNewEventDetailsProps {
  show: boolean;
  eventDetails: {
    name: string;
    date: string;
    startDatetime: string;
    endDatetime: string;
  };
}
interface ICreateNewEventDetailsState {
  name: string;
  date: string;
  startDatetime: string;
  endDatetime: string;
  showModal: boolean;
  modalHeader: string;
  modalBody: JSX.Element;
}
function mapStateToProps(state): { show: boolean } {
  return { show: state.views.showCreateNewEventDetails };
}

export class CreateNewEventDetails extends React.Component<
  ICreateNewEventDetailsProps,
  ICreateNewEventDetailsState
> {
  constructor(props: ICreateNewEventDetailsProps) {
    super(props);
    this.state = {
      name: this.props.eventDetails.name,
      date: this.props.eventDetails.date,
      startDatetime: this.props.eventDetails.startDatetime,
      endDatetime: this.props.eventDetails.endDatetime,
      showModal: false,
      modalHeader: "",
      modalBody: <span />,
    };
  }

  /**
   * Sets the state to whether it shows the modal or not
   * @param {boolean} show Show the modal or not
   */
  setShowModal(show: boolean): void {
    this.setState({ showModal: show });
  }

  /**
   * Returns the modal body
   *  @param {string} string The string to return wrapped in <span>
   * @returns {Object} The JSX Element with the modal body
   */
  getModalBody(string: string): JSX.Element {
    return <span>{string}</span>;
  }

  /**
   * Create a new empty event by dispatching createNewEvent action. Pop-up a window alert otherwise.
   * @private
   * @memberof CreateNewEvent
   */
  private createNewEvent(details: {
    name: string;
    startDatetime: Date;
    endDatetime: Date;
  }): void {
    store
      .dispatch(createNewEvent(details))
      .then(() => {
        this.hideDetailsWindow();
        this.loadEvents();
      })
      .catch(e => {
        this.setState({
          modalHeader: "Error!",
          modalBody: this.getModalBody(`${e}`),
          showModal: true,
        });
      });
  }

  /**
   * Load the root page to show all the availables events
   * @private
   * @memberof CreateNewEvent
   */
  private loadEvents(): void {
    try {
      store.dispatch(getEvents(1, 8));
      window.location.reload();
    } catch (err) {
      captureException(err);
    }
  }

  /**
   * Hide details windows. It doesn't save the details be default.
   * @private
   * @memberof CreateNewEventDetails
   */
  private hideDetailsWindow(): void {
    try {
      store.dispatch(setShowCreateNewEventDetails(false));
    } catch (err) {
      captureException(err);
    }
  }

  /**
   * Cancel default action.
   * @private
   * @param {*} event
   * @memberof CreateNewEventDetails
   */
  private handleSubmit(event): void {
    event.preventDefault();
  }

  /**
   * Save details of the new event to be created.
   * @private
   * @memberof CreateNewEventDetails
   */
  private saveDetails(): void {
    const details = {
      name: this.state.name,
      startDatetime: new Date(this.state.startDatetime),
      endDatetime: new Date(this.state.endDatetime),
    };
    this.createNewEvent(details);
  }

  /**
   * Set a event date
   * @param value
   */
  private setDate(value): void {
    this.setState({ date: value.target.value });
  }

  /**
   * Set a event time
   * @param value
   * @param field
   */
  private setTime(value, field): void {
    const datetime = `${this.state.date}T${value.target.value}:00.000Z`;
    if (field === "startDatetime") {
      this.setState({ startDatetime: datetime });
    } else {
      this.setState({ endDatetime: datetime });
    }
  }

  /**
   * Set state name on every change at input.
   * @private
   * @param {*} value
   * @memberof CreateNewEventDetails
   */
  private setName(value): void {
    this.setState({ name: value.target.value });
  }

  public render(): JSX.Element {
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={(): void => this.hideDetailsWindow()}
          size="lg"
          centered
          className="event-details"
        >
          <Modal.Header closeButton>
            <Modal.Title> Add Event </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form onSubmit={this.handleSubmit}>
              <Row className="event-details__fields">
                <Col xs="4" sm="4" md="4" lg="2">
                  <label>Event name:</label>
                </Col>
                <Col xs="8" sm="8" md="8" lg="10">
                  <input
                    className="event-details__datetime"
                    type="text"
                    onChange={(value): void => {
                      this.setName(value);
                    }}
                  />
                </Col>
              </Row>
              <Row className="event-details__fields">
                <Col xs="4" sm="4" md="4" lg="2">
                  <label>Date:</label>
                </Col>
                <Col xs="8" sm="8" md="8" lg="10">
                  <input
                    className="event-details__datetime"
                    type="date"
                    onChange={(value): void => {
                      this.setDate(value);
                    }}
                  />
                </Col>
              </Row>
              <Row className="event-details__fields">
                <Col xs="4" sm="4" md="4" lg="2">
                  <label>Start Time:</label>
                </Col>
                <Col xs="8" sm="8" md="8" lg="10">
                  <input
                    className="event-details__datetime"
                    type="time"
                    onChange={(value): void => {
                      this.setTime(value, "startDatetime");
                    }}
                  />
                </Col>
              </Row>
              <Row className="event-details__fields">
                <Col xs="4" sm="4" md="4" lg="2">
                  <label>End Time:</label>
                </Col>
                <Col xs="8" sm="8" md="8" lg="10">
                  <input
                    className="event-details__datetime"
                    type="time"
                    onChange={(value): void => {
                      this.setTime(value, "endDatetime");
                    }}
                  />
                </Col>
              </Row>
            </form>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="btn-cycling"
              onClick={(): void => this.saveDetails()}
            >
              Add
            </Button>
            <Button
              className="btn-cycling-secondary"
              onClick={(): void => this.hideDetailsWindow()}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <WindowAlertModal
          modalHeader={this.state.modalHeader}
          modalBody={this.state.modalBody}
          showModal={this.state.showModal}
          onHide={(): void => this.setShowModal(false)}
        />
      </>
    );
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any>(mapStateToProps)(CreateNewEventDetails);
