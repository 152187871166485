import { WebAuth } from "auth0-js";
import log from "loglevel";

import history from "../utils/history";

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || "";
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE || "";
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || "";

let AUTH0_REDIRECT_URI = process.env.REACT_APP_AUTH0_REDIRECT_URI || "";
let APP_URL = process.env.REACT_APP_URL || "";

// If deploying to a live environment, update app urls to that of the environment
if (
  process.env.REACT_APP_AWS_BRANCH &&
  process.env.REACT_APP_AWS_BRANCH.toLowerCase() !== "master"
) {
  const REACT_APP_AWS_BRANCH = (
    process.env.REACT_APP_AWS_BRANCH || ""
  ).toLowerCase();
  AUTH0_REDIRECT_URI = `https://${REACT_APP_AWS_BRANCH}.${process.env.REACT_APP_BASE_URL}/callback`;
  APP_URL = `https://${REACT_APP_AWS_BRANCH}.${process.env.REACT_APP_BASE_URL}`;
}

/**
 * Set what's needed to auth
 * @class AuthService
 */
class AuthService {
  auth0 = new WebAuth({
    domain: AUTH0_DOMAIN,
    audience: AUTH0_AUDIENCE,
    clientID: AUTH0_CLIENT_ID,
    redirectUri: AUTH0_REDIRECT_URI,
    responseType: "token id_token",
    scope: "openid email profile",
  });

  idToken: string | null = null;

  accessToken: string | null = null;

  expiresAt: number | null = null;

  login(): void {
    this.auth0.authorize();
  }

  handleAuthentication(): void {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        history.replace("/login");
        log.warn(err);
        if (err.errorDescription) {
          alert(`Error: ${err.error}\nDescription: ${err.errorDescription}`);
        } else {
          alert(`Error: ${err.error}. Check the console for further details.`);
        }
      }
    });
  }

  getAccessToken(): string {
    return this.accessToken || "";
  }

  getIdToken(): string {
    return this.idToken || "";
  }

  setSession(authResult: any): void {
    // Set isLoggedIn flag in localStorage
    localStorage.setItem("isLoggedIn", "true");

    // Set the time that the access token will expire at
    const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;
  }

  logout(): void {
    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem("isLoggedIn");

    this.auth0.logout({
      returnTo: APP_URL,
      clientID: AUTH0_CLIENT_ID,
    });

    // navigate to the home route
    history.replace("/home");
  }

  isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    if (!this.expiresAt) return false;
    return new Date().getTime() < this.expiresAt;
  }

  silentAuth(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) return reject(err);
        this.setSession(authResult);
        resolve();
      });
    });
  }

  getAPIHeader(): { headers: { Authorization: string } } {
    return { headers: { Authorization: `Bearer ${this.getAccessToken()}` } };
  }
}

export default new AuthService();
