import { ActionType } from "typesafe-actions";

import { IEvent } from "../../models/Event";

// Allow wildcard import for all actions
// eslint-disable-next-line import/no-namespace
import * as actions from "./actions";

export type Actions = ActionType<typeof actions>;

export enum Constants {
  LOCK_EVENT = "LOCK_EVENT",
  RECEIVE_EVENTS = "RECEIVE_EVENTS",
  UPDATE_EVENT = "UPDATE_EVENT",
  UPDATE_WAS_SEEDING_CHANGED = "UPDATE_WAS_SEEDING_CHANGED",
}

export interface LockEventAction {
  type: typeof Constants.LOCK_EVENT;
  payload: { eventId: number; lock: boolean };
}

export interface ReceiveEventsAction {
  type: typeof Constants.RECEIVE_EVENTS;
  payload: { events: IEvent[] };
}

export interface UpdateEventAction {
  type: typeof Constants.UPDATE_EVENT;
  payload: { event: IEvent };
}

export interface UpdateWasSeedingChangedAction {
  type: typeof Constants.UPDATE_WAS_SEEDING_CHANGED;
  payload: {
    wasRiderRemoved: boolean | null;
    wasRiderDragged: boolean | null;
  };
}
