import { faCircle, faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronUp,
  faChevronDown,
  faFlagCheckered,
  faUsers,
  faFileAlt,
  faCog,
  faCheckCircle as faSolidCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { captureException } from "@sentry/browser";
import log from "loglevel";
import React from "react";
import { Collapse, ListGroup } from "react-bootstrap";
import { connect } from "react-redux";

import { Event } from "models/Event";
import { EventBar as EventBarClass } from "models/EventBar";
import store from "store";
import { setRacesOpen, toggleRaceOpen } from "store/eventbar/actions";
import { IRace, IRaceType } from "store/race/types";
import { getEventRaceRiders } from "store/races/actions";
import { SEEDING } from "utils/constants";
import history from "utils/history";
import {
  buildRaceTypesHash,
  convertRaceTypeToUrl,
  convertRaceNameForUrl,
} from "utils/utils";

import EventBarItem from "../EventBarItem";

interface IEventBarProps {
  raceName: string;
  raceType: number;
  raceCategory: string;
  event: Event;
  eventBar: EventBarClass;
  raceTypes: IRaceType[];
  eventRaces: IRace[];
}

interface IEventBarState {
  eventBar: EventBarClass;
  racesOpenToggle: boolean[];
  eventRaceNames: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventRacesNameType: any;
}

export const EventBarItems = [
  { name: "Event Setup", id: "setup" },
  { name: "Seeding", id: "seeding" },
  { name: "Categories and Heats", id: "categories" },
  { name: "Rider Attendance", id: "signin" },
  { name: "Race Results", id: "results" },
];

function mapStateToProps(
  state
): {
  event: Event;
  eventBar: EventBarClass;
  raceTypes: IRaceType[];
  eventRaces: IRace[];
} {
  return {
    event: state.event.event,
    eventBar: state.eventBar,
    raceTypes: state.raceTypes.raceTypes,
    // Races with type Seeding are removed here
    eventRaces: (Object.values(state.races) as IRace[])
      .filter(race => {
        return race.raceType !== SEEDING;
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1)),
  };
}

export class EventBar extends React.Component<IEventBarProps, IEventBarState> {
  private raceTypesHash: { [key: number]: string } = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private allRaceCategories: any[] = [];

  constructor(props: IEventBarProps) {
    super(props);
    this.state = {
      eventBar: props.eventBar,
      racesOpenToggle: props.eventBar.racesOpenToggle,
      eventRaceNames: Array.from(
        new Set<string>(
          Object.values(props.eventRaces).map((race: IRace) => race.name)
        ).values()
      ),
      eventRacesNameType: props.eventRaces.reduce((acc, race) => {
        acc[race.name] = {
          raceType: race.raceType,
        };
        return acc;
      }, {}),
    };
    this.raceTypesHash = buildRaceTypesHash(this.props.raceTypes);
  }

  async componentDidMount(): Promise<void> {
    // Builds categories for each race
    this.buildRaceCategories();
    // Builds which race should have the categories expanded
    this.buildRacesOpenToggle();
    await this.loadRacesAndRiders(this.props.eventRaces);
  }

  componentDidUpdate(prevProps): void {
    // Build race types if they were only retrieved from the backend after
    // the component was mounted
    if (Object.keys(this.raceTypesHash).length === 0) {
      this.raceTypesHash = buildRaceTypesHash(this.props.raceTypes);
    }

    // Refresh page state if races open differs from previous state
    if (
      this.props.eventBar.racesOpenToggle.length !==
      prevProps.eventBar.racesOpenToggle.length
    ) {
      this.setState({ racesOpenToggle: this.props.eventBar.racesOpenToggle });
      this.buildRaceCategories();
    }

    if (this.props.eventRaces !== undefined) {
      if (this.props.eventRaces !== prevProps.eventRaces) {
        this.setState({ racesOpenToggle: this.props.eventBar.racesOpenToggle });
        this.setState({
          ...this.state,
          eventRaceNames: Array.from(
            new Set<string>(
              this.props.eventRaces.map((race: IRace) => race.name)
            ).values()
          ),
          eventRacesNameType: this.props.eventRaces.reduce((acc, race) => {
            acc[race.name] = {
              raceType: race.raceType,
            };
            return acc;
          }, {}),
        });
        this.buildRaceCategories();
      }
    }
    if (this.props.raceName !== prevProps.raceName) {
      this.buildRacesOpenToggle();
    }
  }

  /**
   * Builds an array of arrays holding info of all the categories per race
   */
  public buildRaceCategories(): void {
    this.allRaceCategories = [];
    let raceNames: string[] = [];
    const raceCategories = new Map(); // map each raceName to its set of categories
    if (this.props.eventRaces) {
      const eventRaces = this.props.eventRaces;
      for (let i = 0; i < eventRaces.length; i++) {
        const { name } = eventRaces[i];
        const { category } = eventRaces[i];
        if (!raceCategories.has(name)) {
          // new raceName seen, create a new set
          raceCategories.set(name, new Set());
        }
        // add category for this raceName
        raceCategories.get(name).add(category);
      }
    }
    raceNames = Array.from(raceCategories.keys());
    // loop over all raceName sets
    raceNames.forEach(raceName => {
      const categories = Array.from(raceCategories.get(raceName)).sort();
      this.allRaceCategories.push(categories);
    });
  }

  /**
   * Builds array of boolean values holding info on whether the categories dropdowns are expanded or not
   */
  public buildRacesOpenToggle(): void {
    const racesOpenToggle: boolean[] = [];
    // For a new openToggle
    if (
      this.state.racesOpenToggle &&
      this.state.racesOpenToggle.length === 0 &&
      this.state.eventRaceNames &&
      this.state.eventRaceNames.length
    ) {
      for (let i = 0; i < this.state.eventRaceNames.length; i++) {
        // If the name matches the one in the store keep it open
        if (this.state.eventRaceNames[i] === this.props.raceName) {
          racesOpenToggle.push(true);
        } else {
          racesOpenToggle.push(false);
        }
      }
      store.dispatch(setRacesOpen(racesOpenToggle));
      this.setState({ racesOpenToggle });
    } else if (
      this.state.racesOpenToggle &&
      this.state.racesOpenToggle.length > 0 &&
      this.state.eventRaceNames &&
      this.state.eventRaceNames.length
    ) {
      // Re-build an existing OpenToggle
      for (let i = 0; i < this.state.eventRaceNames.length; i++) {
        // If the name matches the one in the store keep it open
        if (this.state.eventRaceNames[i] === this.props.raceName) {
          racesOpenToggle.push(true);
        } else {
          racesOpenToggle.push(this.state.racesOpenToggle[i]);
        }
      }
      store.dispatch(setRacesOpen(racesOpenToggle));
      this.setState({ racesOpenToggle });
    }
  }

  /**
   * Builds the ListGroup.Item for all the races in the event
   * @returns {Object} The JSX.Element of the races in a given event
   */
  public getEventRaces(): JSX.Element[] {
    if (this.state.eventRaceNames && this.props.eventRaces) {
      const currentRaceNames = this.state.eventRaceNames;
      if (currentRaceNames.length > 0) {
        this.buildRaceCategories();
        const listItems = currentRaceNames.map((currentRaceName, index) => (
          <div key={`Toggle${index}`} className="entry">
            <div className="entry__top-row">
              <span
                className={
                  window.location.pathname ===
                  this.getRaceOverviewUrl(currentRaceName)
                    ? " entry__label entry__label-highlighted"
                    : "entry__label"
                }
                onClick={(): void => {
                  history.push(this.getRaceOverviewUrl(currentRaceName));
                }}
              >
                <span className="icon-">
                  <FontAwesomeIcon icon={faFlagCheckered} />
                </span>
                {currentRaceName} &nbsp;
                {this.getIconStatus(currentRaceName)}
              </span>
              <ListGroup.Item
                className="entry__chevron entry__label "
                onClick={(): void => this.toggleExpandRace(index)}
                aria-controls="example-collapse-text"
                aria-expanded={this.props.eventBar.racesOpenToggle[index]}
              >
                <span>
                  {this.props.eventBar.racesOpenToggle[index] ? (
                    <span className="event-bar__end">
                      <FontAwesomeIcon icon={faChevronUp} />
                    </span>
                  ) : (
                    <span className="event-bar__end">
                      <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                  )}
                </span>
              </ListGroup.Item>
            </div>
            <div>
              <Collapse in={this.props.eventBar.racesOpenToggle[index]}>
                <div id="example-collapse-text">
                  {this.getRaceCategories(
                    index,
                    currentRaceName,
                    this.state.eventRacesNameType[currentRaceName].raceType
                  )}
                </div>
              </Collapse>
            </div>
          </div>
        ));
        return listItems;
      }
    }
    return [];
  }

  /**
   * Get the JSX.Element of the icon status, full checkmark if all the results have been entered
   * empty checkmark is only some results have been entered, empty circle if none.
   * @param currentRaceName
   * @returns {JSX.Element} The span with the fontawesome icon
   */
  public getIconStatus(currentRaceName: string): JSX.Element {
    const raceResultsSubmitted: number[] = [];

    const races = (Object.values(this.props.eventRaces) as IRace[]).filter(
      race => {
        return race.name === currentRaceName;
      }
    );
    races.forEach(race =>
      raceResultsSubmitted.push(this.getResultsSubmitted(race))
    );
    const numberOfRacesWithResults = raceResultsSubmitted.reduce(
      (a, b) => a + b,
      0
    );

    if (numberOfRacesWithResults === 0) {
      return (
        <span className="icon-">
          <FontAwesomeIcon icon={faCircle} />
        </span>
      );
    } else if (numberOfRacesWithResults === raceResultsSubmitted.length) {
      return (
        <span className="icon-">
          <FontAwesomeIcon icon={faSolidCheckCircle} />
        </span>
      );
    }
    return (
      <span className="icon-">
        <FontAwesomeIcon icon={faCheckCircle} />
      </span>
    );
  }

  /**
   * Builds array of boolean values holding info on whether dropdowns are expanded or not
   * @param {number} raceCategoriesIndex The index  of the current race in the array of arrays allRaceCategories
   * @param {string} currentRaceName
   * @param {number} currentRaceType
   * @returns {Object} The JSX.Element of the categories in a given race
   */
  public getRaceCategories(
    raceCategoriesIndex: number,
    currentRaceName: string,
    currentRaceType: number
  ): JSX.Element {
    try {
      if (
        this.allRaceCategories.length > 0 &&
        this.allRaceCategories[raceCategoriesIndex]
      ) {
        const raceCategories = this.allRaceCategories[
          raceCategoriesIndex
        ].map((currentRaceCategory: string) => (
          <EventBarItem
            key={`ListItem${raceCategoriesIndex}${currentRaceCategory}`}
            raceTypesHash={this.raceTypesHash}
            eventId={this.props.event.id}
            raceName={currentRaceName}
            raceType={currentRaceType}
            raceCategory={currentRaceCategory}
          />
        ));
        return raceCategories;
      }
    } catch (err) {
      // Ignore and return nothing if race has no categories yet (not propagated)
      log.error("EventBar, getRaceCategories", err);
    }
    return <></>;
  }

  /**
   * Gets the string of the overview url for that race
   * @param {string} currentRaceName The current race name
   *  @returns {string} A url
   */
  public getRaceOverviewUrl(currentRaceName: string): string {
    return `/event/${this.props.event.id}/overview/${convertRaceNameForUrl(
      currentRaceName
    )}/${convertRaceTypeToUrl(
      this.raceTypesHash,
      this.state.eventRacesNameType[currentRaceName].raceType
    )}/`;
  }

  /**
   * Get a 1 if the race has results, a 0 if it does not
   * @param {IRace} race A race
   * @returns {number} 1 for a race that has results, 0 otherwise
   */
  public getResultsSubmitted(race: IRace): number {
    if (race.riders && race.riders[0] && race.riders[0].placing) {
      return 1;
    }
    return 0;
  }

  /**
   * Load all races with riders, which in this component, will help identify if results have been entered
   * @param {IRace} races
   */
  public loadRacesAndRiders(races: IRace[]): Promise<void> | undefined {
    if (races) {
      return store
        .dispatch(
          getEventRaceRiders({
            races: Object.values(races),
          })
        )
        .catch(() => {
          captureException("Error in loadRacesAndRiders!");
        });
    }
  }

  /**
   * Sets the toggle index to true or false
   * @param {number} index The index of  race to toggle in the array of arrays allRaceCategories
   */
  private toggleExpandRace = (index: number): void => {
    this.setState(
      state => {
        const racesOpenToggle = state.racesOpenToggle.map((item, itemIndex) => {
          if (itemIndex === index) {
            return !item;
          }
          return item;
        });
        return {
          racesOpenToggle,
        };
      },
      () => {
        store.dispatch(toggleRaceOpen(index));
      }
    );
  };

  public render(): JSX.Element {
    // Get items to render.
    const getMenuItems = (): JSX.Element => (
      <div className="event-bar">
        <ListGroup.Item
          className={
            window.location.pathname === `/event/${this.props.event.id}/setup/`
              ? " items items-highlighted"
              : "items"
          }
          key={EventBarItems[0].id}
          onClick={(): void => {
            history.push(`/event/${this.props.event.id}/setup/`);
          }}
        >
          <span className="icon-">
            <FontAwesomeIcon icon={faCog} />
          </span>
          {EventBarItems[0].name}
        </ListGroup.Item>

        <ListGroup.Item
          className={
            this.props.event.isSetup
              ? window.location.pathname ===
                `/event/${this.props.event.id}/seeding/`
                ? " items items-highlighted"
                : "items"
              : "items items-disabled"
          }
          disabled={!this.props.event.isSetup}
          key={EventBarItems[1].id}
          onClick={(): void => {
            history.push(`/event/${this.props.event.id}/seeding/`);
          }}
        >
          <span className="icon-">
            <FontAwesomeIcon icon={faUsers} />
          </span>
          {EventBarItems[1].name}
        </ListGroup.Item>

        <ListGroup.Item
          className="items items-disabled"
          disabled
          key={EventBarItems[3].id}
        >
          <span className="icon-">
            <FontAwesomeIcon icon={faFileAlt} />
          </span>
          {EventBarItems[3].name}
        </ListGroup.Item>

        {this.getEventRaces()}
      </div>
    );

    return (
      <ListGroup defaultActiveKey="/home" className="event-bar">
        {getMenuItems()}
      </ListGroup>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any, any>(mapStateToProps)(EventBar);
