/**
 * Map relation between API and Frontend
 */
export const EventAPIMap = {
  startDatetime: {
    format: (value): Date => new Date(value),
  },
  endDatetime: {
    format: (value): Date => new Date(value),
  },
  mindbodyId: {
    format: (value: string): string => value,
  },
};
