import { camelCase } from "change-case-object";

/**
 * Convert an object from API to a frontend format
 * @param data
 */
const format = (data, dataMap): any => {
  Object.keys(dataMap).forEach(key => {
    if (dataMap[key]) {
      data[key] = dataMap[key].format(data[key]);
    }
  });

  return data;
};

/**
 * Convert an object from API to a frontend format
 * @param dataList
 */
const convertFromAPI = (dataList, dataMap = {}): any => {
  if (!dataList) {
    return [];
  }
  dataList = camelCase(dataList);
  if (dataList instanceof Array) {
    return dataList.map(data => format(data, dataMap));
  }
  return format(dataList, dataMap);
};

export { convertFromAPI };
