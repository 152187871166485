import React from "react";
import { connect } from "react-redux";

import AppBar from "components/Shared/AppBar";

import loadingImage from "./loading.svg";

interface ILoadingProps {
  loading: boolean;
  forceLoading: boolean; // Props directly passed to force spinner to show regardless of global state
}

function mapStateToProps(
  state
): {
  loading: boolean;
} {
  return {
    loading: state.global.loading,
  };
}

export class LoadingFullScreen extends React.Component<ILoadingProps> {
  render(): JSX.Element {
    const { loading } = this.props;

    if (
      loading ||
      (this.props.forceLoading && this.props.forceLoading === true)
    ) {
      return (
        <div className="loading">
          <AppBar />
          <img className="loading__image" src={loadingImage} alt="loading" />
        </div>
      );
    }
    return <span />;
  }
}

export default connect<any, any, any>(mapStateToProps)(LoadingFullScreen);
