import React from "react";

import LoadingFullScreen from "../components/Shared/LoadingFullScreen";

import Auth from "./Auth";

class Callback extends React.Component<any> {
  async componentDidMount(): Promise<void> {
    await Auth.handleAuthentication();
    this.props.history.replace("/");
  }

  render(): JSX.Element {
    return <LoadingFullScreen forceLoading={true} />;
  }
}

export default Callback;
