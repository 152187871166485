import React from "react";
import { Button } from "react-bootstrap";
import CsvDownloader from "react-csv-downloader";

import { Event } from "models/Event";
import { IRace, IRaceType } from "store/race/types";
import { sortRaceSubcategories } from "utils/utils";

interface ICreateCSVProps {
  event: Event;
  races: IRace[];
  raceTypes: IRaceType[];
  raceType: number;
}

interface ICreateCSVState {
  event: Event;
  races: IRace[];
  raceTypes: IRaceType[];
  raceType: number;
}

interface ICsvSeeding {
  lastname: string;
  firstname: string;
  bib: string;
  category: string;
  w: string;
  signature: string;
  notices: string;
}

export default class CreateCSV extends React.Component<
  ICreateCSVProps,
  ICreateCSVState
> {
  constructor(props: ICreateCSVProps) {
    super(props);
    this.state = {
      event: props.event,
      races: props.races,
      raceTypes: props.raceTypes,
      raceType: 0,
    };
  }

  /**
   * Set data for exporting seeding races in CSV format.
   * @public
   * @memberof CreateCSV
   */
  generateCsv(raceType: number): ICsvSeeding[] {
    const csv: ICsvSeeding[] = [];
    const { races } = this.state;
    if (races) {
      Object.values(races)
        .filter((race: IRace) => race.raceType === raceType)
        .sort((a, b) => sortRaceSubcategories(a, b))
        .forEach((race: IRace) => {
          (race.riders || [])
            .sort((a, b) => {
              return `${a.firstName} ${a.lastName}` >
                `${b.firstName} ${b.lastName}`
                ? 1
                : -1;
            })
            .forEach(rider => {
              csv.push({
                lastname: rider.lastName,
                firstname: rider.firstName,
                bib: "",
                category: race.category,
                w: "",
                signature: "",
                notices: "",
              });
            });
        });
    }
    return csv;
  }

  public getColumns(): { id: string; displayName: string }[] {
    const columns = [
      {
        id: "lastname",
        displayName: "LAST NAME",
      },
      {
        id: "firstname",
        displayName: "First",
      },
      {
        id: "bib",
        displayName: "BIB",
      },
      {
        id: "category",
        displayName: "CAT",
      },
      {
        id: "w",
        displayName: "W",
      },
      {
        id: "signature",
        displayName: "Signature",
      },
      {
        id: "notices",
        displayName:
          "Agree to receive notices of upcoming races, other events, and other promotional items",
      },
    ];
    return columns;
  }

  public render(): JSX.Element {
    const { raceType } = this.props;
    const columns = this.getColumns();
    let filenameVar = "";
    if (raceType) {
      const myRaceName = this.state.raceTypes.find(
        raceTypeFound => raceTypeFound.id === raceType
      );
      if (myRaceName) {
        filenameVar = `${this.state.event.name}_${myRaceName.name}`;
      }
    }

    return (
      <CsvDownloader
        filename={filenameVar}
        separator=","
        columns={columns}
        datas={this.generateCsv(raceType)}
      >
        <Button className="btn-cycling-secondary">Export...</Button>
      </CsvDownloader>
    );
  }
}
