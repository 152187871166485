import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { ActionType, action } from "typesafe-actions";

import store from "store";
import { IRiderBibs } from "store/race/types";
import { addRaces, deleteRaces } from "store/races/actions";

import {
  Event,
  IEvent,
  IEventsAPIData,
  IEventsAPISaveEventRaceTypes,
} from "../../models/Event";
import eventsApi from "../../services/events/eventsAPI";
import racesApi from "../../services/races/racesAPI";

import {
  Constants,
  ReceiveEventsAction,
  UpdateEventAction,
  LockEventAction,
  UpdateWasSeedingChangedAction,
} from "./types";

// Events ****

export const receiveEvents = (events: IEvent[]): ReceiveEventsAction =>
  action(Constants.RECEIVE_EVENTS, { events });

export const getEvents = (page: number, pageSize: number) => (
  dispatch: Dispatch<ActionType<typeof receiveEvents>>
): Promise<IEventsAPIData> =>
  eventsApi.getEvents(page, pageSize).then((receivedData: IEventsAPIData) => {
    dispatch(receiveEvents(receivedData.events));
    return receivedData;
  });

export const getMindBodyEvents = () => (
  dispatch: Dispatch<ActionType<typeof receiveEvents>>
): Promise<void> =>
  eventsApi.getMindBodyEvents().then((eventsReceived: IEvent[]) => {
    dispatch(receiveEvents(eventsReceived));
  });

// Event ****

/**
 * Trigger the eventsApi to create a new event.
 * @param {Object} details
 * @param {string} details.name The name of the event
 * @param {Date} details.startDatetime The startDatetime of the event
 * @param {Date} details.endDatetime The endDatetime of the event
 */
export const createNewEvent = (details: {
  name: string;
  startDatetime: Date;
  endDatetime: Date;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}) => (): Promise<any> => eventsApi.createNewEvent(details);

/**
 * Deletes an event
 * @param {Event} event The event to be deleted
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteEvent = (event: Event) => (): Promise<any> => {
  return eventsApi.deleteEvent(event);
};

/**
 * Updates an event
 * @param event
 */
export const updateEvent = (event: IEvent): UpdateEventAction => {
  return action(Constants.UPDATE_EVENT, { event });
};

/**
 * Get an event given an ID
 * @param {number} id The id of the event to retrieve
 * @returns {Event} The retrieved event
 */
export const getEvent = (id: number) => (
  dispatch: Dispatch<ActionType<typeof updateEvent>>
): Promise<Event> => {
  return eventsApi.getEvent(id).then(event => {
    dispatch(updateEvent(event));
    return event;
  });
};

/**
 * Get riders from MindBody
 * @param {Event} event The event for which to retrieve the riders
 */
export const getMindBodyRiders = (event: Event) => (
  dispatch: Dispatch<ActionType<typeof updateEvent>>
): Promise<AxiosResponse> =>
  racesApi.getMindBodyRiders(event.id).then(response => {
    dispatch(updateEvent(event));
    return response;
  });

/**
 * Locks or unlocks the event in the store.
 * @param {number} eventId   The event to be locked/unlocked
 * @param {boolean} lock      A boolean to say if it's locked or unlocked (will be changed to this value)
 */
export const lockEvent = (eventId: number, lock: boolean): LockEventAction =>
  action(Constants.LOCK_EVENT, { eventId, lock });

/**
 * Saves the new lock status in the database
 * @param {number} eventId   The event to be locked/unlocked
 * @param {boolean} lock      A boolean to say if it's locked or unlocked (will be changed to this value)
 */
export const saveLockEvent = (eventId: number, lock: boolean) => (
  dispatch: Dispatch<ActionType<typeof lockEvent>>
): void => {
  eventsApi.lockEvent(eventId, lock).then(() => {
    dispatch(lockEvent(eventId, lock));
  });
};

/**
 * It removes a rider from the whole event
 * @param {Event} event
 * @param {number} riderId The id of the rider to remove
 */
export const removeRider = (event: Event, riderId: number) => (): Promise<
  void
> => {
  return eventsApi.removeRider(event, riderId);
};

/**
 * Saves new event races to an event
 * @param {string} eventId The event ID of the event with new races
 * @param {IEventsAPISaveEventRaceTypes} newRaceTypes Object with the raceType and raceName
 */
export const saveEventRaceTypes = (
  eventId: string,
  newRaceTypes: IEventsAPISaveEventRaceTypes
) => (dispatch: Dispatch<ActionType<typeof addRaces>>): Promise<void> =>
  eventsApi.saveEventRaceTypes(eventId, newRaceTypes).then(newRaces => {
    if (newRaces.length > 0) {
      dispatch(addRaces(newRaces));
    }
    return;
  });

/**
 *  Sends the excel file to validate and process in the backend
 * @param {IEvent} event
 * @param {FormData} excelFile
 */
export const sendExcelFile = (
  event: IEvent,
  excelFile: FormData
) => (): Promise<void> => eventsApi.sendExcelFile(event, excelFile);

/**
 * Updates the starting lineups with the info of the event sent
 * @param {IEvent} event
 * @param {IRiderBibs} riders
 */
export const updateStartingLineups = (event: IEvent, riders: IRiderBibs) => (
  dispatch: Dispatch<ActionType<typeof updateEvent>>
): Promise<void> => {
  return racesApi.updateStartingLineups(event.id, riders).then(res => {
    event.lastUpdated = res.data.updatedDateTime;
    dispatch(updateEvent(event));
    if (res.data.newRaces.length > 0) {
      store.dispatch(addRaces(res.data.newRaces));
    }
    if (res.data.deletedRaces.length > 0) {
      const deletedRaceIds: Set<number> = new Set();
      res.data.deletedRaces.forEach(deletedRace => {
        deletedRaceIds.add(deletedRace.id);
      });
      store.dispatch(deleteRaces(deletedRaceIds));
    }
  });
};

/** Designed for the seeding race, it keeps track of changes in it, if a rider was removed,
 * or moved to another category (dragged). Update starting lineups will reset all to false.
 * This function is used one parameter at a time (one true while the other one needs to be set to null),
 * unless it is used to reset all (like in update starting lineups).
 * This is, if a rider was removed, use { wasRiderRemoved: true, wasRiderDragged: null, }
 * If a rider was dragged, use { wasRiderRemoved: null, wasRiderDragged: true, }
 * If using in update starting lineups to reset all: { wasRiderRemoved: false, wasRiderDragged: false, }
 * @param {Object} params   The two parameters
 * @param {?boolean} params.wasRiderRemoved Was a rider removed?
 * @param {?boolean} params.wasRiderDragged Was a rider dragged (from a category to another category)?
 */
export const updateWasSeedingChanged = ({
  wasRiderRemoved,
  wasRiderDragged,
}): UpdateWasSeedingChangedAction => {
  return action(Constants.UPDATE_WAS_SEEDING_CHANGED, {
    wasRiderRemoved: wasRiderRemoved,
    wasRiderDragged: wasRiderDragged,
  });
};
