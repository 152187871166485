import { isUndefined, isNullOrUndefined } from "util";

import { captureException } from "@sentry/browser";
import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { action, ActionType } from "typesafe-actions";

import { Rider } from "models/Rider";
import eventsApi from "services/events/eventsAPI";
import racesApi from "services/races/racesAPI";
import riderApi from "services/rider/riderAPI";
import { convertFromAPI } from "services/utils";
import { IRace } from "store/race/types";
import { UNASSIGNED_STR } from "utils/constants";

import {
  Constants,
  AddRacesAction,
  DeleteRacesAction,
  UpdateRacesAction,
  UpdateRaceNamesAction,
  UpdateRaceRidersAction,
  UpdateRacesRidersAction,
  UpdateRaceDetailsAction,
  AddNewRiderAction,
  LockRaceAction,
  UpdateBibAction,
  UpdateMultipleBibsAction,
  AddFinalRacesAction,
} from "./types";

/**
 * Creates an action to add races to the store (state.races)
 * @param {Object} races   A list of races to be added to the store
 */
export const addRaces = (races: IRace[]): AddRacesAction => {
  return action(Constants.ADD_RACES, { races });
};

/**
 * Creates an action to add final races to the store (state.races)
 * @param {IRace[]} races   A list of the final races to be added
 * @returns {AddFinalRacesAction}   An action with the final races
 */
export const addFinalRaces = (races: IRace[]): AddFinalRacesAction => {
  return action(Constants.ADD_FINAL_RACES, { races });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendRaceResults = (race: IRace, results: any) => (
  dispatch: Dispatch<ActionType<typeof addFinalRaces>>
): Promise<AxiosResponse> => {
  return eventsApi.sendRaceResults(race, results).then(res => {
    if (res.data.finalRaces) {
      const formattedData = convertFromAPI(res.data.finalRaces);
      dispatch(addFinalRaces(formattedData));
    }
    return res;
  });
};
// TODO: Remove, used in the defunct UploadResults
/**
 * Sends an Excel file to the database. Retrieves the Finals races from the backend and dispatches an action to add those races to the
 * store (state.races).
 * @param {Object} race  The race that the results are being submitted for
 * @param {Object} excelFile   An Excel file holding the results data
 */
export const sendRaceFile = (race: IRace, excelFile: FormData) => (
  dispatch: Dispatch<ActionType<typeof addRaces>>
): Promise<AxiosResponse> => {
  return eventsApi.sendRaceFile(race, excelFile).then(res => {
    if (res.data.finalRaces) {
      const formattedData = convertFromAPI(res.data.finalRaces);
      dispatch(addRaces(formattedData));
    }
    return res;
  });
};

/**
 * Creates a new race to represent the new category. Dispatches an action to add it to the store (state.races).
 * @param {number} eventId   The event ID of the event that the new category will be added to
 * @param {number} raceType  The race type of the new category, right now it is always equal to 1
 * @param {string} newCategoryName   The new category's name (usually just a letter)
 */
export const createNewCategory = (
  eventId: number,
  raceType: number,
  newCategoryName: string
) => (dispatch: Dispatch<ActionType<typeof addRaces>>): void => {
  racesApi.saveNewCategory(eventId, raceType, newCategoryName).then(res => {
    const newRace: IRace = convertFromAPI(res.data.race);
    if (newRace.riders === undefined) {
      newRace.riders = [];
    }
    dispatch(addRaces([newRace]));
  });
};

/**
 * Creates an action to delete a race from the store (state.races)
 * @param {Object} races  The races to be removed from the store
 */
export const deleteRaces = (races: Set<number>): DeleteRacesAction => {
  return action(Constants.DELETE_RACES, { races });
};

/**
 * Deletes a race from the database and the store (state.races)
 * @param {Object} race  The race to be deleted
 */
export const deleteCategory = (race: IRace) => (
  dispatch: Dispatch<ActionType<typeof deleteRaces>>
): void => {
  racesApi.deleteCategory(race.id).then(res => {
    if (!isUndefined(res.data.error)) {
      alert("Error: Cannot delete category unassigned");
      return null;
    } else {
      return dispatch(deleteRaces(new Set([race.id])));
    }
  });
};

/**
 * Creates an action to update the races in the store (state.races)
 * @param {Object} races  A list of races to populate the store with
 */
export const updateEventRaces = (races: IRace[]): UpdateRacesAction => {
  return action(Constants.UPDATE_RACES, { races });
};

/**
 * Update the races' names in the store (state.races)
 * @param {Object} racesToUpdate  A dictionary with key old name, value, new name
 */
export const updateRaceNames = (racesToUpdate: {
  [oldRaceName: string]: string;
}): UpdateRaceNamesAction => {
  return action(Constants.UPDATE_RACE_NAMES, { racesToUpdate });
};

/**
 * Creates an action to update a race with riders in the store (state.races[i].riders)
 * @param {Object} race  The race to add the riders to
 * @param {Object} riders  The riders to be added
 */
export const updateRaceRiders = (
  race: IRace,
  riders: Rider[]
): UpdateRaceRidersAction =>
  action(Constants.UPDATE_RACE_RIDERS, { race, riders });

/**
 * Updates races in the store with the provided riders,
 * grouped by race IDs for lookup
 * @param {Object} raceIdsWithRiders Object of riders grouped by race IDs
 */
export const updateRacesRiders = (raceIdsWithRiders: {
  [raceId: string]: Rider[];
}): UpdateRacesRidersAction =>
  action(Constants.UPDATE_RACES_RIDERS, { raceIdsWithRiders });

/**
 * Gets riders from the database to add them to the store (state.races[i].riders)
 * @param {Object} races The races that we want to get the riders for, can contain other races
 * @param {string} raceName Race name to filter on
 * @param {string} [raceCategory] (optional) Race category to filter on, in addition to race type
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getEventRaceRiders = (params: any) => async (
  dispatch: Dispatch<ActionType<typeof updateRacesRiders>>
): Promise<void> => {
  const { races, raceName, raceCategory } = params;
  const matchedRaceIds: number[] = [];
  // Get the riders for all the races
  if (isNullOrUndefined(raceCategory) && isNullOrUndefined(raceName)) {
    races.forEach(race => {
      matchedRaceIds.push(race.id);
    });
  } else if (isNullOrUndefined(raceCategory)) {
    // Filter races by race name and race category (if provided)
    races.forEach(race => {
      if (race.name === raceName) {
        matchedRaceIds.push(race.id);
      }
    });
  } else {
    races.forEach(race => {
      if (race.name === raceName && race.category === raceCategory) {
        matchedRaceIds.push(race.id);
      }
    });
  }
  const raceIdsWithRiders = await racesApi.getRaceRiders(matchedRaceIds);
  dispatch(updateRacesRiders(raceIdsWithRiders));
};

/**
 * Creates an action to update the Race details (number of riders who qualify for finals for Keirin
 * and number of laps per race for Keirin or other races) in the store.
 * (state.races)
 * @param {number} raceId  The ID of the race to update the details
 * @param {number} numQualify  The number of riders who qualify for finals
 * @param {number} numLaps   The number of laps in the race
 */
export const updateRaceDetails = (
  raceId: number,
  numQualify: number | null,
  numLaps: number | null
): UpdateRaceDetailsAction =>
  action(Constants.UPDATE_RACE_DETAILS, {
    raceId: raceId,
    numQualify: numQualify,
    numLaps: numLaps,
  });

/**
 * Sends the Keirin details (number of riders who qualify for finals and number of laps per race) to the database and dispatches an action
 * to add them to the store (state.races)
 * @param {number} raceId  The ID of the Keirin race
 * @param {number} numQualify  The number of riders who qualify for Keirin finals
 * @param {number} numLaps   The number of laps in the Keirin race
 * @returns {Promise}   The result of the API call
 */
export const saveRaceDetails = (
  raceId: number,
  numQualify: number | null,
  numLaps: number | null
) => (
  dispatch: Dispatch<ActionType<typeof updateRaceDetails>>
): Promise<AxiosResponse> => {
  return racesApi
    .saveRaceDetails(raceId, numQualify, numLaps)
    .then(res => {
      dispatch(updateRaceDetails(raceId, numQualify, numLaps));
      return res;
    })
    .catch(err => {
      captureException(err);
    });
};

/**
 * Sends the race details and randomizes the order of the riders
 * @param {Object} race
 */
export const randomizeRiders = (race: IRace) => (
  dispatch: Dispatch<ActionType<typeof updateRaceRiders>>
): Promise<void> => {
  return riderApi.randomizeRiders(race.id).then(res => {
    dispatch(
      updateRaceRiders(
        race,
        convertFromAPI(res.data.raceRiders).map(rider =>
          Rider.fromInterface(rider)
        )
      )
    );
  });
};

/**
 * Creates an action to add a new rider to the store (state.race(seeding unassigned race).riders)
 * @param {Object} rider     The new rider to add to the event
 * @param {string} category  The category that the new rider is assigned to
 */
export const addNewRider = (
  rider: Rider,
  category: string
): AddNewRiderAction =>
  action(Constants.ADD_NEW_RIDER, { rider: rider, category: category });

/**
 * Adds a new rider to an event in the database and dispatches an action to add it to the store (state.race(seeding unassigned race).riders)
 * @param {Object} rider   An object that holds the rider's information
 * @param {number} eventId   The ID of the event to add the rider to
 */
// Can't define the rider's type because it's an object created by AddRiderDetails
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveAddRider = (rider: any, eventId: number) => (
  dispatch: Dispatch<ActionType<typeof addNewRider>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  return riderApi.addRider(rider, eventId).then(deats => {
    const riderObj = new Rider(
      deats.data.riderId,
      rider.firstName,
      rider.lastName,
      deats.data.riderRanking ? deats.data.riderRanking : 25,
      deats.data.riderStdev ? deats.data.riderStdev : 3,
      deats.data.bib || undefined
    );
    const category = deats.data.category ? deats.data.category : UNASSIGNED_STR;
    dispatch(addNewRider(riderObj, category));
  });
};

/**
 * Locks or unlocks all the races for a given race type in the store
 * @param {number} eventID         The races will be updated for the event with this event ID
 * @param {string} raceName        The races will be updated for this race name
 * @param {boolean} status          The lock/unlock status (locked = true, unlocked = false)
 * @param {?string} lockedDatetime  The date time when the race was locked
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lockRaces = (params: any): LockRaceAction => {
  const { status, eventID, raceName, lockedDatetime } = params;
  return action(Constants.LOCK_RACE, {
    eventID,
    status,
    raceName,
    lockedDatetime,
  });
};

/**
 * Locks or unlocks all races for a given race type in the database. Also dispatches an action to update the store
 * @param {number} eventID   The races will be updated for the event with this event ID
 * @param {string} raceName  The races will be updated for this race name
 * @param {boolean} status    The lock/unlock status (locked = true, unlocked = false)
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lockRace = (params: any) => (
  dispatch: Dispatch<ActionType<typeof lockRaces>>
): void => {
  const { status, eventID, raceName } = params;
  racesApi
    .lockRace(status, eventID, raceName)
    .then(response => {
      dispatch(
        lockRaces({
          eventID: eventID,
          raceName: raceName,
          status: status,
          lockedDatetime: response,
        })
      );
    })
    .catch(err => {
      captureException(err);
    });
};

/**
 * Dispatches an action to update a bib number for a rider
 * @param {number} riderId  The rider ID of the rider to get a new bib number
 * @param {string} bib      The bib number
 */
export const updateBib = (riderId: number, bib: string): UpdateBibAction => {
  return action(Constants.UPDATE_BIB, { riderId, bib });
};

/**
 * Updates multiple bib numbers for multiple riders
 * @param riderBibs   An array of riderID-bib pairings
 */
export const updateMultipleBibs = (
  riderBibs: [{ riderId: number; bib: string }]
): UpdateMultipleBibsAction => {
  return action(Constants.UPDATE_MULTIPLE_BIBS, riderBibs);
};

/**
 * Gets multiple bibs from the database and dispatches an action to add them to the store
 * @param eventId   The event ID of the event to get bibs for
 */
export const getBibs = (eventId: number) => (
  dispatch: Dispatch<ActionType<typeof updateMultipleBibs>>
): Promise<void> => {
  return riderApi.getBibs(eventId).then(res => {
    dispatch(updateMultipleBibs(res.data));
  });
};

// TODO: Does this have to do with listing the races under the event tile???
// this function might be depreciated (duh duh DUH!)
// not depreciated, but mostly used by the refresh page functions
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getEventRaces = (eventId: number): Promise<any> =>
  eventsApi.getEventRaces(eventId);
