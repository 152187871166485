import { IEventBar } from "../../models/EventBar";

import { Constants, Actions } from "./types";

export const initialState: IEventBar = {
  racesOpenToggle: [],
};

function toggleRaceOpen(racesOpen, index: number): boolean[] {
  racesOpen[index] = !racesOpen[index];
  return racesOpen;
}

export function eventBarReducer(
  state: IEventBar = initialState,
  action: Actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  switch (action.type) {
    case Constants.SET_RACES_OPEN:
      return { ...state, racesOpenToggle: action.payload.racesOpenToggle };
    case Constants.TOGGLE_RACE_OPEN:
      return {
        ...state,
        racesOpenToggle: toggleRaceOpen(
          state.racesOpenToggle,
          action.payload.raceToToggle
        ),
      };
    default:
      return state;
  }
}
