import { faCircle, faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle as faSolidCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { captureException } from "@sentry/browser";
import React from "react";
import { Row, Col, Table } from "react-bootstrap";
import { connect } from "react-redux";

import LoadingSpinner from "components/Shared/LoadingSpinner";
import { Event } from "models/Event";
import store from "store";
import { IRace } from "store/race/types";
import { getEventRaceRiders } from "store/races/actions";
import { KEIRIN, CHARIOT } from "utils/constants";
import history from "utils/history";
import {
  buildRaceTypesHash,
  convertRaceTypeToUrl,
  convertRaceNameForUrl,
} from "utils/utils";

import CreateCSVSelectionButton from "../CreateCSVSelectionButton";

interface IRaceOverviewPageProps {
  raceTypes: { id: number; name: string }[];
  event: Event;
  races: IRace[];
  eventId: number;
  raceName: string;
  raceType: number;
}

export interface IRaceOverviewPageState {
  loading: boolean;
  raceTypes: { id: number; name: string }[];
  resultsSubmittedFlag: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  infoPerCategory: any;
  currentSampleRace: IRace;
}

function mapStateToProps(
  state
): {
  event: Event;
  raceTypes: { id: number; name: string }[];
  races: IRace[];
} {
  return {
    event: state.event.event,
    raceTypes: state.raceTypes.raceTypes,
    races: state.races,
  };
}

export class RaceOverviewPage extends React.Component<
  IRaceOverviewPageProps,
  IRaceOverviewPageState
> {
  private raceTypesHash: { [key: number]: string } = {};

  constructor(props: IRaceOverviewPageProps) {
    super(props);
    this.raceTypesHash = buildRaceTypesHash(this.props.raceTypes);
    this.state = {
      loading: true,
      raceTypes: this.props.raceTypes,
      resultsSubmittedFlag: false,
      infoPerCategory: new Map(),
      currentSampleRace: this.getCurrentRace(),
    };
  }

  async componentDidMount(): Promise<void> {
    const races = (Object.values(this.props.races) as IRace[]).filter(race => {
      return race.name === this.props.raceName;
    });
    await this.loadRacesAndRiders(races);
    const infoPerCategory = this.getInfoPerCategory(races);
    if (infoPerCategory.size !== 0) {
      this.setState({
        infoPerCategory: infoPerCategory,
        currentSampleRace: this.getCurrentRace(),
      });
    }
  }

  async componentDidUpdate(prevProps): Promise<void> {
    if (prevProps.raceName !== this.props.raceName) {
      const races = (Object.values(this.props.races) as IRace[]).filter(
        race => {
          return race.name === this.props.raceName;
        }
      );
      await this.loadRacesAndRiders(races);
      const infoPerCategory = this.getInfoPerCategory(races);
      this.setState({
        infoPerCategory: infoPerCategory,
        loading: false,
        currentSampleRace: this.getCurrentRace(),
      });
    }

    if (this.props !== prevProps) {
      this.setState({ loading: false });
    }
  }
  /**
   * Creates the race overview table that shows all the categories and their info
   * @returns {JSX.Element}  The Table to be displayed
   */
  public createTable(): JSX.Element {
    const tableRows: JSX.Element[] = [];

    this.state.infoPerCategory.forEach((value, key) => {
      tableRows.push(
        <tr
          key={"curTile" + key.toString()}
          onClick={(): void => this.handleRouting(key)}
          className="race-overview__table-whole-row"
        >
          <td>
            <div className="race-overview__table-row race-overview__cat-row">
              Category {key}
            </div>
            {this.getRaceInfoLine(value)}
          </td>
          <td className="race-overview__table-row">
            {this.getResultsColumn(value.results)}
          </td>
        </tr>
      );
      // Adding an empty row to create space between the category 'cards'
      tableRows.push(
        <tr key={"curTile" + key.toString() + "spacer"}>
          <td></td>
        </tr>
      );
    });

    return (
      <Table borderless>
        {/* Table does not have headers but need to set them in order to set the width of the columns */}
        <thead>
          <tr>
            <th className="race-overview__table-header-1"></th>
            <th className="race-overview__table-header-2"></th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </Table>
    );
  }

  /**
   * Gets the sample current race for the current race name, it has the basic info, raceName, raceType, locked.
   * @returns {IRace} A Race with the same name as the props.name
   */
  getCurrentRace(): IRace {
    const currentRace = (Object.values(this.props.races) as IRace[]).filter(
      race => {
        return race.name === this.props.raceName;
      }
    );
    return currentRace[0];
  }
  /**
   * Gets the text of the column for the number of heats
   * @param {number} numHeats The number of heats of the current race
   * @returns {string} The string
   */
  getHeatsColumn(numHeats: number): string | void {
    if (this.props.raceType === KEIRIN || this.props.raceType === CHARIOT) {
      return `${numHeats} Heats`;
    }
  }
  /**
   * Gets all info for the category: number of Riders, whether results have been submitted, and number of riders that qualify
   * @param {Object} races races
   * @returns {Object} The map
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getInfoPerCategory(races): any {
    const categoryHash = races.reduce((acc, race) => {
      if (race.riders) {
        if (acc.has(race.category)) {
          const subLength = race.subcategory
            ? race.subcategory.includes("Heat")
              ? race.riders.length
              : 0
            : race.riders.length;
          const length = acc.get(race.category).length + subLength;
          const hasHeat = race.subcategory && race.subcategory.includes("Heat");
          const numHeats = acc.get(race.category).numHeats + (hasHeat ? 1 : 0);
          const results1 = acc.get(race.category).results;
          const results2 = this.getResultsSubmitted(race);
          const results =
            results1 === results2 ? results1 : "Results partially submitted";
          const numQualify = acc.get(race.category).numQualify;
          const numLaps = acc.get(race.category).numLaps;
          acc.set(race.category, {
            length: length,
            numHeats: numHeats,
            results: results,
            numQualify: numQualify,
            numLaps: numLaps,
          });
        } else {
          const hasHeat = race.subcategory && race.subcategory.includes("Heat");
          const numHeats = hasHeat ? 1 : 0;
          const length = race.subcategory
            ? race.subcategory.includes("Heat")
              ? race.riders.length
              : 0
            : race.riders.length;
          const numLaps = race.subcategory
            ? race.subcategory.includes("Heat")
              ? race.numLaps
              : 0
            : race.numLaps;
          acc.set(race.category, {
            length: length,
            numHeats: numHeats,
            results: this.getResultsSubmitted(race),
            numQualify: race.numQualify,
            numLaps: numLaps,
          });
        }
      }
      return acc;
    }, new Map());
    return categoryHash;
  }
  /**
   * Returns the JSX.Element of the race info line: number of riders, heats, laps, and riders that advance
   * @returns{JSX.Element}  The race info line
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getRaceInfoLine(value: any): JSX.Element {
    if (value) {
      return (
        <div className="race-overview__table-row">
          {`${value.length} Riders
          ${
            value.numHeats
              ? `\u00A0\u2022\u00A0 ${this.getHeatsColumn(value.numHeats)}`
              : ""
          } ${
            value.numLaps
              ? `\u00A0\u2022\u00A0 ${value.numLaps} Laps`
              : `\u00A0\u2022\u00A0 Unknown Laps`
          } ${
            value.numQualify
              ? `\u00A0\u2022\u00A0 ${value.numQualify} qualify`
              : ""
          }`}
        </div>
      );
    }
    return <></>;
  }
  /**
   * Gets the name of the raceType given the numerical raceType
   * @returns {string} The raceType name
   */
  getRaceType(): string {
    return this.raceTypesHash[this.props.raceType];
  }

  /**
   * Gets the text of the column for the results in each category
   * @param {string} results The number of heats of the current race
   * @returns {JSX.Element} The JSX.Element
   */
  getResultsColumn(results: string): JSX.Element {
    switch (results) {
      case "Results submitted":
        return (
          <div>
            <span className="race-overview__results-icon-full">
              <FontAwesomeIcon icon={faSolidCheckCircle} />
            </span>
            <span>{results}</span>
          </div>
        );

      case "Results partially submitted":
        return (
          <div>
            <span className="race-overview__results-icon-part">
              <FontAwesomeIcon icon={faCheckCircle} />
            </span>
            <span>{results}</span>
          </div>
        );
      case "No results submitted":
        return (
          <div>
            <span className="race-overview__results-icon-none">
              <FontAwesomeIcon icon={faCircle} />
            </span>
            <span>{results}</span>
          </div>
        );
      default:
        return <></>;
    }
  }
  /**
   * Get the string of whether results have been submitted or not for a single race
   * @param {race} race A race
   * @returns {string} The string of 'Results submitted (or not)'
   */
  getResultsSubmitted(race): string {
    if (race.riders && race.riders[0] && race.riders[0].placing) {
      return "Results submitted";
    } else {
      return "No results submitted";
    }
  }
  /**
   * Routes to the category page
   * @param {string} category The category of the current race
   */
  handleRouting(category: string): void {
    history.push(
      `/event/${this.props.eventId}/${convertRaceNameForUrl(
        this.props.raceName
      )}/${convertRaceTypeToUrl(
        this.raceTypesHash,
        this.props.raceType
      )}/${category}/`
    );
  }
  /**
   * Load races with riders for a specific race name
   */
  loadRacesAndRiders(races): Promise<void> | undefined {
    this.setState({ loading: true });
    if (races) {
      return store
        .dispatch(
          getEventRaceRiders({
            races: Object.values(races),
            raceName: this.props.raceName,
          })
        )
        .then()
        .catch(() => {
          captureException("Error in loadRacesAndRiders!");
        });
    }
  }
  render(): JSX.Element {
    if (this.state.loading) {
      return <LoadingSpinner />;
    }
    return (
      <>
        <div>
          <div className="race-overview__race-header">
            <Row className="race-overview__upper-row">
              {this.state.currentSampleRace === undefined ? (
                <Col></Col>
              ) : (
                <Col xs="12">
                  <span className="race-overview__race-name-text">
                    {this.props.raceName}
                  </span>
                </Col>
              )}
            </Row>
            <Row className="race-overview__lower-row">
              <Col xs="6">
                <span className="race-overview__race-type-name">
                  {this.getRaceType()}
                </span>
              </Col>
              <Col className="race-overview__export" xs="6">
                <CreateCSVSelectionButton />
              </Col>
            </Row>
          </div>
          <div className="race-overview__table-div">{this.createTable()}</div>
        </div>
      </>
    );
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any, any>(mapStateToProps)(RaceOverviewPage);
