import { ActionType } from "typesafe-actions";

// Allow wildcard import for all actions
// eslint-disable-next-line import/no-namespace
import * as actions from "./actions";

export type Actions = ActionType<typeof actions>;

export enum Constants {
  SET_RACES_OPEN = "SET_RACES_OPEN",
  TOGGLE_RACE_OPEN = "TOGGLE_RACE_OPEN",
}
