import { captureException } from "@sentry/browser";
import React from "react";
import { Button, Spinner, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import { Event } from "models/Event";
import store from "store";
import {
  updateStartingLineups,
  updateWasSeedingChanged,
} from "store/events/actions";
import { IRace, IRiderBibs } from "store/race/types";
import { SEEDING } from "utils/constants";
import { formatDate } from "utils/utils";

interface IUpdateStartingLineupsProps {
  event: Event;
  races: IRace[];
  invalidBibs: Set<string>;
  riderBibs: IRiderBibs;
  onButtonClicked: () => void;
  bibChangesMade: boolean;
  seedingChanges: boolean;
}

interface IUpdateStartingLineupsState {
  eventRaceTypes: number[];
  loading: boolean;
}

function mapStateToProps(
  state
): {
  event: Event;
  races: IRace[];
  invalidBibs: Set<string>;
  seedingChanges: boolean;
} {
  return {
    event: state.event.event,
    races: state.races,
    invalidBibs: state.views.event.invalidBibs,
    seedingChanges: Boolean(
      Object.values(state.event.seedingChanges).reduce((a, b) => a || b, false)
    ),
  };
}

/**
 * UpdateStartingLineups component
 * @export
 * @class UpdateStartingLineups
 * @extends {React.Component<IUpdateStartingLineups>}
 */
export class UpdateStartingLineups extends React.Component<
  IUpdateStartingLineupsProps,
  IUpdateStartingLineupsState
> {
  constructor(props: IUpdateStartingLineupsProps) {
    super(props);
    this.state = {
      eventRaceTypes: Array.from(
        new Set<number>(
          (Object.values(store.getState().races) as IRace[]).map(
            (race: IRace) => race.raceType
          )
        ).values()
      ).sort((a, b) => a - b),
      loading: false,
    };
  }

  /**
   * Returns the paragraph with the date of the last update
   * @param {string} theClass
   * @returns {JSX.Element} The paragraph
   */
  public getLastUpdated(theClass: string): JSX.Element {
    if (this.props.event.lastUpdated) {
      return (
        <p className={`${theClass}`}>
          Last Updated{" "}
          {formatDate(
            this.props.event.lastUpdated,
            "MMM DD, YYYY [at] h:mm:ss a"
          )}
        </p>
      );
    }
    return <></>;
  }

  /**
   * Returns the button to update starting lineups, as well as the last updated text
   * @param {boolean} disabled True if the button should be disabled
   * @returns {JSX.Element} The update starting lineups button, with or without spinner
   */
  public getUpdateButton(disabled: boolean): JSX.Element {
    return (
      <span className="update-starting-lineups__button">
        <Button
          className="btn-cycling-secondary"
          disabled={disabled}
          onClick={(): Promise<void> => this.updateStartingLineups()}
        >
          Update Races{" "}
          {this.state.loading === true ? this.showSpinner() : <span />}
        </Button>
      </span>
    );
  }

  /**
   * Checks if seeding view fulfills requirements to populate races
   * Checks that all seeding races (Categories) have riders in them,
   * except for the Unassigned category
   * Checks there are no invalid bibs
   * @returns {boolean}
   */
  public isRaceReady(): boolean {
    if (this.props.races) {
      // Checks that all seeding races (Categories) have riders in them,
      // except for the Unassigned category
      let allSeedingRacesHaveRiders = true;
      Object.values(this.props.races)
        .filter(race => race.raceType === SEEDING)
        .forEach(race => {
          if (
            race.category !== "Unassigned" &&
            (!race.riders || race.riders.length === 0)
          ) {
            allSeedingRacesHaveRiders = false;
          }
        });
      // Checks that the number of riders in the seeding view is
      // equal to the number of bibs, and that allSeedingRacesHaveRiders
      if (allSeedingRacesHaveRiders && this.props.invalidBibs.size === 0) {
        return true;
      }
      return false;
    }
    return false;
  }

  /**
   * Finds the number of races in the event
   * @returns {number} The number of races in the event
   */
  public numRaceTypes(): number {
    if (this.state.eventRaceTypes) {
      return this.state.eventRaceTypes.length;
    }
    return 0;
  }

  /**
   * Returns the JSX.Element paragraph with the info that they need to makes changes before updating.
   * Update starting lineups button is disabled.
   * @returns {JSX.Element} The paragraph of why races cannot be updated.
   */
  showCannotUpdate(): JSX.Element {
    return (
      <Row className="ml-0 mr-0 update-starting-lineups update-starting-lineups__box-changes-disabled align-items-center">
        <Col className="update-starting-lineups__left-side-box-changes-disabled">
          <p className="update-starting-lineups__info">
            There are pending changes to the seeding list. You cannot update
            races until:
          </p>
          <p className="update-starting-lineups__subinfo">
            {`\u25cf`} &nbsp; All displayed categories are populated.
          </p>
          <p className="update-starting-lineups__subinfo">
            {`\u25cf`} &nbsp; All riders have unique Bib numbers.
          </p>
        </Col>
        <Col
          xs="auto"
          lg="3"
          className="update-starting-lineups__right-side-box"
        >
          {this.getUpdateButton(true)}
        </Col>
      </Row>
    );
  }

  /**
   * Returns the JSX.Element paragraph showing that ones needs to update the starting lineups.
   * Update starting lineups button is enabled.
   * @returns {JSX.Element} The paragraph showing that races need be updated
   */
  showNeedToUpdate(): JSX.Element {
    return (
      <Row className="ml-0 mr-0 update-starting-lineups update-starting-lineups__box-changes-enabled">
        <Col className="update-starting-lineups__left-side-box-changes-enabled">
          There are pending changes to the seeding list. Apply these changes to
          all races where no results have been added.
          {this.getLastUpdated("update-starting-lineups__last-updated-changes")}
        </Col>
        <Col
          xs="auto"
          lg="3"
          className="update-starting-lineups__right-side-box"
        >
          {this.getUpdateButton(false)}
        </Col>
      </Row>
    );
  }

  /**
   * Shows a spinner
   * @returns {JSX.Element} The Spinner
   */
  public showSpinner(): JSX.Element {
    return (
      <span>
        &nbsp;{" "}
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      </span>
    );
  }

  /**
   * Returns the JSX.Element paragraph informing the user that info is up to date.
   * Update starting lineups button is disabled.
   * @returns {JSX.Element} The paragraph of showing that races are up to date.
   */
  showUpToDate(): JSX.Element {
    return (
      <Row className="ml-0 mr-0 update-starting-lineups update-starting-lineups__box-no-changes">
        <Col className="update-starting-lineups__left-side-box-no-changes align-self-center">
          <p>The seeding list is up to date. There are no pending changes.</p>
          {this.getLastUpdated(
            "update-starting-lineups__last-updated-no-changes"
          )}
        </Col>
        <Col
          xs="auto"
          lg="3"
          className="update-starting-lineups__right-side-box"
        >
          {this.getUpdateButton(true)}
        </Col>
      </Row>
    );
  }

  /**
   * Updates the starting lineups, resets the state of seeding changes to 'no changes'
   * @returns {Promise<void>}
   */
  public async updateStartingLineups(): Promise<void> {
    try {
      this.setState({ loading: true });
      // Send the bib numbers to the backend and retrieve the new races
      await store.dispatch(
        updateStartingLineups(this.props.event, this.props.riderBibs)
      );
      // Refresh state to show no pending changes after update starting lineups was pressed
      try {
        await store.dispatch(
          updateWasSeedingChanged({
            wasRiderRemoved: false,
            wasRiderDragged: false,
          })
        );
      } catch (error) {
        captureException(
          `Error with updateWasSeedingChanged(false to all): ${error}`
        );
      }
      this.setState({ loading: false });
    } catch (err) {
      captureException(err);
    }
    this.props.onButtonClicked();
  }

  render(): JSX.Element {
    // Return disabled button if race is not ready or not set up, otherwise check if seeding has changes
    // If there are changes, tell user races need to be updated, otherwise it is up to date.
    if (!this.isRaceReady() || this.numRaceTypes() <= 1) {
      return this.showCannotUpdate();
    } else if (
      this.isRaceReady() &&
      (this.props.bibChangesMade || this.props.seedingChanges)
    ) {
      return this.showNeedToUpdate();
    }
    return this.showUpToDate();
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any, any>(mapStateToProps)(
  UpdateStartingLineups
);
