import React from "react";
import { connect } from "react-redux";

import { IEvent } from "models/Event";
import { mapRaceTypeStringToInt, convertRaceNameFromUrl } from "utils/utils";

import CreateCSVSelection from "../../components/Race/CreateCSVSelection";
import RaceOverviewPage from "../../components/Race/RaceOverviewPage";
import Layout from "../../components/Shared/Layout";

interface IRaceOverviewViewProps {
  event: IEvent;
  showCreateCSV: boolean;
  showLockRace: boolean;
  dontShowLockRaceAgain: boolean;
  eventId: string;
  raceName: string;
  raceType: string;
}

function mapStateToProps(
  state
): {
  event: IEvent;
  showCreateCSV: boolean;
  showLockRace: boolean;
  dontShowLockRaceAgain: boolean;
} {
  return {
    event: state.event,
    showCreateCSV: state.views.event.showCreateCSV,
    showLockRace: state.views.event.showLockRace,
    dontShowLockRaceAgain: state.views.event.dontShowLockRaceAgain,
  };
}

export class RaceOverviewView extends React.Component<IRaceOverviewViewProps> {
  render(): JSX.Element | null {
    return (
      <Layout
        eventId={parseInt(this.props.eventId)}
        raceName={convertRaceNameFromUrl(this.props.raceName)}
        raceType={mapRaceTypeStringToInt(this.props.raceType)}
      >
        <div className="event container ml-2">
          <RaceOverviewPage
            eventId={parseInt(this.props.eventId)}
            raceName={convertRaceNameFromUrl(this.props.raceName)}
            raceType={mapRaceTypeStringToInt(this.props.raceType)}
          />
        </div>

        {this.props.showCreateCSV && (
          <CreateCSVSelection
            raceName={convertRaceNameFromUrl(this.props.raceName)}
            raceType={mapRaceTypeStringToInt(this.props.raceType)}
            show={this.props.showCreateCSV}
          />
        )}
      </Layout>
    );
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any>(mapStateToProps)(RaceOverviewView);
